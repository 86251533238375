import React, { memo } from 'react';
import PropTypes       from 'prop-types';

const PhoneIcon = memo( ( { className, color } ) => {
  return (
    <svg className={ className }
         xmlns="http://www.w3.org/2000/svg"
         width="18.498"
         height="18.534"
         viewBox="0 0 18.498 18.534">
      <path d="M21.665,16.877v2.79a1.86,1.86,0,0,1-2.028,1.86,18.406,18.406,0,0,1-8.026-2.855,18.136,18.136,0,0,1-5.58-5.58A18.406,18.406,0,0,1,3.175,5.027,1.86,1.86,0,0,1,5.026,3h2.79a1.86,1.86,0,0,1,1.86,1.6,11.942,11.942,0,0,0,.651,2.613,1.86,1.86,0,0,1-.419,1.962L8.728,10.357a14.881,14.881,0,0,0,5.58,5.58l1.181-1.181a1.86,1.86,0,0,1,1.962-.419,11.942,11.942,0,0,0,2.613.651,1.86,1.86,0,0,1,1.6,1.888Z"
            transform="translate(-3.168 -3)"
            fill={ color } />
    </svg>
  )
});

PhoneIcon.defaultProps = {
  className: '',
  color: '#431E75'
};

PhoneIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
};

export default PhoneIcon;