import React, { memo } from 'react';
import PropTypes       from 'prop-types';

const EditIcon = memo( ( { className, color } ) => {
  return (
    <svg className={ className }
         xmlns="http://www.w3.org/2000/svg"
         width="30"
         height="30"
         viewBox="0 0 30 30">
      <g transform="translate(-136 -381.236)">
        <path d="M19.925,2.979,23.15,6.2a.35.35,0,0,1,0,.494l-7.81,7.81-3.319.368a.7.7,0,0,1-.769-.769l.368-3.319,7.81-7.81A.35.35,0,0,1,19.925,2.979Zm5.793-.819L23.973.415A1.4,1.4,0,0,0,22,.415L20.733,1.681a.35.35,0,0,0,0,.494L23.959,5.4a.35.35,0,0,0,.494,0l1.266-1.266a1.4,1.4,0,0,0,0-1.974Z"
              transform="translate(132.313 388.792)"
              fill={ color } />
        <g transform="translate(136 381.236)"
           fill="none"
           stroke={ color }
           strokeWidth="1.6">
          <rect width="30" height="30" rx="6" stroke="none"/>
          <rect x="0.8" y="0.8" width="28.4" height="28.4" rx="5.2" fill="none"/>
        </g>
      </g>
    </svg>
  );
});

EditIcon.defaultProps = {
  className: '',
  color: '#00b7f1'
};

EditIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
};

export default EditIcon;