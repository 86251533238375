import React, { memo, useContext, useState, useEffect } from 'react';
import { useStaticQuery, graphql }                      from 'gatsby';
import PropTypes                                        from 'prop-types';

// import context
import { GlobalStateContext } from '../../context/GlobalContextProvider';

// import components
import RSSIcon from './icons/RSSIcon';

const RSSMenu = memo( ( { useBorder } ) => {
  const data = useStaticQuery( graphql`
    {
      allApiPoints {
        nodes {
          rss {
            title
          }
        }
      }
      
      allRss {
        nodes {
          lang
          title
          xml_description
          xml_title
          xml_url
        }
      }
    }
  `);

  // define the component context
  const state = useContext( GlobalStateContext );
  const { defaultLang } = state;

  // define the default component state
  const [rssTitle, setRssTitle] = useState( '' );
  const [rssItems, setRssItems] = useState( [] );

  /**
   * Get texts from the backend and processing changing language
   */
  useEffect( () => {
    // get loaded data from graphql
    const { allApiPoints, allRss } = data;
    const rssChannels = allRss.nodes.filter( node => node.lang === defaultLang );

    setRssItems( rssChannels );

    allApiPoints.nodes.forEach( node => {
      const { rss } = node;
      let orderNumber;

      switch ( defaultLang ) {
        case 'en':
          orderNumber = 1;
          break;

        default:
          orderNumber = 0;
      }

      setRssTitle( rss[orderNumber].title );
    });
  }, [data, defaultLang] );

  return (
    <>
      { useBorder ?
        <span className="overlay__icon__wrapper">
          <RSSIcon className="overlay__icon" border={ false } />
        </span>
      :
        <RSSIcon className="overlay__icon" />
      }
      <h4 className="overlay__title">{ rssTitle }</h4>

      <ul className="overlay__list">
        { rssItems.map( item => {
          const { title, xml_url } = item;

          return (
            <li className="overlay__list__item" key={ xml_url }>
              <a className="overlay__list__item__link"
                 href={ `/${ xml_url }.xml` }
                 target="_blank"
                 rel="noreferrer" >
                { title }
              </a>
            </li>
          );
        } ) }
      </ul>
    </>
  )
});

RSSMenu.defaultProps = {
  useBorder: false
};

RSSMenu.propTypes = {
  useBorder: PropTypes.bool
};

export default RSSMenu;