import React, { memo } from 'react';
import PropTypes       from 'prop-types';

const EnvelopeIcon = memo( ( { className, color, strokeColor } ) => {
  return (
    <svg className={ className }
         xmlns="http://www.w3.org/2000/svg"
         width="19.994"
         height="15.881"
         viewBox="0 0 19.994 15.881">
      <g transform="translate(0.696 0.5)">
        <path d="M4.86,6H19.741A1.866,1.866,0,0,1,21.6,7.86V19.021a1.866,1.866,0,0,1-1.86,1.86H4.86A1.866,1.866,0,0,1,3,19.021V7.86A1.866,1.866,0,0,1,4.86,6Z"
              transform="translate(-3 -6)"
              fill={ color }
              stroke={ strokeColor }
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1" />
        <path d="M21.6,9l-9.3,6.51L3,9"
              transform="translate(-3 -7.14)"
              fill={ color }
              stroke={ strokeColor }
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1" />
      </g>
    </svg>
  )
});

EnvelopeIcon.defaultProps = {
  className: '',
  color: '#431E75',
  strokeColor: '#fff'
};

EnvelopeIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  strokeColor: PropTypes.string
};

export default EnvelopeIcon;