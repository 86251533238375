import React, { memo } from 'react';
import PropTypes       from 'prop-types';

// import styles
import { SocialIconElement } from '../../../styles/elements/SocialIcons';

// import components
import LinkedinIcon  from './LinkedinIcon';
import TwitterIcon   from './TwitterIcon';
import FacebookIcon  from './FacebookIcon';
import InstagramIcon from './InstagramIcon';

const SocialIcon = memo( ( { className, type, link, color, altText } ) => {
  if ( type === 'linkedin' ) {
    return (
      <SocialIconElement className={ className }
                         href={ link }
                         target="_blank"
                         title={ altText }
                         rel="noreferrer nofollow">
        <LinkedinIcon color={ color } />
      </SocialIconElement>
    )
  }

  if ( type === 'twitter' ) {
    return (
      <SocialIconElement className={ className }
                         href={ link }
                         target="_blank"
                         title={ altText }
                         rel="noreferrer nofollow">
        <TwitterIcon color={ color } />
      </SocialIconElement>
    )
  }

  if ( type === 'facebook' ) {
    return (
      <SocialIconElement className={ className }
                         href={ link }
                         target="_blank"
                         title={ altText }
                         rel="noreferrer nofollow">
        <FacebookIcon color={ color } />
      </SocialIconElement>
    )
  }

  if ( type === 'instagram' ) {
    return (
      <SocialIconElement className={ className }
                         href={ link }
                         target="_blank"
                         title={ altText }
                         rel="noreferrer nofollow">
        <InstagramIcon color={ color } />
      </SocialIconElement>
    )
  }
});

SocialIcon.defaultProps = {
  className: '',
  type: 'linkedin',
  link: '#',
  color: '#fff',
  altText: 'Follow us on'
};

SocialIcon.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  color: PropTypes.string,
  altText: PropTypes.string,
};

export default SocialIcon;