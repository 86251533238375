import React, { memo } from 'react';
import { Link }        from 'gatsby';
import PropTypes       from 'prop-types';

// import styles
import { NavColElement } from '../../styles/common/FooterNavCol';

const FooterNavCol = memo( ( { data } ) => {
  const { subMenu, text, link: pageLink } = data;

  /**
   * Create link for footer navigation
   *
   * @param pageLink | string
   * @param text | string
   * @param link | string
   *
   * @return HTML Entity
   */
  const websiteLink = ( pageLink, text, link ) => {
    if ( pageLink === '/' ) {
      return (
        <Link className="nav-link"
              to={ `/${ link }` }>
          { text }
        </Link>
      )
    } else {
      return (
        <a className="nav-link"
           href={ `${ link }` }
           target="_blank"
           rel="noreferrer">
          { text }
        </a>
      )
    }
  };

  return (
    <NavColElement>
      <h3 className="nav-title">{ text }</h3>

      { subMenu.map( ( item ) => {
        const { link, text } = item;

        return websiteLink( pageLink, text, link );
      })}
    </NavColElement>
  )
});

FooterNavCol.defaultProps = {
  data: {}
};

FooterNavCol.propTypes = {
  data: PropTypes.object.isRequired
};

export default FooterNavCol;