import React, { memo, useContext, useState, useEffect } from 'react';
import { useStaticQuery, graphql }                      from 'gatsby';
import PropTypes                                        from 'prop-types';

// import constants
import { AUTH_URL, CHANGE_OVERLAY } from '../../constants';

// import context
import { GlobalDispatchContext, GlobalStateContext } from '../../context/GlobalContextProvider';

// import helpers
import { useEmailValidation, usePasswordValidation, useWindowSize, postData } from '../../helpers';

// import components
import UserIconAdd       from '../elements/icons/UserIconAdd';
import EyeVisibleOnIcon  from './icons/EyeVisibleOnIcon';
import EyeVisibleOffIcon from './icons/EyeVisibleOffIcon';
import Button            from '../elements/Button';
import PasswordHint      from '../elements/PasswordHint';

const Register = memo( ( { className } ) => {
  const data = useStaticQuery( graphql`
    {
      allApiPoints {
        nodes {
          register {
            a_member_link
            a_member_text
            error
            already_registered_error
            password_not_match
            regex_error
            fields {
              text
              type
            }
            gender_options {
              text
            }
            gender_text
            lang
            privacy_link
            privacy_link_text
            privacy_text
            title
          }
        }
      }
    }
  `);

  // define the component context
  const state    = useContext( GlobalStateContext );
  const dispatch = useContext( GlobalDispatchContext );
  const { defaultLang } = state;

  // define the default component state
  const [confirmPasswordIsVisible, setConfirmPasswordIsVisible] = useState( false );
  const [isPasswordNotMatchError, setIsPasswordNotMatchError]   = useState( false );
  const [passwordIsVisible, setPasswordIsVisible]     = useState( false );
  const [isAlreadyRegistered, setIsAlreadyRegistered] = useState( false );
  const [isPasswordError, setIsPasswordError] = useState( false );
  const [isBtnProcess, setIsBtnProcess] = useState( false );
  const [isError, setIsError]   = useState( false );
  const [showHint, setShowHint] = useState( false );
  const [formData, setFormData] = useState({
    company: '',
    gender: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: ''
  });

  const [companyIsInvalid, setCompanyIsInvalid]     = useState( false );
  const [genderIsInvalid, setGenderIsInvalid]       = useState( false );
  const [firstNameIsInvalid, setFirstNameIsInvalid] = useState( false );
  const [lastNameIsInvalid, setLastNameIsInvalid]   = useState( false );
  const [emailIsInvalid, setEmailIsInvalid]         = useState( false );
  const [passwordIsInvalid, setPasswordIsInvalid]   = useState( false );
  const [confirmPasswordIsInvalid, setConfirmPasswordIsInvalid] = useState( false );

  const [texts, setTexts] = useState({
    registerTitle: '',
    registerError: '',
    registerAlreadyRegisteredError: '',
    registerPasswordError: '',
    registerPasswordNotMatchError: '',
    registerMemberText: '',
    registerMemberLink: '',
    registerGenderText: '',
    registerGenderFields: [],
    registerPrivacyText: '',
    registerPrivacyLinkText: '',
    registerPrivacyLink: '',
    registerFields: []
  });

  const { company, gender, firstName, lastName, email, password, confirmPassword } = formData;
  const {
    registerTitle, registerError, registerMemberText, registerMemberLink, registerGenderText, registerGenderFields,
    registerPrivacyText, registerPrivacyLinkText, registerPrivacyLink, registerFields,
    registerAlreadyRegisteredError, registerPasswordError, registerPasswordNotMatchError
  } = texts;

  // get the component custom hooks
  const passwordValidation = usePasswordValidation( password );
  const emailValidation = useEmailValidation( email );
  const windowSize = useWindowSize();
  const { width }  = windowSize;

  /**
   * Get texts from the backend and processing changing language
   */
  useEffect( () => {
    // get loaded data from graphql
    data.allApiPoints.nodes.forEach( node => {
      const { register } = node;
      let orderNumber;

      switch ( defaultLang ) {
        case 'en':
          orderNumber = 1;
          break;

        default:
          orderNumber = 0;
      }

      const current = register[orderNumber];
      const {
        title, error, a_member_text, a_member_link, fields, gender_text, gender_options, privacy_text,
        privacy_link_text, privacy_link, already_registered_error, password_not_match, regex_error
      } = current;

      setTexts( t => ({
        ...t,
        registerTitle: title,
        registerError: error,
        registerAlreadyRegisteredError: already_registered_error,
        registerPasswordError: regex_error,
        registerPasswordNotMatchError: password_not_match,
        registerMemberText: a_member_text,
        registerMemberLink: a_member_link,
        registerGenderText: gender_text,
        registerGenderFields: gender_options,
        registerPrivacyText: privacy_text,
        registerPrivacyLinkText: privacy_link_text,
        registerPrivacyLink: privacy_link,
        registerFields: fields
      }));
    });
  }, [data, defaultLang] );

  /**
   * Processing fill a field
   *
   * @param e | Event
   * @param index | int
   */
  const handleChangeField = ( e, index ) => {
    switch ( index ) {
      case 1:
        setFormData( { ...formData, firstName: e.currentTarget.value.trimStart() } );
        break;

      case 2:
        setFormData( { ...formData, lastName: e.currentTarget.value.trimStart() } );
        break;

      case 3:
        setFormData( { ...formData, email: e.currentTarget.value.trim().toLowerCase() } );
        break;

      case 4:
        setFormData( { ...formData, password: e.currentTarget.value.trim() } );
        break;

      case 5:
        setFormData( { ...formData, confirmPassword: e.currentTarget.value.trim() } );
        break;

      default:
        setFormData( { ...formData, company: e.currentTarget.value.trimStart() } );
    }
  };

  /**
   * Register action.
   * Validating the data, passing the data to the backend or showing an error
   *
   * @param e | Event
   */
  const handleSubmit = ( e ) => {
    e.preventDefault();

    const { company, gender, firstName, lastName, email, password, confirmPassword } = formData;

    if (
      company !== '' &&
      gender !== '' &&
      firstName !== '' &&
      lastName !== '' &&
      email !== '' &&
      emailValidation
    ) {
      if (
        password !== '' &&
        passwordValidation &&
        confirmPassword !== ''
      ) {
        if ( password === confirmPassword ) {
          const prepFirstName = firstName.trim();
          const prepLastName  = lastName.trim();
          const prepUsername  = prepFirstName + prepLastName;

          const data = {
            email,
            username: prepUsername.replace( /\s/g, '' ),
            first_name: prepFirstName,
            last_name: prepLastName,
            gender,
            company_name: company.trim(),
            password,
            password2: confirmPassword,
            language: defaultLang,
            newsletter: "True"
          };

          setIsBtnProcess( true );

          postData( AUTH_URL + 'register/', 'POST', data, false )
            .then( data => {
              const response = data[0];
              const { status } = response;

              // if a user already registered
              if ( status === 409 ) {
                setIsAlreadyRegistered( true );
                setIsBtnProcess( false );
              }

              // show a thank message
              if ( status === 201 ) {
                setIsBtnProcess( false );
                dispatch( { type: CHANGE_OVERLAY, payload: 'registerMsg' } );
              }
            });
        } else {
          setIsPasswordNotMatchError( true );
          setPasswordIsInvalid( true );
          setConfirmPasswordIsInvalid( true );
        }
      } else {
        setIsPasswordError( true );

        if ( password === '' || !passwordValidation ) setPasswordIsInvalid( true );
        if ( confirmPassword === '' || password !== confirmPassword ) setConfirmPasswordIsInvalid( true );
      }
    } else {
      setIsError( true );

      if ( company === '' ) setCompanyIsInvalid( true );
      if ( gender === '' ) setGenderIsInvalid( true );
      if ( firstName === '' ) setFirstNameIsInvalid( true );
      if ( lastName === '' ) setLastNameIsInvalid( true );
      if ( email === '' || !emailValidation ) setEmailIsInvalid( true );
      if ( password === '' || !passwordValidation ) setPasswordIsInvalid( true );
      if ( confirmPassword === '' ) setConfirmPasswordIsInvalid( true );
    }
  };

  /**
   * Hide an error
   *
   * @param index | int
   */
  const handleHideError = ( index ) => {
    if ( isError ) setIsError( false );
    if ( isAlreadyRegistered ) setIsAlreadyRegistered( false );
    if ( isPasswordError ) setIsPasswordError( false );
    if ( isPasswordNotMatchError ) setIsPasswordNotMatchError( false );
    if ( companyIsInvalid && index === 0 ) setCompanyIsInvalid( false );
    if ( firstNameIsInvalid && index === 1 ) setFirstNameIsInvalid( false );
    if ( lastNameIsInvalid && index === 2 ) setLastNameIsInvalid( false );
    if ( emailIsInvalid && index === 3 ) setEmailIsInvalid( false );
    if ( passwordIsInvalid && index === 4 ) setPasswordIsInvalid( false );
    if ( confirmPasswordIsInvalid && index === 5 ) setConfirmPasswordIsInvalid( false );
  };

  /**
   * Processing if a password field has focus
   *
   * @param index | int
   */
  const handlePasswordFocus = ( index ) => {
    handleHideError( index );

    setShowHint( index === 4 );
  };

  /**
   * Show or hide the password or confirm password symbols
   *
   * @param index | int
   */
  const handleVisible = ( index ) => {
    if ( index === 4 ) {
      setPasswordIsVisible( v => !v );
      setShowHint( true );
    }

    if ( index === 5 ) setConfirmPasswordIsVisible( v => !v );
  };

  /**
   * Processing change the gender field
   *
   * @param text | string
   */
  const handleChangeGender = ( text ) => {
    setFormData( { ...formData, gender: text } );
    setIsError( false );
    setGenderIsInvalid( false );
  };

  return (
    <>
      <UserIconAdd className={ `overlay__icon ${ className }` } />
      <h4 className={ `overlay__title ${ className }` }>{ registerTitle }</h4>
      { isError ? <span className="overlay__error">{ registerError }</span> : false }
      { isAlreadyRegistered ? <span className="overlay__error">{ registerAlreadyRegisteredError }</span> : false }
      { isPasswordError ? <span className="overlay__error" dangerouslySetInnerHTML={ { __html: registerPasswordError } } /> : false }
      { isPasswordNotMatchError ? <span className="overlay__error">{ registerPasswordNotMatchError }</span> : false }
      <span className={ `overlay__helper-text ${ className }` }>
        { registerMemberText } <span className="overlay__helper-text__link"
                                     role="presentation"
                                     onClick={ () => { dispatch( { type: CHANGE_OVERLAY, payload: 'login' } ) } }>{ registerMemberLink }</span>
      </span>

      <form className="overlay__form"
            onSubmit={ ( e ) => handleSubmit( e ) }>
        { registerFields.map( ( field, index ) => {
          if ( index === 0 ) {
            const { text, type } = field;

            return (
              <div className="overlay__field-group" key={ text }>
                <input className={ companyIsInvalid ? 'overlay__field overlay__field--is-invalid' : 'overlay__field' }
                       type={ type }
                       value={ company }
                       placeholder={ text }
                       onChange={ ( e ) => handleChangeField( e, index ) }
                       onFocus={ () => handleHideError( index ) } />
              </div>
            )
          } else return false;
        })}

        <div className="overlay__gender-switcher">
          <span className={ genderIsInvalid ? 'overlay__gender-switcher__label overlay__gender-switcher__label--is-invalid' : 'overlay__gender-switcher__label' }>
            { registerGenderText }
            </span>
          <div className="overlay__gender-switcher__radios">
            { width > 991 ?
              registerGenderFields.map( field => {
                const { text } = field;

                return (
                  <React.Fragment key={ text }>
                    <input className="overlay__gender-switcher__radio"
                           id={ text }
                           type="radio"
                           name="gender"
                           checked={ gender === text }
                           hidden
                           onChange={ () => handleChangeGender( text ) } />
                    <label className="overlay__gender-switcher__radio__label"
                           htmlFor={ text }>{ text }</label>
                  </React.Fragment>
                )
              })
            :
              registerGenderFields.map( field => {
                const { text } = field;

                return (
                  <React.Fragment key={ text }>
                    <input className="overlay__gender-switcher__radio"
                           id={ text }
                           type="radio"
                           name="gender"
                           checked={ gender === text }
                           hidden
                           onChange={ () => handleChangeGender( text ) } />
                    <label className={ gender === text ? 'overlay__gender-switcher__radio__label is-checked' : 'overlay__gender-switcher__radio__label' }
                           role="presentation"
                           htmlFor={ text }
                           onClick={ () => handleChangeGender( text ) }>{ text }</label>
                  </React.Fragment>
                )
              })
            }
          </div>
        </div>

        <div className="overlay__field-group">
          { registerFields.map( ( field, index ) => {
            if ( index === 1 || index === 2 ) {
              const { text, type } = field;
              let value;

              switch ( index ) {
                case 2:
                  value = lastName;
                  break;

                default:
                  value = firstName;
              }

              const firstNameAdditionalClass = index === 1 ? 'overlay__field--half overlay__field--left' : '';
              const lastNameAdditionalClass  = index === 2 ? 'overlay__field--half overlay__field--right' : '';
              const invalidFirstName = index === 1 && firstNameIsInvalid ? ' overlay__field--is-invalid' : '';
              const invalidLastName  = index === 2 && lastNameIsInvalid ? ' overlay__field--is-invalid' : '';

              return (
                <input className={ `overlay__field ${ firstNameAdditionalClass } ${ lastNameAdditionalClass }${ invalidFirstName }${ invalidLastName }` }
                       key={ text }
                       type={ type }
                       value={ value }
                       placeholder={ text }
                       onChange={ ( e ) => handleChangeField( e, index ) }
                       onFocus={ () => handleHideError( index ) } />
              )
            } else return false;
          })}
        </div>

        { registerFields.map( ( field, index ) => {
          if ( index > 2 ) {
            const { text, type } = field;
            let value;

            switch ( index ) {
              case 4:
                value = password;
                break;

              case 5:
                value = confirmPassword;
                break;

              default:
                value = email;
            }

            const invalidPassword        = index === 4 && passwordIsInvalid ? ' overlay__field--is-invalid' : '';
            const invalidConfirmPassword = index === 5 && confirmPasswordIsInvalid ? ' overlay__field--is-invalid' : '';

            return (
              <div className="overlay__field-group" key={ text }>
                { type === 'password' ?
                  <>
                    <input className={ `overlay__field overlay__field--password${ invalidPassword }${ invalidConfirmPassword }` }
                           type={ ( index === 4 && passwordIsVisible ) || ( index === 5 && confirmPasswordIsVisible ) ? 'text' : 'password' }
                           value={ value }
                           placeholder={ text }
                           onChange={ ( e ) => handleChangeField( e, index ) }
                           onFocus={ () => handlePasswordFocus( index ) }
                           onBlur={ () => setShowHint( false ) } />
                    <span className="overlay__field__icon"
                          role="presentation"
                          onClick={ () => handleVisible( index ) }>
                      { value !== '' ?
                        ( index === 4 && passwordIsVisible ) || ( index === 5 && confirmPasswordIsVisible ) ?
                          <EyeVisibleOffIcon className="overlay__field__icon__item" />
                        :
                          <EyeVisibleOnIcon className="overlay__field__icon__item" />
                      : false }
                    </span>

                    { showHint && index === 4 ? <PasswordHint password={ password } /> : false }
                  </>
                :
                  <input className={ emailIsInvalid ? 'overlay__field overlay__field--is-invalid' : 'overlay__field' }
                         type={ type }
                         value={ value }
                         placeholder={ text }
                         onChange={ ( e ) => handleChangeField( e, index ) }
                         onFocus={ () => handleHideError( index ) } />
                }
              </div>
            )
          } else return false;
        })}

        <div className="overlay__additional-actions">
          <span className={ `overlay__privacy ${ className }` }>
            { registerPrivacyText } <a className="overlay__privacy__link"
                                       href={ registerPrivacyLink }
                                       target="_blank"
                                       rel="noreferrer nofollow">{ registerPrivacyLinkText }</a>
          </span>
        </div>

        <Button className={ `overlay__register__btn${ isBtnProcess ? ' processing' : '' }` }
                text={ registerTitle }
                disabled={ isBtnProcess }
                method="submit" />
      </form>
    </>
  )
});

Register.defaultProps = {
  className: ''
};

Register.propTypes = {
  className: PropTypes.string
};

export default Register;