import React, { memo, useContext, useState, useEffect } from 'react';
import { useStaticQuery, graphql }                      from 'gatsby';

// import constants
import { AUTH_URL, CHANGE_OVERLAY } from '../../constants';

// import context
import { GlobalDispatchContext, GlobalStateContext } from '../../context/GlobalContextProvider';

// import helpers
import { useEmailValidation, postData } from '../../helpers';

// import components
import UserIconCircled from '../elements/icons/UserIconCircled';
import Button          from '../elements/Button';

const ForgotPassword = memo( () => {
  const data = useStaticQuery( graphql`
    {
      allApiPoints {
        nodes {
          forgot {
            button_text
            error
            login_link
            login_text
            placeholder
            register_link
            register_text
            title
          }
        }
      }
    }
  `);

  // define the component context
  const state    = useContext( GlobalStateContext );
  const dispatch = useContext( GlobalDispatchContext );
  const { defaultLang } = state;

  // define the default component state
  const [isError, setIsError]   = useState( false );
  const [formData, setFormData] = useState( { email: '' } );
  const [isBtnProcess, setIsBtnProcess] = useState( false );
  const [emailIsInvalid, setEmailIsInvalid] = useState( false );
  const [texts, setTexts] = useState({
    forgotTitle: '',
    forgotLoginText: '',
    forgotLoginLink: '',
    forgotRegisterText: '',
    forgotRegisterLink: '',
    forgotPlaceholder: '',
    forgotButtonText: '',
    forgotError: ''
  });

  const { email } = formData;
  const {
    forgotTitle, forgotLoginText, forgotLoginLink, forgotRegisterText, forgotRegisterLink,
    forgotPlaceholder, forgotButtonText, forgotError
  } = texts;

  // define the component custom hooks
  const validation = useEmailValidation( email );

  /**
   * Get texts from the backend and processing changing language
   */
  useEffect( () => {
    // get loaded data from graphql
    data.allApiPoints.nodes.forEach( node => {
      const { forgot } = node;
      let orderNumber;

      switch ( defaultLang ) {
        case 'en':
          orderNumber = 1;
          break;

        default:
          orderNumber = 0;
      }

      const current = forgot[orderNumber];
      const {
        title, login_text, login_link, register_text, register_link, placeholder, button_text, error
      } = current;

      setTexts( t => ({
        ...t,
        forgotTitle: title,
        forgotLoginText: login_text,
        forgotLoginLink: login_link,
        forgotRegisterText: register_text,
        forgotRegisterLink: register_link,
        forgotPlaceholder: placeholder,
        forgotButtonText: button_text,
        forgotError: error
      }));
    });
  }, [data, defaultLang] );

  /**
   * Hide an error
   */
  const handleHideError = () => {
    if ( isError ) setIsError( false );
    if ( emailIsInvalid ) setEmailIsInvalid( false );
  };

  /**
   * Processing a send action.
   * Validating the data, passing the data to the backend or showing an error
   *
   * @param e | Event
   */
  const handleSubmit = ( e ) => {
    e.preventDefault();

    const { email } = formData;

    if ( email !== '' && validation ) {
      const data = {
        email,
        language: defaultLang
      };

      setIsBtnProcess( true );

      postData( AUTH_URL + 'request-reset-email/', 'POST', data, false )
        .then( data => {
          setIsBtnProcess( false );

          // if the email is valid
          if ( data[0].status === 200 ) {
            setIsBtnProcess( false );
            dispatch( { type: CHANGE_OVERLAY, payload: 'forgotMsg' } );
          }
        });
    } else {
      setIsError( true );

      if ( email === '' || !validation ) setEmailIsInvalid( true );
    }
  };

  return (
    <>
      <UserIconCircled className="overlay__icon" />
      <h4 className="overlay__title">{ forgotTitle }</h4>
      { isError ? <span className="overlay__error overlay__error--forgot">{ forgotError }</span> : false }
      <span className="overlay__helper-text overlay__helper-text--forgot">
        { forgotLoginText } <span className="overlay__helper-text__link"
                                  role="presentation"
                                  onClick={ () => { dispatch( { type: CHANGE_OVERLAY, payload: 'login' } ) } }>{ forgotLoginLink }</span>
      </span>
      <span className="overlay__helper-text">
        { forgotRegisterText } <span className="overlay__helper-text__link"
                                     role="presentation"
                                     onClick={ () => { dispatch( { type: CHANGE_OVERLAY, payload: 'register' } ) } }>{ forgotRegisterLink }</span>
      </span>

      <form className="overlay__form"
            onSubmit={ ( e ) => handleSubmit( e ) }>
        <div className="overlay__field-group">
          <input className={ emailIsInvalid ? 'overlay__field overlay__field--is-invalid' : 'overlay__field' }
                 type="text"
                 value={ email }
                 placeholder={ forgotPlaceholder }
                 onChange={ ( e ) => { setFormData( { ...formData, email: e.currentTarget.value.trim().toLowerCase() } ) } }
                 onFocus={ handleHideError } />
        </div>

        <Button className={ `overlay__login__btn${ isBtnProcess ? ' processing' : '' }` }
                text={ forgotButtonText }
                disabled={ isBtnProcess }
                method="submit" />
      </form>
    </>
  )
});

export default ForgotPassword;