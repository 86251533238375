import React, { memo } from 'react';
import PropTypes       from 'prop-types';

// import styles
import { ButtonElement } from '../../styles/elements/Button';

const Button = memo( ( { className, text, color, type, method, disabled, onClick } ) => {
  /**
   * Processing click action.
   *
   * @param e | Event
   */
  const handleClick = ( e ) => {
    if ( onClick !== undefined ) onClick( e );
  };

  const buttonClass = `${ className } ${ type } ${ color }`;

  return (
    <ButtonElement className={ buttonClass }
                   type={ method }
                   disabled={ disabled }
                   onClick={ ( e ) => handleClick( e ) }>
      { text }
    </ButtonElement>
  )
});

Button.defaultProps = {
  className: '',
  text: '',
  color: 'blue',
  type: 'normal',
  method: 'button',
  disabled: false,
  onClick: () => {}
};

Button.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.string,
  method: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func
};

export default Button;