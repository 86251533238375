import styled from 'styled-components';

export const CategoriesElement = styled.section`;
  position: fixed;
  left: 0;
  top: 75px;
  width: 100%;
  overflow: hidden;
  background-color: var(--white);
  box-shadow: -4px 4px 6px rgba(var(--black--rgb), .16);
  transition: .4s height ease-in;
  z-index: 5;
  
  &.is-opened {
    transition: .4s height ease-out;
  }
  
  .container {
    height: 100%;
  }
  
  .categories {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    transition: .4s all ease-in-out;
  }
  
  .title {
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: var(--theme-deep-purple);
  }
  
  .list {
    display: flex;
    height: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    
    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 200px;
      min-width: 155px;
      border-left: 1px solid var(--theme-gray);
      transition: .3s all ease-in-out;
      
      &:hover {
        cursor: pointer;
       
        .list {
          &__item {
            &__link {
              color: var(--theme-blue);
              
              &__arrow {
                path {
                  stroke: var(--theme-blue);
                }
              }
            }
            
            &__children {
              animation: dropdownFadeIn .4s ease forwards;
              animation-delay: .3s;
            }
          }
        }
      }
      
      &__link {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 0 30px;
        text-align: center;
        line-height: 19px;
        text-decoration: none;
        color: var(--theme-deep-purple);
        transition: .3s all ease-in-out;
        
        &[aria-current="page"] {
          color: var(--theme-blue);
        }
        
        &--additional-padding {
          padding-right: 10px;
        }
        
        &__arrow {
          --width: 6px;
          --height: 10px;
        
          position: absolute;
          top: calc(50% - (var(--height) / 2));
          right: -10px;
          width: var(--width);
          height: var(--height);
          
          path {
            transition: .3s all ease-in-out;
          }
        }
      }
      
      &__children {
        position: absolute;
        left: 0;
        top: 70px;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;
        margin: 0;
        opacity: 0;
        visibility: hidden;
        padding: 65px 0 0 0;
        list-style: none;
        animation: dropdownFadeOut .2s ease forwards;
        
        &::before {
          content: '';
          position: absolute;
          top: 35px;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: var(--theme-gray);
        }
        
        &__item {
          width: 180px;
          margin-bottom: 35px;
          line-height: 19px;
          transition: .3s all ease-in-out;
          
          &:hover {
            color: var(--theme-blue);
          }
        }
      }
    }
  }
  
  /*-------------------------
  # dropdownFadeOut
  --------------------------*/
  @keyframes dropdownFadeIn {
    0% {
      opacity: 0;
      visibility: hidden;
    }
    
    100% {
      opacity: 1;
      visibility: visible;
    }
  }
  
  /*-------------------------
  # dropdownFadeOut
  --------------------------*/
  @keyframes dropdownFadeOut {
    0% {
      opacity: 1;
      visibility: visible;
    }
    
    100% {
      opacity: 0;
      visibility: hidden;
    }
  }
  
  /*-------------------------
  # Media Queries
  --------------------------*/
  @media (max-width: 1440px) {
  
    .list {
      &__item {
        &__children {
          top: 50px;
          justify-content: flex-start;
        
          &__item {
            width: 160px;
          }
        }
      }
    }
  }
  
  @media (max-width: 1280px) {
    .title {
      width: 120px;
    }
    
    .list {
      &__item {
        max-width: 190px;
        
        &__children {
          &__item {
            width: 140px;
          }
        }
      }
    }
  }
  
  @media (max-width: 1100px) {
    .list {
      &__item {
        max-width: 170px;
        
        &__children {
          &__item {
            width: 170px;
          }
        }
      }
    }
  }
  
  @media (max-width: 1024px) {
    .list {
      &__item {
        max-width: 165px;
        
        &__children {
          &__item {
            width: 190px;
          }
        }
      }
    }
  }
`;