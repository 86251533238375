import React, { memo, useState, useEffect, useContext } from 'react';
import { graphql, useStaticQuery }                      from 'gatsby';
import PropTypes                                        from 'prop-types';

// import constants
import { CHANGE_OVERLAY, SHOW_OVERLAY } from '../../../constants';

// import context
import { GlobalDispatchContext, GlobalStateContext } from '../../../context/GlobalContextProvider';

import { useGetCookie } from '../../../helpers';

// import utils
import { getUser } from '../../../utils/Auth';

// import styles
import { HeaderNavElement } from './styles';

// import components
import UserIcon   from '../icons/UserIcon';
import MMLogoIcon from '../icons/MMLogoIcon';
import RSSIcon    from '../icons/RSSIcon';
import GlobeIcon  from '../icons/GlobeIcon';

/**
 * The HeaderNav Component
 *
 * @param currentLang | string
 */
const HeaderNav = memo( ( { currentLang } ) => {
  const data = useStaticQuery( graphql`
    {
      allApiPoints {
        nodes {
          login_links {
            login_text
            register_text
          }
        }
      }
    }
  `);

  // define the component context
  const state    = useContext( GlobalStateContext );
  const dispatch = useContext( GlobalDispatchContext );
  const { isLoggedIn } = state;

  // get session from cookie
  const session = useGetCookie( 'MMBlogSession' );

  // get user
  const user = getUser( session );

  // define the default component state
  const [texts, setTexts] = useState({
    loginText: '',
    registerText: ''
  });

  const { loginText, registerText } = texts;

  /**
   * Get texts from the backend and processing changing language
   */
  useEffect( () => {
    // get loaded data from graphql
    data.allApiPoints.nodes.forEach( node => {
      const { login_links } = node;
      let orderNumber;

      switch ( currentLang ) {
        case 'en':
          orderNumber = 1;
          break;

        default:
          orderNumber = 0;
      }

      // set up texts
      const current = login_links[orderNumber];
      const { login_text, register_text } = current;

      setTexts( t => ({
        ...t,
        loginText: login_text,
        registerText: register_text
      }));
    });
  }, [data, currentLang] );

  /**
   * Set overlay type
   *
   * @param type | string
   */
  const handleOverlayType = ( type ) => {
    dispatch( { type: CHANGE_OVERLAY, payload: type } );
    dispatch( { type: SHOW_OVERLAY, payload: true } );
  };

  return (
    <HeaderNavElement className="header-nav" role="navigation">
      <div className="header-nav__login">
        <UserIcon className="header-nav__login__icon" />
        { isLoggedIn ?
          <span className="header-nav__login__link"
                role="presentation"
                onClick={ () => handleOverlayType( 'account' ) }>{ user.username }</span>
        :
          <>
            <span className="header-nav__login__link"
                  role="presentation"
                  onClick={ () => handleOverlayType( 'login' ) }>{ loginText }</span>
            <span className="header-nav__login__separator" />
            <span className="header-nav__login__link"
                  role="presentation"
                  onClick={ () => handleOverlayType( 'register' ) }>{ registerText }</span>
          </>
        }
      </div>

      <a className="header-nav__mm-logo"
         href="https://www.marketingmonkeys.ch/"
         target="_blank"
         title="Marketing Monkeys"
         rel="noreferrer">
        <MMLogoIcon className="header-nav__mm-logo__icon" />
      </a>

      <RSSIcon className="header-nav__rss"
               role="presentation"
               onClick={ () => handleOverlayType( 'rss' ) }
               border />

      <span className="header-nav__lang__wrapper"
            role="presentation"
            onClick={ () => handleOverlayType( 'lang' ) }>
        <>
          <GlobeIcon className="header-nav__lang" />
          <span className="header-nav__lang__indicator">{ currentLang }</span>
        </>
      </span>
    </HeaderNavElement>
  );
});

HeaderNav.defaultProps = {
  currentLang: 'de',
};

HeaderNav.propTypes = {
  currentLang: PropTypes.string.isRequired
};

export default HeaderNav;