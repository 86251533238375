import React, { memo } from 'react';
import PropTypes       from 'prop-types';

const TwitterIcon = memo( ( { className, color } ) => {
  return (
    <svg className={ className }
         xmlns="http://www.w3.org/2000/svg"
         width="22.411"
         height="18.459"
         viewBox="0 0 22.411 18.459">
      <g transform="translate(0 0)">
        <g transform="translate(0)">
          <g>
            <path d="M1006.884,761.819a9.217,9.217,0,0,1-2.264.647c.365-.061.9-.721,1.117-.988a4.171,4.171,0,0,0,.754-1.373c.02-.039.035-.088,0-.118a.129.129,0,0,0-.12.01,11.584,11.584,0,0,1-2.689,1.029.181.181,0,0,1-.185-.049,2.054,2.054,0,0,0-.235-.24,4.717,4.717,0,0,0-1.3-.8,4.525,4.525,0,0,0-2-.322,4.776,4.776,0,0,0-1.9.535,4.885,4.885,0,0,0-1.529,1.249,4.7,4.7,0,0,0-.914,1.829,4.953,4.953,0,0,0-.048,1.931c.014.108.005.123-.093.108a14.362,14.362,0,0,1-9.24-4.7c-.108-.123-.166-.123-.255.009a4.688,4.688,0,0,0,.8,5.536c.181.171.367.343.568.5a4.72,4.72,0,0,1-1.783-.5c-.108-.068-.162-.03-.171.093a2.791,2.791,0,0,0,.029.529,4.739,4.739,0,0,0,2.92,3.778,2.749,2.749,0,0,0,.593.181,5.281,5.281,0,0,1-1.749.054c-.127-.025-.176.039-.127.161a4.919,4.919,0,0,0,3.674,3.077c.167.029.333.029.5.068-.011.016-.021.016-.03.03a5.827,5.827,0,0,1-2.509,1.329,8.987,8.987,0,0,1-3.811.488c-.2-.03-.248-.028-.3,0s-.007.083.059.137c.26.172.524.324.793.471a12.585,12.585,0,0,0,2.547,1.019,13.568,13.568,0,0,0,13.173-3.08c2.7-2.682,3.645-6.38,3.645-10.084,0-.145.171-.225.273-.3a8.875,8.875,0,0,0,1.785-1.864.545.545,0,0,0,.11-.357v-.02C1006.969,761.76,1006.976,761.777,1006.884,761.819Z"
                  transform="translate(-984.558 -759.606)"
                  fill={ color } />
          </g>
          <g transform="translate(6.655 18.38)">
            <path d="M994.006,785.7Z"
                  transform="translate(-994.006 -785.696)"
                  fill={ color }
                  fillRule="evenodd" />
          </g>
          <g transform="translate(6.484 18.38)">
            <path d="M993.934,785.7c-.03,0-.37,0,0,0Z"
                  transform="translate(-993.763 -785.696)"
                  fill={ color }
                  fillRule="evenodd" />
          </g>
          <g transform="translate(7.41 18.382)">
            <path d="M995.077,785.717c0-.038.4,0,0,0C995.077,785.7,995.125,785.717,995.077,785.717Z"
                  transform="translate(-995.077 -785.7)"
                  fill={ color }
                  fillRule="evenodd" />
          </g>
          <g transform="translate(15.244 0.027)">
            <path d="M1006.289,759.645a.078.078,0,0,1-.092,0Z"
                  transform="translate(-1006.197 -759.645)"
                  fill={ color }
                  fillRule="evenodd" />
          </g>
        </g>
      </g>
    </svg>
  );
});

TwitterIcon.defaultProps = {
  className: '',
  color: '#fff'
};

TwitterIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

export default TwitterIcon;