import React, { memo } from 'react';
import PropTypes       from 'prop-types';

// import styles
import { HeaderHamburgerElement } from './styles';

const HeaderHamburger = memo( ( { setIsOpened } ) => {
  /**
   * Processing to open the Mobile menu
   */
  const handleClick = () => {
    if ( setIsOpened !== undefined ) {
      setIsOpened();
    }
  };

  return (
    <HeaderHamburgerElement xmlns="http://www.w3.org/2000/svg"
                            width="28.676"
                            height="20.058"
                            viewBox="0 0 28.676 20.058"
                            onClick={ handleClick }>
      <path d="M2279.272,2617.644h22.94c1.584,0,2.868-.873,2.868-1.951s-1.283-1.951-2.868-1.951h-22.94c-1.584,0-2.868.873-2.868,1.951s1.283,1.951,2.868,1.951Zm0,0"
            transform="translate(-2276.404 -2613.742)"
            fill="#eaeaea"/>
      <path d="M2302.212,2657.908h-22.94c-1.584,0-2.868.873-2.868,1.951s1.283,1.951,2.868,1.951h22.94c1.584,0,2.868-.873,2.868-1.951s-1.283-1.951-2.868-1.951Zm0,0"
            transform="translate(-2276.404 -2649.83)"
            fill="#eaeaea"/>
      <path d="M2302.212,2702.073h-22.94c-1.584,0-2.868.873-2.868,1.951s1.283,1.951,2.868,1.951h22.94c1.584,0,2.868-.873,2.868-1.951s-1.283-1.951-2.868-1.951Zm0,0"
            transform="translate(-2276.404 -2685.917)"
            fill="#eaeaea"/>
    </HeaderHamburgerElement>
  );
});

HeaderHamburger.defaultProps = {
  setIsOpened: () => {}
};

HeaderHamburger.propTypes = {
  setIsOpened: PropTypes.func.isRequired
};

export default HeaderHamburger;