import styled from 'styled-components';

// import assets
import footerBg from '../../assets/images/footer__bg.svg';

export const FooterElement = styled.footer`
  --footerTileHeight: 24em;
  --footerBottomHeight: 28em;
  
  overflow: hidden;
  
  .footer {
    &-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      min-height: calc(var(--footerTileHeight) + var(--footerBottomHeight));
    }
    
    &-top {
      position: relative;
      top: 50px;
      display: flex;
      align-items: flex-start;
      justify-content: space-evenly;
      z-index: 2;
    }
    
    &-bottom {
      position: relative;
      height: var(--footerBottomHeight);
      overflow: hidden;
      background: transparent -o-linear-gradient(173deg,#140048 0,#3e2271 100%) 0 0 no-repeat;
      background: transparent linear-gradient(277deg,#140048 0,#3e2271 100%) 0 0 no-repeat;
      z-index: 1;
      
      &__decorative {
        --height: 510px;
        
        position: absolute;
        left: 0;
        top: -20px;
        width: 100%;
        height: var(--height);
        background: url(${ footerBg }) no-repeat center transparent;
        background-size: contain;
        transform: translate(0,-50%) rotateX(0);
        animation: 40s linear infinite footerRotation;
        transition: .3s all ease-in-out;
      }
      
      &__container {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 5.5em 0 1em 0;
      }
    }
    
    &__logo {
      &__icon {
        position: relative;
        top: -2px;
        display: block;
        width: 178px;
        height: 132px;
      }
    }
  }
  
  .infobox {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 35%;
    height: var(--footerTileHeight);
    border-radius: 8px;
    padding: 1.75em 2em 1.75em 2em;
    background-color: var(--white);
    box-shadow: rgba(0, 0, 0, .25) -5px -5px 70px;
    
    &__title {
      margin: 0;
      text-transform: uppercase;
      font-size: 38px;
      font-weight: 800;
      line-height: 40px;
      color: var(--theme-deep-purple);
      
      .colored {
        color: var(--theme-blue);
      }
    }
    
    &__btn {
      position: absolute;
      bottom: 30px;
      width: calc(100% - 80px);
      min-width: 13em;
      height: 4em;
      align-self: center;
      font-size: 1em;
      line-height: 1em;
    }
    
    &__description {
      margin: 70px 0 0 0;
      font-size: 1em;
      line-height: 1.8em;
      letter-spacing: .072em;
    }
    
    &__form {
      position: relative;
      width: 100%;
      
      &--is-loading {
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          opacity: .25;
          border-radius: 48px;
          background-color: var(--theme-blue);
          z-index: 1;
        }
      }
      
      &__wrapper {
        position: absolute;
        width: calc(100% - 80px);
        left: 40px;
        bottom: 30px;
      }
      
      &__field {
        width: 100%;
        height: 4em;
        border: 2px solid var(--theme-deep-purple);
        border-radius: 48px;
        padding: 0 35px 0 1em;
        background: var(--white);
        transition: .3s all ease-in-out;
        
        &::placeholder {
          text-transform: uppercase;
          color: var(--theme-dark-gray);
        }
        
        &:focus {
          outline: none;
        }
        
        &:disabled {
          border-color: rgba(var(--theme-light-purple--rgb), .3); 
          background-color: rgba(85, 144, 193, .3);
          
          &::placeholder {
            color: var(--white);
          }
        }
      }
      
      &__btn {
        --dimensions: 2.7em;
      
        position: absolute;
        top: 0.65em;
        right: 0.65em;
        display: flex;
        justify-content: center;
        align-items: center;
        width: var(--dimensions);
        height: var(--dimensions);
        border: 2px solid var(--theme-deep-purple);
        border-radius: 50%;
        padding: 0;
        background-color: transparent;
        transform: rotate(0deg);
        transition: transform 0.3s ease-in-out 0s;
        
        &:hover {
          transform: rotate(1turn);
          cursor: pointer;
        }
        
        
        &:disabled {
          border-color: var(--white);
          
          svg {
            path {
              stroke: var(--white);
            }
          }
        }
      }
      
      &__error {
        position: absolute;
        left: 25px;
        bottom: -25px;
        font-weight: 600;
        color: var(--theme-error);
      }
    }
  }
  
  .contacts {
    display: flex;
    flex-direction: column;
    margin: 35px 0 10px 0;
    
    &__item {
      position: relative;
      display: flex;
      align-items: center;
      height: 2em;
      max-width: fit-content;
      margin-bottom: 5px;
      padding-left: 3em;
      
      &:hover {
        cursor: pointer;
        
        .contacts {
          &__item {
            &__icon {
              &::before {
                border-top-color: var(--theme-dark-purple);
                border-right-color: var(--theme-dark-purple);
                border-bottom-color: var(--theme-dark-purple);
                transition: border-top-color 0.15s linear 0s, border-right-color 0.15s linear 0.1s, border-bottom-color 0.15s linear 0.2s;
              }
              
              &::after {
                border-top: 2px solid var(--theme-purple);
                border-left-width: 2px;
                border-right-width: 2px;
                transform: rotate(270deg);
                transition: transform 0.4s linear 0s, border-left-width 0s linear 0.35s; 
              }
            }
          }
        }
      }
      
      &__icon {
        --dimensions: 33px;
        
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: var(--dimensions);
        height: var(--dimensions);
        transition: all 0.3s ease-out 0s;
        
        &::before,
        &::after {
          content: "";
          box-sizing: border-box;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          transition: all 0.3s ease-in-out 0s;
        }
        
        &::before {
          border: 2px solid transparent;
        }
        
        &::after {
          border: 0px solid transparent;
        }
      }
      
      &__text {
        position: relative;
        text-decoration: none;
        font-size: 1em;
        line-height: 1em;
        letter-spacing: 1.15px;
        color: var(--theme-deep-purple);
        
        &--long {
          max-width: 220px;
        }
        
        &--linkedin {
          position: relative;
          top: 3px;
        }
        
        &:hover {
          &::after {
            width: 100%;
          }
        }
        
        &::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: -2px;
          width: 0;
          height: 1px;
          background-color: var(--theme-deep-purple);
          transition: width 0.3s ease-in-out 0s;
        }
      }
    }
  }
  
  .nav {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    
    &__logo {
      &__icon {
        position: relative;
        top: 13px;
        display: block;
      }
    }
  }
  
  .copyright {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-bottom: 20px;
    padding-bottom: 19px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    text-transform: uppercase;
    color: var(--white);
  
    &__link {
      position: relative;
      text-decoration: none;
      color: var(--white);
      transition: all 0.3s ease-in-out 0s;
      
      &:hover {
        &::after {
          transform: scale(1);
        }
      }
      
      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: var(--white);
        transform: scaleX(0);
        transition: transform 0.3s ease-in-out 0s;
      }
    }
  }
  
  .to-top {
    position: absolute;
    right: 15px;
    bottom: 470px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 67px;
    height: 61px;
    opacity: 0;
    border: 3px solid #6C4C9F;
    border-radius: 8px;
    z-index: 3;
    
    &:hover {
      cursor: pointer;
    }
  
    &__icon {
      --dimensions: 36px;
    
      width: var(--dimensions);
      height: var(--dimensions);
    }
    
    &__detect {
      position: absolute;
      left: 0;
      bottom: 350px;
    }
    
    &.toTopFadeIn {
      animation: toTopFadeIn .5s ease forwards;
      transition: .3s all ease-in;
    }
    
    &.toTopFadeOut {
      animation: toTopFadeOut .5s ease forwards;
      transition: .3s all ease-out;
    }
  }
  
  /*-------------------------
  # footerRotation
  --------------------------*/
  @keyframes footerRotation {
    from {
      transform: rotateX(0deg);
    }
  
    to {
      transform: rotateX(1turn);
    }
  }
  
  /*-------------------------
  # toTopFadeIn
  --------------------------*/
  @keyframes toTopFadeIn {
    0% {
      opacity: 0;
      right: -100px;
    }
    
    100% {
      opacity: 1;
      right: 15px;
    }
  }
  
  /*-------------------------
  # toTopFadeOut
  --------------------------*/
  @keyframes toTopFadeOut {
    0% {
      opacity: 1;
      right: 15px;
    }
    
    100% {
      opacity: 0;
      right: -100px;
    }
  }
  
  /*-------------------------
  # Media Queries
  --------------------------*/
  @media (max-width: 1440px) {
    .infobox {
      &__title {
        font-size: 34px;
      }
    }
  
    .contacts {
      &__item {
        &__text {
          &--linkedin {
            top: 5px;
          }
        }
      }
    }
  }
  
  @media (max-width: 1439px) {
    --footerTileHeight: 340px;
    --footerBottomHeight: 370px;
    
    .infobox {
      padding: 20px;
    }
  }
  
  @media (max-width: 1280px) { 
    --footerTileHeight: 360px;
  
    .footer-top {
      justify-content: space-between;
    }
  
    .infobox {
      width: 540px;
    
      &__description {
        font-size: 16px;
        line-height: 27px;
      }
    }
    
    .contacts {
      margin: 20px 0 10px 0;
      
      &__item {
        &__text {
          line-height: 25px;
        
          &--long {
            margin-top: 5px;
          }
        }
      }
    }
    
    .nav {
      justify-content: space-around;
      
      &__logo {
        &__icon {
          width: 125px;
        }
      }
    }
    
    .to-top {
      bottom: 270px;
      
      &__detect {
        bottom: 220px;
      }
    }
  }
  
  @media (max-width: 1199px) {
    .footer {
      &-top {
        justify-content: space-evenly;
      }
      
      &__logo {
        &__icon {
          top: 0;
          width: 110px;
          height: 80px;
        }
      }
    }
  
    .infobox {
      width: 460px;
    }
    
    .contacts {
      &__item {
        margin-bottom: 8px;
        padding-left: 45px;
      
        &__text {
          line-height: 18px;
        }
      }
    }
  }
  
  @media (max-width: 1024px) {
    --footerTileHeight: 22em;
    --footerBottomHeight: 28em;
    
    .footer-top {
      top: 15px;
    }
  
    .bottom {
      height: 235px;
    }
    
    .footer-bottom {
      &__container {
        padding: 2em 0 1em 0;
      }
    }
    
    .infobox {
      width: 430px;
      height: var(--footerTileHeight);
      padding: 1.75em 2em 1.75em 2em;
      
      &__title {
        font-size: 2em;
      }
      
      &__btn {
        bottom: 20px;
      }
      
      &__form {
        &__wrapper {
          bottom: 20px;
        }
      }
    }
    
    .nav {
      &__logo {
        &__icon {
          width: 90px;
          height: auto;
        }
      }
    }
    
    .to-top {
      bottom: 390px;
    }
  }
  
  @media (max-width: 991px) {
    --footerBottomHeight: calc(50vh - 5px);
    --footerTileHeight: calc(30vh - 5px);
    
    .footer-top {
      top: 140px;
      flex-direction: column;
      align-items: center;
    }
  
    .footer-bottom {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      
      &__container {
        flex-direction: column;
        align-items: center;
        margin-bottom: 0.5em;
        padding-top: 1.5em;
      }
    }
  
    .top {
      top: 30px;
    }
  
    .bottom {
      height: 420px;
      
      &::before {
        background-position: left center;
      }
    }
    
    .infobox {
      width: calc(50% - 15px);
      padding: 15px;
      
      &__title {
        text-align: center;
        font-size: 1.5em;
        line-height: 1.5em;
      }
      
      &:nth-child(2) {
        height: auto;
        margin-top: 30px;
        padding: 0;
        background: transparent;
        box-shadow: none;
      
        .infobox {
          &__title,
          &__description {
            display: none;
          }
        }
      }
      
      &__form {
        &__wrapper {
          position: relative;
          bottom: 0;
          left: -40px;
          width: calc(100% + 80px);
        }
      
        &__field {
          height: 4em;
          border: 2px solid var(--theme-dark-purple);
          padding-left: 1em;
          font-size: 16px;
          font-weight: 500;
          line-height: 19px;
          text-transform: initial;
        }
        
        &__btn {
         --dimensions: 2.7em;
         
          right: 0.65em;
          top: 0.65em;
        }
        
        &__error {
          left: 15px;
          bottom: -20px;
          font-size: 12px;
          line-height: 16px;
        }
      }
      
      &__btn {
        font-size: 0.8em;
        min-width: 18em;
        height: 4em;
        margin-top: 0.5em;
      }
    }
    
    .contacts {
      align-items: center;
      margin: 15px 0 0 0;
    
      &__item {
        height: 1.5em;
        width: 280px;
        max-width: initial;
        margin-bottom: 0.5em;
        padding: 0.5em 0 0 2.5em;
        
        &__icon {
          --dimensions: 33px;
        }
        
        &__text {
          font-size: 0.8em;
          line-height: 1em;
        }
      }
    }
    
    .nav {
      flex-direction: column;
      align-items: center;
      
      &__logo {
        &__icon {
          margin-bottom: 30px;
        }
      }
    }
    
    .footer {
      &__logo {
        margin-bottom: 7px;
      }
    }
    
    .copyright {
      position: static;
      font-size: 0.7em;
      padding-top: 0;
      margin-bottom: 33px;
      padding-bottom: 0;
    }
    
    .to-top {
      --toTopDimensions: 50px;
      
      right: 10px;
      top: calc(36vh - -2px);
      bottom: 20px;
      width: var(--toTopDimensions);
      height: var(--toTopDimensions);
      border: none;
      border-radius: 10px;
      background-color: #d1d3d4;
      
      &__icon {
        --dimensions: 20px;
      }
    }
    
    @keyframes toTopFadeIn {
      0% {
        opacity: 0;
        right: -100px;
      }
      
      100% {
        opacity: 1;
        right: 10px;
      }
    }
  
    @keyframes toTopFadeOut {
      0% {
        opacity: 1;
        right: 10px;
      }
      
      100% {
        opacity: 0;
        right: -100px;
      }
    }
  }
  
  @media (max-width: 640px) {
    --footerTileHeight: calc(32vh - 5px);
  
    .top {
      top: 55px;
    }
    
    .bottom {
      height: 340px;
    }
  
    .infobox {
      padding: 15px;
      width: calc(70% - 13px);
    }
    
    .contacts {
      margin: 0;
      
      &__item {
        height: 1.5em;
        margin-bottom: 0.5em;
        padding: 0.5em 0 0 2.7em;
        
        &__text {
          font-size: 0.8em;
          line-height: 1.25em;
          
          &--long {
            margin-top: 1px;
          }
          
          &--linkedin {
            top: 6px;
          }
        }
      }
    }
    
    .footer {
      &__logo {
        margin-bottom: 8px;
        
        &__icon {
          width: 70px;
          height: 50px;
        }
      }
    }
    
    .copyright {
      margin-bottom: 32px;
    }
    
    .to-top {
      top: calc(39vh - -3px);
    }
  }
  
  @media (max-width: 480px) {
    padding-top: 40px;
  
    .top {
      top: 20px;
    }
  
    .bottom {
      height: 265px;
      padding-top: 50px;
    }
    
    .infobox {
      width: calc(70% - -1px);
      height: auto;
      
      &__subtitle {
        margin: 25px 0 10px 0;
        text-align: center;
        font-size: 11px;
        line-height: 16px;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 1.2px;
      }
      
      &__form {
        &__field {
          height: 3.5em;
        }
        
        &__btn {
          right: 6px;
          top: 7px;  
        }
      }
      
      &__btn {
        position: static;
        font-size: 0.8em;
        min-width: 18em;
        height: 4em;
        margin-top: 15px;
      }
    }
    
    .nav {
      &__logo {
        display: none;
      }
    }
    
    .footer {
      &__logo {
        display: none;
      }
    }
    
    .contacts {
      &__item {
        padding-left: calc(3.5em - 2px);
      
        &__icon {
          --dimensions: 20px;
          
          top: 6px;
          left: 10px;
          margin-left: 1.2em;
          padding-right: 0.5em;
        }
      }
    }
    
    .to-top {
      top: calc(50% - 13px);
      right: 8px;
      background-color: #e3e3e3;
      
      &__detect {
        bottom: 300px;
      }
    }
    
    @keyframes toTopFadeIn {
      0% {
        opacity: 0;
        right: 8px;
        bottom: 300px;
      }
      
      100% {
        opacity: 1;
        right: 8px;
        bottom: 560px;
      }
    }
  
    @keyframes toTopFadeOut {
      0% {
        opacity: 1;
        right: 8px;
        bottom: 560px;
      }
      
      100% {
        opacity: 0;
        right: 8px;
        bottom: 300px;
      }
    }
  }
  
  @media (max-width: 440px) {
    .infobox {
      width: calc(82% - 2px);
    }
  }
  
  @media (max-width: 395px) {
    --footerBottomHeight: calc(48vh - 5px);
  
    padding-top: 45px;
  
    .top {
      top: 20px;
    }
  
    .bottom {
      height: 250px;
      padding-top: 50px;
    }
    
    .footer-top {
      top: 125px;
    }
    
    .infobox {
      width: calc(81% - 3px);
    
      &__form {
        &__wrapper {
          left: -30px;
          width: calc(100% + 60px);
        }
      }
    }
    
    .contacts {
      &__item {
        padding-left: 3.7em;
      
        &__icon {
          left: 5px;
          margin-left: 1.8em;
        }
      }
    }
    
    .nav {
      &__logo {
        &__icon {
          width: 50px;
          margin-bottom: 15px;
        }
      }
    }
    
    .copyright {
      line-height: 16px;
    }
    
    .to-top {
      --toTopDimensions: 40px;
      top: calc(53% - 7px);
      right: 7px;
    
      &__detect {
        bottom: 250px;
      }
    }
    
    @keyframes toTopFadeIn {
      0% {
        opacity: 0;
        right: 7px;
        bottom: 400px;
      }
      
      100% {
        opacity: 1;
        right: 7px;
        bottom: 540px;
      }
    }
  
    @keyframes toTopFadeOut {
      0% {
        opacity: 1;
        right: 7px;
        bottom: 540px;
      }
      
      100% {
        opacity: 0;
        right: 7px;
        bottom: 400px;
      }
    }
  }
`;