import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
/*
  TABLE OF CONTENT
  # Basic
  # Decorative
  # Media Queries
  */
  
  /*-------------------------
  # Basic
  --------------------------*/
  :root {
    --white: #FFF;
    --black: #000;
    --theme-white: #F4F4F4;
    --theme-light-gray: #EAEAEA;
    --theme-gray: #D1D3D4;
    --theme-dark-gray: #A0A2A3;
    --theme-light-blue: #82D3EB;
    --theme-blue: #00B7F1;
    --theme--dark-blue: #0487BF;
    --theme-light-purple: #A376D6;
    --theme-purple: #724B9E;
    --theme-dark-purple: #431E75;
    --theme-deep-purple: #0E1237;
    --theme-error: #de0105;
    --white--rgb: 255, 255, 255;              // #FFF
    --black--rgb: 0, 0, 0;                    // #000
    --theme-white--rgb: 244, 244, 244;        // #F4F4F4
    --theme-light-gray--rgb: 234, 234, 234;   // #EAEAEA
    --theme-gray--rgb: 209, 211, 212;         // #D1D3D4
    --theme-dark-gray--rgb: 160, 162, 163;    // #A0A2A3
    --theme-light-blue--rgb: 130, 211, 235;   // #82D3EB
    --theme-blue--rgb: 0, 183, 241;           // #00B7F1
    --theme--dark-blue--rgb: 4, 134, 191;     // #0487BF
    --theme-light-purple--rgb: 163, 118, 214; // #A376D6
    --theme-purple--rgb: 114, 75, 158;        // #724B9E
    --theme-dark-purple--rgb: 67, 30, 117;    // #431E75
    --theme-deep-purple--rbg: 14, 18, 55;     // #0E1237
  }
  
  * {
    box-sizing: border-box;
  }
  
  html {
    &.no-scroll {
      overflow: hidden;
    }
  }
  
  body {
    zoom: 90%;
  }
  
  body,
  button,
  input,
  optgroup,
  select,
  textarea{
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 29px;
    color: var(--theme-deep-purple);
  }
  
  header,
  main,
  footer {
    position: relative;
    left: 0;
    transition: .3s all ease-in;
  
    &.is-shifted {
      left: -100%;
      transition: .3s all ease-out;
    }
  }
  
  .main {
    margin-bottom: 50px;
    padding-top: 180px;
    overflow: hidden;
    
    &.is-mobile-device {
      padding-top: 125px;  
    }
  }
  
  .container {
    width: 1440px;
    margin: 0 auto;
    
    &--relative {
      position: relative;
      z-index: 1;
    }
  }
  
  /*-------------------------
  # Decorative
  --------------------------*/
  .newsletter-decorative-circles {
    --dimensions: 124px;
  
    position: absolute;
    right: -100px;
    bottom: 0;
    width: var(--dimensions);
    height: var(--dimensions);;
    z-index: -1;
  }
  
  .decorative-circle {
    position: absolute;
  
    &__outer {
      --outer-dimensions: 124px;
      
      width: var(--outer-dimensions);
      height: var(--outer-dimensions);
      animation: 30s linear infinite circleRotatingRight;
    }
    
    &__middle {
      --middle-dimensions: 77px;
      
      left: calc(50% - (var(--middle-dimensions) / 2));
      top: calc(50% - (var(--middle-dimensions) / 2));
      width: var(--middle-dimensions);
      height: var(--middle-dimensions);
      animation: 20s linear infinite circleRotatingLeft;
    }
    
    &__inner {
      --inner-dimensions: 27px;
      
      left: calc(50% - (var(--inner-dimensions) / 2));
      top: calc(50% - (var(--inner-dimensions) / 2));
      width: var(--inner-dimensions);
      height: var(--inner-dimensions);
      animation: 20s linear infinite circl
    }
  }
  
  /*-------------------------
  # circleRotatingRight
  --------------------------*/
  @keyframes circleRotatingRight {
    0% {
      transform: rotate(0);
    }
    
    100% {
      transform: rotate(1turn);
    }
  }
  
  /*-------------------------
  # circleRotatingLeft
  --------------------------*/
  @keyframes circleRotatingLeft {
    0% {
      transform: rotate(0);
    }
    
    100% {
      transform: rotate(-1turn);
    }
  }
  
  /*-------------------------
  # Media Queries
  --------------------------*/
  @media (max-width: 1440px) {
    .main {
      padding-top: 155px;
    }
  
    .container {
      width: 1280px;
    }
    
    .newsletter-decorative-circles {
      --dimensions: 101px;
    
      right: -60px;
      bottom: 40px;
    }
    
    .decorative-circle {
      &__outer {
        --outer-dimensions: 101px;
      }
      
      &__middle {
        --middle-dimensions: 62px;
      }
      
      &__inner {
        --inner-dimensions: 21px;
      }
    }
  }
  
  @media (max-width: 1280px) {
    .container {
      width: 1120px;
    }
    
    .newsletter-decorative-circles {
      --dimensions: 90px;
    
      right: -40px;
      bottom: -40px;
    }
    
    .decorative-circle {
      &__outer {
        --outer-dimensions: 90px;
      }
      
      &__middle {
        --middle-dimensions: 55px;
      }
      
      &__inner {
        --inner-dimensions: 19px;
      }
    }
  }
  
  @media (max-width: 1199px) {
    .container {
      width: 100%;
      padding: 0 35px;
    }
  }
  
  @media (max-width: 1024px) {
    .newsletter-decorative-circles {
      --dimensions: 90px;
    
      right: 10px;
      bottom: -60px;
    }
  }
  
  @media (max-width: 991px) {
    .newsletter-decorative-circles {
      --dimensions: 55px;
    
      right: 20px;
      bottom: -30px;
    }
    
    .decorative-circle {
      &__outer {
        --outer-dimensions: 55px;
      }
      
      &__middle {
        --middle-dimensions: 33px;
      }
      
      &__inner {
        --inner-dimensions: 12px;
      }
    }
  }
  
  @media (max-width: 375px) {
    .container {
      padding: 0 20px;
    }
  }
`;

export default GlobalStyles;