import React, { memo, useContext, useState, useEffect } from 'react';
import { useStaticQuery, graphql }                      from 'gatsby';

// import context
import { GlobalStateContext } from '../../context/GlobalContextProvider';

// import components
import UserIconCircled from '../elements/icons/UserIconCircled';

const LogoutMsg = memo( () => {
  const data = useStaticQuery( graphql`
    {
      allApiPoints {
        nodes {
          account {
            msg_title
            msg_text
          }
        }
      }
    }
  `);

  // define the component context
  const state = useContext( GlobalStateContext );
  const { defaultLang } = state;

  // define the default component state
  const [texts, setTexts] = useState({
    logoutTitle: '',
    logoutText: ''
  });

  const { logoutTitle, logoutText } = texts;

  /**
   * Get texts from the backend and processing changing language
   */
  useEffect( () => {
    // get loaded data from graphql
    data.allApiPoints.nodes.forEach( node => {
      const { account } = node;
      let orderNumber;

      switch ( defaultLang ) {
        case 'en':
          orderNumber = 1;
          break;

        default:
          orderNumber = 0;
      }

      const current = account[orderNumber];
      const { msg_title, msg_text } = current;

      setTexts( t => ({
        ...t,
        logoutTitle: msg_title,
        logoutText: msg_text
      }));
    });
  }, [data, defaultLang] );

  return (
    <>
      <UserIconCircled className="overlay__icon" />
      <h4 className="overlay__title">{ logoutTitle }</h4>
      <span className="overlay__helper-text overlay__helper-text--center">{ logoutText }</span>
    </>
  );
});

export default LogoutMsg;