import React, { memo } from 'react';
import PropTypes       from 'prop-types';

const MMLogoIcon = memo( ( { className } ) => {
  return (
    <svg className={ className }
         xmlns="http://www.w3.org/2000/svg"
         width="39.794"
         height="22.521"
         viewBox="0 0 39.794 22.521">
      <g transform="translate(-985.35 -7)">
        <g transform="translate(985.35 7)">
          <path d="M186.952,396.03a3.436,3.436,0,0,1,3.227-4.615h1.156l6.54,17.906a3.436,3.436,0,0,1-3.227,4.615h-1.155Zm15.972,17.906h1.156a3.436,3.436,0,0,0,3.227-4.615l-6.54-17.906H199.61a3.436,3.436,0,0,0-3.227,4.615Z"
                transform="translate(-174.613 -391.415)"
                fill="#c7c9cb"/>
          <path d="M158.409,413.936h-3.473l9.723-20.554a3.436,3.436,0,0,1,3.106-1.967h3.893l-9.544,20.174A4.1,4.1,0,0,1,158.409,413.936Zm13.145-2.346,9.544-20.174H177.2a3.437,3.437,0,0,0-3.106,1.967l-9.723,20.554h3.473A4.1,4.1,0,0,0,171.554,411.589Zm9.26.379,9.723-20.554h-3.473a4.1,4.1,0,0,0-3.705,2.346l-9.544,20.174h3.893A3.436,3.436,0,0,0,180.814,411.969Zm6.737-7.115-4.3,9.082h3.892a3.436,3.436,0,0,0,3.106-1.967l4.476-9.461h-3.473A4.1,4.1,0,0,0,187.551,404.853Z"
                transform="translate(-154.936 -391.415)"
                fill="#fff"/>
        </g>
      </g>
    </svg>
  )
});

MMLogoIcon.defaultProps = {
  className: ''
};

MMLogoIcon.propTypes = {
  className: PropTypes.string
};

export default MMLogoIcon;