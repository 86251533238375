import React, { memo } from 'react';
import PropTypes       from 'prop-types';

const UserIcon = memo( ( { className, width, height, color } ) => {
  return (
    <svg className={ className }
         xmlns="http://www.w3.org/2000/svg"
         width={ width }
         height={ height }
         viewBox={ `0 0 ${ width } ${ height }` }>
      <path d="M853,561.7a3.376,3.376,0,1,0-3.376-3.376A3.377,3.377,0,0,0,853,561.7Z"
            transform="translate(-846.91 -554.945)"
            fill={ color } />
      <path d="M857.173,568.038a3.083,3.083,0,0,0-2.613-1.455h-.023v0a3.271,3.271,0,0,0-1.2.227,4.674,4.674,0,0,1-1.719.337,4.252,4.252,0,0,1-1.6-.317,3.294,3.294,0,0,0-1.193-.229,3.052,3.052,0,0,0-2.683,1.544,4.174,4.174,0,0,0-.539,2.083v4.509h12.173v-4.509A4.187,4.187,0,0,0,857.173,568.038Z"
            transform="translate(-845.6 -558.738)"
            fill={ color } />
    </svg>
  )
});

UserIcon.defaultProps = {
  className: '',
  width: '12',
  height: '16',
  color: '#eaeaea'
};

UserIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string
};

export default UserIcon;