import React, { useEffect, useContext, useState } from 'react';
import { Helmet }                                 from 'react-helmet';
import { isMobile }                               from 'react-device-detect';
import PropTypes                                  from 'prop-types';

// import constants
import { BACKEND_URL, CHANGE_LANGUAGE, CHANGE_LOGIN_STATUS } from '../../constants';

// import context
import { GlobalDispatchContext } from '../../context/GlobalContextProvider';

// import helper
import {setCookie, useGetCookie} from '../../helpers';

// import utils
import { isLoggedIn } from '../../utils/Auth';

// import styles
import '../../../node_modules/normalize.css/normalize.css';
import GlobalStyles from '../../styles/GlobalStyles';

// import components
import Loader           from '../common/Loader';
import Header           from '../common/Header';
import MobileCategories from '../elements/Categories/MobileCategories';
import Categories       from '../elements/Categories/index';
import Footer           from '../common/Footer';
import Transition       from '../transition';

export default function Layout( { children, location } ) {
  const [isMobileDevice, setIsMobileDevice] = useState( true );

  // define the component hooks
  const dispatch      = useContext( GlobalDispatchContext );
  const getCookieLang = useGetCookie( 'MMBlogLang' );
  const session       = useGetCookie( 'MMBlogSession' );

  // initial loading
  useEffect( () => {
    const { location: { pathname } } = window;
    const pathObj = pathname.split( '/' );

    if ( pathObj[1] === 'en' ) {
      dispatch( { type: CHANGE_LANGUAGE, payload: 'en' } );
      setCookie( 'MMBlogLang', 'en', { 'max-age': 24 * 60 * 60 * 365 } );
    } else {
      dispatch( { type: CHANGE_LANGUAGE, payload: 'de' } );
      setCookie( 'MMBlogLang', 'de', { 'max-age': 24 * 60 * 60 * 365 } );
    }

    setIsMobileDevice( isMobile );
    dispatch( { type: CHANGE_LANGUAGE, payload: getCookieLang !== undefined ? getCookieLang : 'de' } );
    dispatch( { type: CHANGE_LOGIN_STATUS, payload: isLoggedIn( session ) } );

    setTimeout( () => {
      const loader = document.getElementById( 'loader' );

      loader.classList.add( 'is-loaded' );

      setTimeout( () => {
        loader.classList.add( 'is-hidden' );
      }, 1000 );
    }, 1000 );
  }, [dispatch, getCookieLang, session] );

  return (
    <>
      <Helmet>
        <link rel="preload" href="https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700;800&display=swap" as="style" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700;800&display=swap" />
        <link rel="preconnect" href={ BACKEND_URL } />
      </Helmet>
      <GlobalStyles />
      <Loader />
      <Header />
      { isMobileDevice ? <MobileCategories /> : <Categories /> }
      <Transition location={ location }>
        <main className={ isMobileDevice ? 'main is-mobile-device' : 'main' } role="main">
          { children }
        </main>
      </Transition>
      <Footer />
    </>
  )
};

// props
Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object
};
