// import constants
import {
  CHANGE_OVERLAY, SHOW_OVERLAY, CHANGE_LANGUAGE,
  CHANGE_FILTER, OPEN_MOBILE_MENU, CHANGE_LOGIN_STATUS,
  GET_FILTERED_POSTS, CHANGE_SORT_TITLE
} from '../constants';

export default function reducer( state, action ) {
  switch ( action.type ) {
    case SHOW_OVERLAY:
      return {
        ...state,
        overlayIsShown: action.payload
      };

    case CHANGE_OVERLAY:
      return {
        ...state,
        overlayType: action.payload
      };

    case CHANGE_LANGUAGE:
      return {
        ...state,
        defaultLang: action.payload
      };

    case CHANGE_FILTER:
      return {
        ...state,
        filterRequestData: action.payload
      };

    case GET_FILTERED_POSTS:
      return {
        ...state,
        filteredPosts: action.payload
      };

    case OPEN_MOBILE_MENU:
      return {
        ...state,
        mobileMenuIsOpened: action.payload
      };

    case CHANGE_LOGIN_STATUS:
      return {
        ...state,
        isLoggedIn: action.payload
      };

    case CHANGE_SORT_TITLE:
      return {
        ...state,
        sortDropdownTitle: action.payload
      };

    default:
      throw new Error( 'Bad Action Type' );
  }
}