import styled from 'styled-components';

export const LoaderElement = styled.section`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--white);
  overflow: hidden;
  opacity: 1;
  visibility: visible;
  z-index: 100;
  transition: .3s opacity ease-in-out;
  
  &.is-loaded {
    opacity: 0;
  }
  
  &.is-hidden {
    visibility: hidden;
    z-index: -1;
  }
  
  .wrapper {
    --width: 200px;
    --height: 200px;
    
    position: absolute;
    left: calc(50% - (var(--width) / 2));
    top: calc(50% - (var(--height) / 2));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: var(--width);
    height: var(--height);
  }
  
  .logo {
    width: 50px;
    height: 50px;
    margin-bottom: 30px;
    animation: spin 2s infinite ease-in-out;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    
    100% {
      transform: rotate(360deg)
    }
  }
`;