import React, { memo, useContext, useState, useEffect } from 'react';
import { useStaticQuery, graphql, Link }                from 'gatsby';
import { useLocation }                                  from '@reach/router';
import PropTypes                                        from 'prop-types';

// import constants
import { CHANGE_LANGUAGE, SHOW_OVERLAY } from '../../constants';

// import context
import { GlobalStateContext, GlobalDispatchContext } from '../../context/GlobalContextProvider';

// import helper
import { setCookie } from '../../helpers';

// import components
import GlobeIcon from './icons/GlobeIcon';

const LanguageMenu = memo( ( { useBorder, onChoose } ) => {
  const data = useStaticQuery( graphql`
    {
      allApiPoints {
        nodes {
          languages {
            items {
              text
            }
            title
          }
        }
      }
      
      allPosts {
        nodes {
          data {
            alternative_slug
            slug
          }
          lang
        }
      }
    }
  `);

  // define the component context
  const dispatch = useContext( GlobalDispatchContext );
  const state    = useContext( GlobalStateContext );
  const { defaultLang } = state;

  // define the default component state
  const [pathWithoutLang, setPathWithoutLang] = useState( '' );
  const [currentLangPosts, setCurrentLangPosts] = useState( [] );
  const [texts, setTexts] = useState({
    langTitle: '',
    langItems: []
  });

  const { langTitle, langItems } = texts;

  const location = useLocation();

  /**
   * Get texts from the backend and processing changing language
   */
  useEffect( () => {
    // get loaded data from graphql
    const { allApiPoints, allPosts } = data;

    allApiPoints.nodes.forEach( node => {
      const { languages } = node;
      let orderNumber;

      switch ( defaultLang ) {
        case 'en':
          orderNumber = 1;
          break;

        default:
          orderNumber = 0;
      }

      const current = languages[orderNumber];
      const { title, items } = current;

      setTexts( t => ({
        ...t,
        langTitle: title,
        langItems: items
      }));
    });

    const posts = allPosts.nodes.filter( node => {
      if ( defaultLang === node.lang ) {
        return node;
      } else return false;
    });

    setCurrentLangPosts( posts );
  }, [data, defaultLang] );

  /**
   * Get an actual link to switch a language
   */
  useEffect( () => {
    const { pathname } = location;
    const pathObj    = pathname.split( '/' );
    const removeLang = pathObj.filter( p => p !== 'en' && p !== 'de' );
    const getNewPath = removeLang.join( '/' );

    setPathWithoutLang( getNewPath );
  }, [location] );

  /**
   * Processing change the website language
   *
   * @param lang | string
   */
  const handleChangLanguage = ( lang ) => {
    setCookie( 'MMBlogLang', lang, { 'max-age': 24 * 60 * 60 * 365 } );
    dispatch( { type: CHANGE_LANGUAGE, payload: lang } );
    dispatch( { type: SHOW_OVERLAY, payload: false } );

    if ( onChoose !== undefined ) {
      onChoose();
    }
  };

  return (
    <>
      { useBorder ?
        <span className="overlay__icon__wrapper">
          <GlobeIcon className="overlay__icon" />
        </span>
      :
        <GlobeIcon className="overlay__icon" />
      }
      <h4 className="overlay__title">{ langTitle }</h4>

      <ul className="overlay__list">
        { langItems.map( ( lang, index ) => {
          const { text } = lang;
          const currentIndex = index === 1 ? 'de' : 'en';
          let langLink = currentIndex === 'en' ? `/en${ pathWithoutLang }` : pathWithoutLang;

          if ( currentIndex === 'de' && langLink.match( 'categories' ) ) {
            langLink = langLink.replace( 'categories', 'kategorien' );
          } else if ( currentIndex === 'en' && langLink.match( 'kategorien' ) ) {
            langLink = langLink.replace( 'kategorien', 'categories' );
          } else {
            const getPath = pathWithoutLang.replace( '/', '' );

            currentLangPosts.forEach( post => {
              const { data: { slug, alternative_slug } } = post;

              if ( slug === getPath ) {
                if ( currentIndex === 'en' && defaultLang === 'de' ) {
                  langLink = `/en/${ alternative_slug }`;
                }

                if ( currentIndex === 'de' && defaultLang === 'en' ) {
                  langLink = `/${ alternative_slug }`;
                }
              }
            });
          }

          return (
            <li className="overlay__list__item"
                key={ text }>
              <Link className={ defaultLang === currentIndex ? 'overlay__list__item__link overlay__list__item__link--current' : 'overlay__list__item__link' }
                    to={ langLink }
                    onClick={ () => handleChangLanguage( currentIndex ) }>
                { text }
              </Link>
            </li>
          )
        } ) }
      </ul>
    </>
  )
});

LanguageMenu.defaultProps = {
  useBorder: false,
  onChoose: () => {}
};

LanguageMenu.propTypes = {
  useBorder: PropTypes.bool,
  onChoose: PropTypes.func
};

export default LanguageMenu;