import React, { memo, useContext, useState, useEffect } from 'react';
import { Link, useStaticQuery, graphql }                from 'gatsby';
import { useLocation }                                  from '@reach/router';
import { Swiper, SwiperSlide }                          from 'swiper/react';
import SwiperCore, { Navigation }                       from 'swiper/core';

// import context
import { GlobalStateContext } from '../../../context/GlobalContextProvider';

// import helpers
import { getCategoryName } from '../../../helpers';

// import styles
import 'swiper/swiper.min.css';
import { MobileCategoriesElement } from './styles/MobileCategories';

// install Swiper modules
SwiperCore.use( [Navigation] );

const MobileCategories = memo( () => {
  const data = useStaticQuery( graphql`
    {
      allApiPoints {
        nodes {
          categories_text {
            all_text
          }
          categories {
            categories_list {
              id
              link
              text
            }
          }
        }
      }
    }
  `);

  // define the component context
  const state = useContext( GlobalStateContext );
  const { defaultLang } = state;

  // define the component state
  const [categories, setCategories] = useState( [] );
  const [allCategoriesText, setAllCategoriesText] = useState( '' );

  // define the component custom hook
  const { pathname } = useLocation();

  /**
   * Get texts from the backend and processing changing language
   */
  useEffect( () => {
    // get loaded data from graphql
    data.allApiPoints.nodes.forEach( node => {
      const { categories, categories_text } = node;
      let orderNumber;

      switch ( defaultLang ) {
        case 'en':
          orderNumber = 1;
          break;

        default:
          orderNumber = 0;
      }

      setCategories( categories[orderNumber].categories_list );
      setAllCategoriesText( categories_text[orderNumber].all_text );
    });
  }, [data, defaultLang] );

  return (
    <MobileCategoriesElement>
      <Swiper slidesPerView={ 'auto' }
              className="list"
              wrapperTag="ul"
              spaceBetween={ 0 }
              navigation>
        { pathname.match( /categories/ ) || pathname.match( /kategorien/ ) ?
          <SwiperSlide className="list__item"
                       tag="li">
            <Link className="list__item__link" to={ defaultLang === 'de' ? '/' : `/${ defaultLang }/` }>
              <span dangerouslySetInnerHTML={ { __html: allCategoriesText } } />
            </Link>
          </SwiperSlide>
        : false }
        { categories.map( category => {
          const { text, link } = category;
          const categoryName = getCategoryName( text );
          const categoryLink = defaultLang === 'de' ? `/kategorien/${ link }` : `/${ defaultLang }/categories/${ link }`;

          return (
            <SwiperSlide className="list__item"
                         key={ link }
                         tag="li">
              <Link className="list__item__link" to={ categoryLink }>
                <span dangerouslySetInnerHTML={ { __html: categoryName } } />
              </Link>
            </SwiperSlide>
          )
        } ) }
      </Swiper>
    </MobileCategoriesElement>
  );
});

export default MobileCategories;