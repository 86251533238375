import React, { memo, useContext, useState, useEffect } from 'react';
import { useStaticQuery, graphql }                      from 'gatsby';

// import context
import { GlobalStateContext } from '../../../../context/GlobalContextProvider';

// import components
import PhoneIcon from '../../icons/PhoneIcon';

const ContactMsg = memo( () => {
  const data = useStaticQuery( graphql`
    {
      allApiPoints {
        nodes {
          footer {
            inquiry_texts {
              title
              successful_inquiry_message
            }
          }
        }
      }
    }
  `);

  // define the component context
  const state = useContext( GlobalStateContext );
  const { defaultLang } = state;

  // define the default component state
  const [texts, setTexts] = useState({
    title: '',
    text: ''
  });

  const { title, text } = texts;

  /**
   * Get texts from the backend and processing changing language
   */
  useEffect( () => {
    // get loaded data from graphql
    data.allApiPoints.nodes.forEach( node => {
      const { footer } = node;
      let orderNumber;

      switch ( defaultLang ) {
        case 'de':
          orderNumber = 1;
          break;

        default:
          orderNumber = 0;
      }

      const current = footer[orderNumber];
      const { inquiry_texts: { title, successful_inquiry_message } } = current;
      setTexts({
        title,
        text: successful_inquiry_message
      })
    });
  }, [data, defaultLang] );

  return (
    <>
      <span className="overlay__icon overlay__icon--decorated">
        <PhoneIcon className="overlay__icon__item" color="#0E1237"/>
      </span>
      <h4 className="overlay__title">{ title }</h4>
      <span className="overlay__helper-text overlay__helper-text--center" dangerouslySetInnerHTML={ { __html: text } } />
    </>
  );
});

export default ContactMsg;