import React, { memo, useContext, useState, useEffect } from 'react';
import { useStaticQuery, graphql }                      from 'gatsby';

// import constants
import { CHANGE_OVERLAY } from '../../../constants/index';

// import context
import { GlobalDispatchContext, GlobalStateContext } from '../../../context/GlobalContextProvider';

// import styles
import { AccountElement } from './styles/index';

// import components
import AccountUser from './AccountUser';

const AccountChangePasswordMsg = memo( () => {
  const data = useStaticQuery( graphql`
    {
      allApiPoints {
        nodes {
          account {
            button_text
            edit_password_text
            change_password_msg
          }
        }
      }
    }
  `);

  // define the component context
  const state    = useContext( GlobalStateContext );
  const dispatch = useContext( GlobalDispatchContext );
  const { defaultLang, overlayType } = state;

  // define the default component state
  const [texts, setTexts] = useState({
    accountTitle: '',
    accountMessage: ''
  });

  const { accountTitle, accountMessage } = texts;

  /**
   * Get texts from the backend and processing changing language
   */
  useEffect( () => {
    // get loaded data from graphql
    data.allApiPoints.nodes.forEach( node => {
      const { account } = node;
      let orderNumber;

      switch ( defaultLang ) {
        case 'en':
          orderNumber = 1;
          break;

        default:
          orderNumber = 0;
      }

      const current = account[orderNumber];
      const { edit_password_text, change_password_msg } = current;

      setTexts( t => ({
        ...t,
        accountTitle: edit_password_text,
        accountMessage: change_password_msg
      }));
    });
  }, [data, defaultLang] );

  /**
   * Redirect to the main profile view after 5 seconds
   */
  useEffect( () => {
    setTimeout( () => {
      if ( overlayType === 'accountChangePasswordMsg' ) {
        dispatch( { type: CHANGE_OVERLAY, payload: 'account' } );
      }
    }, 5000 );
  }, [dispatch, overlayType] );

  return (
    <AccountElement>
      <div className="account__container">
        <div className="account__top">
          <AccountUser />
        </div>

        <div className="account__fields">
          <div className="account__details">
            <h4 className="account__details__title">{ accountTitle }</h4>
          </div>

          <div className="account__edit">
            <span className="account__edit__msg">{ accountMessage }</span>
          </div>
        </div>
      </div>
    </AccountElement>
  );
});

export default AccountChangePasswordMsg;