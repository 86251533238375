import React, { memo, useContext, useEffect, useState } from 'react';
import { useStaticQuery, graphql }                      from 'gatsby';

// import constants
import { AUTH_URL, CHANGE_OVERLAY, SHOW_OVERLAY, CHANGE_LOGIN_STATUS } from '../../constants';

// import context
import { GlobalDispatchContext, GlobalStateContext } from '../../context/GlobalContextProvider';

// import helpers
import { useEmailValidation, usePasswordValidation, useWindowSize, postData, setCookie } from '../../helpers';

// import utils
import { setAccessToken, setRefreshToken, setUser } from '../../utils/Auth';

// import components
import UserIconCircled   from '../elements/icons/UserIconCircled';
import EyeVisibleOnIcon  from './icons/EyeVisibleOnIcon';
import EyeVisibleOffIcon from './icons/EyeVisibleOffIcon';
import Button            from '../elements/Button';

const Login = memo( () => {
  const data = useStaticQuery( graphql`
    {
      allApiPoints {
        nodes {
          login {
            button_text
            error
            fields {
              text
              type
            }
            not_a_member_link
            not_a_member_text
            remember_text
            forget_password_text
            forget_password_link
            title
          }
        }
      }
    }
  `);

  // define the component context
  const state    = useContext( GlobalStateContext );
  const dispatch = useContext( GlobalDispatchContext );
  const { defaultLang } = state;

  // define the default component state
  const [passwordIsVisible, setPasswordIsVisible] = useState( false );
  const [passwordIsInvalid, setPasswordIsInvalid] = useState( false );
  const [emailIsInvalid, setEmailIsInvalid] = useState( false );
  const [isBtnProcess, setIsBtnProcess] = useState( false );
  const [isError, setIsError]   = useState( false );
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    remember: false,
  });
  const [texts, setTexts] = useState({
    loginTitle: '',
    loginError: '',
    loginNotMemberText: '',
    loginNotMemberLink: '',
    loginRememberText: '',
    loginForgotText: '',
    loginForgotLink: '',
    loginButtonText: '',
    loginFields: []
  });

  const { email, password, remember } = formData;
  const {
    loginTitle, loginError, loginNotMemberText, loginNotMemberLink, loginForgotText,
    loginForgotLink, loginRememberText, loginButtonText, loginFields
  } = texts;

  // define the component custom hooks
  const passwordValidation = usePasswordValidation( password );
  const emailValidation = useEmailValidation( email );
  const windowSize = useWindowSize();
  const { width }  = windowSize;

  /**
   * Get texts from the backend and processing changing language
   */
  useEffect( () => {
    // get loaded data from graphql
    data.allApiPoints.nodes.forEach( node => {
      const { login } = node;
      let orderNumber;

      switch ( defaultLang ) {
        case 'en':
          orderNumber = 1;
          break;

        default:
          orderNumber = 0;
      }

      const current = login[orderNumber];
      const {
        title, error, not_a_member_text, not_a_member_link, remember_text,
        forget_password_text, forget_password_link, button_text, fields
      } = current;

      setTexts( t => ({
        ...t,
        loginTitle: title,
        loginError: error,
        loginNotMemberText: not_a_member_text,
        loginNotMemberLink: not_a_member_link,
        loginRememberText: remember_text,
        loginForgotText: forget_password_text,
        loginForgotLink: forget_password_link,
        loginButtonText: button_text,
        loginFields: fields
      }));
    });
  }, [data, defaultLang] );

  /**
   * Processing fill a field
   *
   * @param e | Event
   * @param index | int
   */
  const handleChangeField = ( e, index ) => {
    switch ( index ) {
      case 1:
        setFormData( { ...formData, password: e.currentTarget.value.trim() } );
        break;

      default:
        setFormData( { ...formData, email: e.currentTarget.value.trim().toLowerCase() } );
    }
  };

  /**
   * Processing a login action.
   * Validating the data, passing the data to the backend or showing an error
   *
   * @param e | Event
   */
  const handleSubmit = ( e ) => {
    e.preventDefault();

    const { email, password, remember } = formData;

    if (
      email !== '' && emailValidation &&
      password !== '' && passwordValidation
    ) {
      const data = {
        email,
        password
      };

      setIsBtnProcess( true );

      postData( AUTH_URL + 'login/', 'POST', data, false )
        .then( data => {
          const response = data[0];
          const { status } = response;

          // if the user successfully logged in
          if ( status === 200 ) {
            const resJson = data[1];
            const { tokens, username, email: userEmail, company_name, first_name, last_name } = resJson;

            const preparedTokens = tokens.replace( /'/g, '"' );
            const parsedTokens   = JSON.parse( preparedTokens );

            setIsBtnProcess( false );
            dispatch( { type: SHOW_OVERLAY, payload: false } );
            setFormData({
              ...formData,
              email: '',
              password: ''
            });

            if ( remember ) {
              setCookie( 'MMBlogSession', true, { 'max-age': 24 * 60 * 60 * 7 } );
              setAccessToken( parsedTokens.access, true );
              setRefreshToken( parsedTokens.refresh, true );
              setUser(
                {
                  email: userEmail,
                  username,
                  firstName: first_name,
                  lastName: last_name,
                  companyName: company_name
                },
                true
              );
            } else {
              setAccessToken( parsedTokens.access, false );
              setRefreshToken( parsedTokens.refresh, false );
              setUser(
                {
                  email: userEmail,
                  username,
                  firstName: first_name,
                  lastName: last_name,
                  companyName: company_name
                },
                false
              );
            }

            dispatch( { type: CHANGE_LOGIN_STATUS, payload: true } );

            if ( width < 992 ) {
              dispatch( { type: CHANGE_OVERLAY, payload: 'account' } )
            }
          }

          // if the email or password is invalid
          if ( status === 401 ) {
            setIsBtnProcess( false );
            setIsError( true );
            setEmailIsInvalid( true );
            setPasswordIsInvalid( true );
          }
        });
    } else {
      setIsError( true );

      if ( email === '' || !emailValidation ) setEmailIsInvalid( true );
      if ( password === '' || !passwordValidation ) setPasswordIsInvalid( true );
    }
  };

  /**
   * Hide an error
   */
  const handleHideError = () => {
    if ( isError ) setIsError( false );
    if ( emailIsInvalid ) setEmailIsInvalid( false );
    if ( passwordIsInvalid ) setPasswordIsInvalid( false );
  };

  return (
    <>
      <UserIconCircled className="overlay__icon" />
      <h4 className="overlay__title">{ loginTitle }</h4>
      { isError ? <span className="overlay__error">{ loginError }</span> : false }
      <span className="overlay__helper-text">
        { loginNotMemberText } <span className="overlay__helper-text__link"
                                     role="presentation"
                                     onClick={ () => { dispatch( { type: CHANGE_OVERLAY, payload: 'register' } ) } }>{ loginNotMemberLink }</span>
      </span>

      <form className="overlay__form"
            onSubmit={ ( e ) => handleSubmit( e ) }>
        { loginFields.length > 0 ?
          loginFields.map( ( field, index ) => {
            const { text, type } = field;
            let value;

            switch ( index ) {
              case 1:
                value = password;
                break;

              default:
                value = email;
            }

            return (
              <div className="overlay__field-group" key={ type }>
                { type === 'password' ?
                  <>
                    <input className={ passwordIsInvalid ? 'overlay__field overlay__field--password overlay__field--is-invalid' : 'overlay__field overlay__field--password' }
                           type={ passwordIsVisible ? 'text' : 'password' }
                           value={ value }
                           placeholder={ text }
                           onChange={ ( e ) => handleChangeField( e, index ) }
                           onFocus={ handleHideError } />
                    <span className="overlay__field__icon"
                          role="presentation"
                          onClick={ () => setPasswordIsVisible( v => !v ) }>
                      { value !== '' ?
                        passwordIsVisible ?
                          <EyeVisibleOffIcon className="overlay__field__icon__item" />
                        :
                          <EyeVisibleOnIcon className="overlay__field__icon__item" />
                      : false }
                    </span>
                  </>
                :
                  <input className={ emailIsInvalid ? 'overlay__field overlay__field--is-invalid' : 'overlay__field' }
                         type={ type }
                         value={ value }
                         placeholder={ text }
                         onChange={ ( e ) => handleChangeField( e, index ) }
                         onFocus={ handleHideError } />
                }
              </div>
            )
          })
        : false }

        <div className="overlay__remember">
          { width > 991 ?
            <>
              <input className="overlay__remember__mark"
                     id="remember"
                     type="checkbox"
                     checked={ remember }
                     hidden
                     onChange={ ( e ) => { setFormData( { ...formData, remember: e.currentTarget.checked } ) } } />
              <label className="overlay__remember__label"
                     htmlFor="remember">{ loginRememberText }</label>
            </>
          :
            <>
              <input className="overlay__remember__mark"
                     id="remember"
                     type="checkbox"
                     checked={ remember }
                     hidden
                     onChange={ () => setFormData( ( state ) => ( { ...formData, remember: !state.remember } ) ) } />
              <label className={ remember ? 'overlay__remember__label is-checked' : 'overlay__remember__label' }
                     htmlFor="remember"
                     role="presentation"
                     onClick={ () => setFormData( ( state ) => ( { ...formData, remember: !state.remember } ) ) }>{ loginRememberText }</label>
            </>
          }
        </div>

        <span className="overlay__forgot">
          { loginForgotText } <span className="overlay__forgot__link"
                                    role="presentation"
                                    onClick={ () => { dispatch( { type: CHANGE_OVERLAY, payload: 'forgot' } ) } }>{ loginForgotLink }</span>
        </span>

        <Button className={ `overlay__login__btn${ isBtnProcess ? ' processing' : '' }` }
                text={ loginButtonText }
                disabled={ isBtnProcess }
                method="submit" />
      </form>
    </>
  )
});

export default Login;