import React, { memo, useContext } from 'react';

// import constants
import { CHANGE_OVERLAY, OPEN_MOBILE_MENU } from '../../constants';

// import context
import { GlobalDispatchContext, GlobalStateContext } from '../../context/GlobalContextProvider';

// import styles
import { HeaderElement } from '../../styles/common/Header';

// import helpers
import { useWindowSize } from '../../helpers';

// import components
import HeaderLogo      from '../elements/HeaderLogo';
import HeaderNav       from '../elements/HeaderNav';
import HeaderHamburger from '../elements/HeaderHamburger';
import Overlay         from '../elements/Overlay';
import MobileMenu      from '../elements/MobileMenu/index';

const Header = memo( () => {
  // define the component context
  const state    = useContext( GlobalStateContext );
  const dispatch = useContext( GlobalDispatchContext );
  const { defaultLang, mobileMenuIsOpened } = state;

  // define the component custom hooks
  const windowSize = useWindowSize();
  const { width }  = windowSize;

  /**
   * Open and close the Mobile menu
   *
   * @param action | string
   */
  const handleToggleMenu = ( action ) => {
    const header = document.getElementsByTagName( 'header' )[0];
    const main   = document.getElementsByTagName( 'main' )[0];
    const footer = document.getElementsByTagName( 'footer' )[0];

    if ( action === 'open' ) {
      header.classList.add( 'is-shifted' );
      main.classList.add( 'is-shifted' );
      footer.classList.add( 'is-shifted' );

      dispatch( { type: OPEN_MOBILE_MENU, payload: true } );
      dispatch( { type: CHANGE_OVERLAY, payload: '' } );
    } else {
      header.classList.remove( 'is-shifted' );
      main.classList.remove( 'is-shifted' );
      footer.classList.remove( 'is-shifted' );

      dispatch( { type: OPEN_MOBILE_MENU, payload: false } );
      dispatch( { type: CHANGE_OVERLAY, payload: '' } );
    }
  };

  /**
   * Return
   */
  return (
    <HeaderElement>
      <div className="container">
        <div className="wrapper">
          <HeaderLogo currentLang={ defaultLang } />

          { width > 991 ?
            <HeaderNav currentLang={ defaultLang } />
          :
            <HeaderHamburger setIsOpened={ () => handleToggleMenu( 'open' ) } />
          }
        </div>
      </div>

      <Overlay />

      { width <= 991 ?
        <MobileMenu isOpen={ mobileMenuIsOpened }
                    setIsClosed={ () => handleToggleMenu( 'close' ) } />
      : false }
    </HeaderElement>
  )
});

export default Header;
