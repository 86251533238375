import React, { memo } from 'react';
import PropTypes       from 'prop-types';

const CrossIcon = memo( ( { className, color } ) => {
  return (
    <svg className={ className }
         xmlns="http://www.w3.org/2000/svg"
         width="12.854"
         height="12.734"
         viewBox="0 0 12.854 12.734">
      <path d="M22602.295,16972.463l-4.873-4.863-4.859,4.863a.9.9,0,0,1-.646.27.879.879,0,0,1-.641-.27.9.9,0,0,1,0-1.281l4.813-4.818-4.82-4.812a.9.9,0,0,1,0-1.285.915.915,0,0,1,1.291,0l4.873,4.867,4.857-4.867a.915.915,0,0,1,1.293,0,.908.908,0,0,1,0,1.285l-4.818,4.816,4.822,4.814a.909.909,0,1,1-1.291,1.281Z"
            transform="translate(-22591 -16959.998)"
            fill={ color } />
    </svg>
  )
});

CrossIcon.defaultProps = {
  className: '',
  color: '#82D3EB'
};

CrossIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
};

export default CrossIcon;