// import helper
import { deleteCookie } from '../helpers';

export const isBrowser = () => typeof window !== 'undefined';

export const getUser = ( session ) => {
  if ( session ) {
    return (
      isBrowser() && window.localStorage.getItem( 'user' )
        ? JSON.parse( window.localStorage.getItem( 'user' ) )
        : {}
    )
  } else {
    return (
      isBrowser() && window.sessionStorage.getItem( 'user' )
        ? JSON.parse( window.sessionStorage.getItem( 'user' ) )
        : {}
    )
  }
};

export const setUser = ( user, session ) => {
  if ( session ) {
    window.localStorage.setItem( 'user', JSON.stringify( user ) );
  } else {
    window.sessionStorage.setItem( 'user', JSON.stringify( user ) );
  }
};

export const setAccessToken = ( token, session ) => {
  if ( session ) {
    window.localStorage.setItem( 'accessToken', JSON.stringify( token ) );
  } else {
    window.sessionStorage.setItem( 'accessToken', JSON.stringify( token ) );
  }
};

export const getAccessToken = ( session ) => {
  if ( session ) {
    return (
      isBrowser() && window.localStorage.getItem( 'accessToken' )
        ? JSON.parse( window.localStorage.getItem( 'accessToken' ) )
        : ''
    );
  } else {
    return (
      isBrowser() && window.sessionStorage.getItem( 'accessToken' )
        ? JSON.parse( window.sessionStorage.getItem( 'accessToken' ) )
        : ''
    );
  }
};

export const getRefreshToken = ( session ) => {
  if ( session ) {
    return (
      isBrowser() && window.localStorage.getItem( 'refreshToken' )
        ? JSON.parse( window.localStorage.getItem( 'refreshToken' ) )
        : ''
    );
  } else {
    return (
      isBrowser() && window.sessionStorage.getItem( 'refreshToken' )
        ? JSON.parse( window.sessionStorage.getItem( 'refreshToken' ) )
        : ''
    );
  }
};

export const setRefreshToken = ( token, session ) => {
  if ( session ) {
    window.localStorage.setItem( 'refreshToken', JSON.stringify( token ) );
  } else {
    window.sessionStorage.setItem( 'refreshToken', JSON.stringify( token ) );
  }
};

export const isLoggedIn = ( session ) => {
  const user = getUser( session );

  return !!user.username;
};

export const logout = () => {
  setUser( {}, true );
  setUser( {}, false );
  setAccessToken( '', true );
  setAccessToken( '', false );
  setRefreshToken( '', true );
  setRefreshToken( '', false );
  deleteCookie( 'MMBlogSession' );
};