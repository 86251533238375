import React, { memo, useContext } from 'react';
import PropTypes                   from 'prop-types';

// import constants
import { CHANGE_OVERLAY } from '../../../constants/index';

// import context
import { GlobalDispatchContext } from '../../../context/GlobalContextProvider';

// import components
import ArrowRegularIcon from '../icons/ArrowRegularIcon';

const AccountBack = memo( ( { overlay, text } ) => {
  // define the component context
  const dispatch = useContext( GlobalDispatchContext );

  return (
    <div className="account__back"
         role="presentation"
         onClick={ () => { dispatch( { type: CHANGE_OVERLAY, payload: overlay } ); } }>
      <span className="account__back__icon">
        <ArrowRegularIcon color="#00B7F1" />
      </span>
      <span className="account__back__text">{ text }</span>
    </div>
  );
});

AccountBack.defaultProps = {
  overlay: 'account',
  text: 'Back'
};

AccountBack.propTypes = {
  overlay: PropTypes.string,
  text: PropTypes.string
};

export default AccountBack;