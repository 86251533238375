import React, { memo, useContext, useState, useEffect } from 'react';
import { Link, useStaticQuery, graphql }                from 'gatsby';
import PropTypes                                        from 'prop-types';

// import constants
import { CHANGE_OVERLAY, OPEN_MOBILE_MENU } from '../../../constants';

// import context
import { GlobalDispatchContext, GlobalStateContext } from '../../../context/GlobalContextProvider';

// import helpers
import { useGetCookie } from '../../../helpers';

// import utils
import { getUser } from '../../../utils/Auth';

// import styles
import { MobileMenuElement } from './styles';

// import components
import LanguageMenu             from '../LanguageMenu';
import RSSMenu                  from '../RSSMenu';
import Login                    from '../Login';
import LogoutMsg                from '../LogoutMsg';
import Register                 from '../Register';
import RegisterThankMsg         from '../RegisterThankMsg';
import ForgotPassword           from '../ForgotPassword';
import ForgotPasswordThankMsg   from '../ForgotPasswordThankMsg';
import Account                  from '../Account/index';
import AccountChange            from '../Account/AccountChange';
import AccountChangePassword    from '../Account/AccountChangePassword';
import AccountChangePasswordMsg from '../Account/AccountChangePasswordMsg';
import ProfileDeletedMsg        from '../ProfileDeletedMsg';
import MobileSearch             from '../Search/MobileSearch';
import BrandIconExtended        from '../icons/BrandIconExtended';
import CrossIcon                from '../icons/CrossIcon';
import SearchIcon               from '../icons/SearchIcon';
import UserIcon                 from '../icons/UserIcon';
import SocialIcon               from '../icons/SocialIcon';
import MMLogoIcon               from '../icons/MMLogoIcon';
import RSSIcon                  from '../icons/RSSIcon';
import GlobeIcon                from '../icons/GlobeIcon';
import ArrowIcon                from '../icons/ArrowIcon';

const MobileMenu = memo( ( { isOpen, setIsClosed } ) => {
  const data = useStaticQuery( graphql`
    {
      allApiPoints {
        nodes {
          powereds {
            link_text
            title_powered
          }
          rss {
            mobile_title
          }
          languages {
            mobile_title
          }
          account {
            hello_text
          }
        }
      }
    }
  `);

  // define the component context
  const state    = useContext( GlobalStateContext );
  const dispatch = useContext( GlobalDispatchContext );
  const { overlayType, defaultLang, isLoggedIn } = state;

  // define the default component state
  const [subMenuIsOpened, setSubMenuIsOpened]     = useState( false );
  const [languagesIsOpened, setLanguagesIsOpened] = useState( false );
  const [rssIsOpened, setRssIsOpened]             = useState( false );
  const [searchIsOpened, setSearchIsOpened]       = useState( false );
  const [searchIsCleared, setSearchIsCleared]     = useState( false );
  const [texts, setTexts] = useState({
    poweredText: '',
    poweredLink: '',
    rssTitle: '',
    languageTitle: '',
    accountHello: ''
  });

  const { poweredText, poweredLink, rssTitle, languageTitle, accountHello } = texts;

  // get session from cookie
  const session = useGetCookie( 'MMBlogSession' );

  // get user data
  const user = getUser( session );

  /**
   * Get texts from the backend and processing changing language
   */
  useEffect( () => {
    // get loaded data from graphql
    data.allApiPoints.nodes.forEach( node => {
      const { powereds, rss, languages, account } = node;
      let orderNumber;

      switch ( defaultLang ) {
        case 'en':
          orderNumber = 1;
          break;

        default:
          orderNumber = 0;
      }

      const currentPowered = powereds[orderNumber];
      const currentRss     = rss[orderNumber];
      const currentLang    = languages[orderNumber];
      const currentAccount = account[orderNumber];
      const { link_text, title_powered }    = currentPowered;
      const { mobile_title: rssTitle }      = currentRss;
      const { mobile_title: languageTitle } = currentLang;
      const { hello_text } = currentAccount;

      setTexts( t => ({
        ...t,
        poweredText: title_powered,
        poweredLink: link_text,
        accountHello: hello_text,
        rssTitle,
        languageTitle
      }));
    });
  }, [data, defaultLang] );

  useEffect( () => {
    if ( overlayType === 'closeSearch' ) {
      setSearchIsOpened( false );
    }
  }, [overlayType] );

  /**
   * Processing click on the close button and pass data to the parent component
   */
  const handleClose = () => {
    if ( setIsClosed !== undefined ) {
      setIsClosed();
      setSubMenuIsOpened( false );
      setLanguagesIsOpened( false );
      setRssIsOpened( false );
      setSearchIsOpened( false );
      setSearchIsCleared( true );
      dispatch( { type: CHANGE_OVERLAY, payload: '' } );

      setTimeout( () => {
        setSearchIsCleared( false );
      }, 500 );
    }
  };

  /**
   * Processing click on the language menu and open it
   */
  const handleOpenLanguages = () => {
    setSubMenuIsOpened( true );
    setLanguagesIsOpened( true );
    setSearchIsOpened( false );
  };

  /**
   * Processing click on the RSS menu and open it
   */
  const handleOpenRss = () => {
    setSubMenuIsOpened( true );
    setRssIsOpened( true );
    setSearchIsOpened( false );
  };

  /**
   * Processing click on the user icon and open the login form
   */
  const handleOpenUserArea = () => {
    if ( isLoggedIn ) {
      dispatch( { type: CHANGE_OVERLAY, payload: 'account' } );
    } else {
      dispatch( { type: CHANGE_OVERLAY, payload: 'login' } );
    }
  };

  const handleOpenSearch = () => {
    setSearchIsOpened( true );
    handleCloseSubMenu();
  };

  /**
   * Processing click on the button to close sub menu
   */
  const handleCloseSubMenu = () => {
    setSubMenuIsOpened( false );
    setLanguagesIsOpened( false );
    setRssIsOpened( false );
    dispatch( { type: CHANGE_OVERLAY, payload: '' } );
  };

  const handleClickLogo = () => {
    const header = document.getElementsByTagName( 'header' )[0];
    const main   = document.getElementsByTagName( 'main' )[0];
    const footer = document.getElementsByTagName( 'footer' )[0];

    header.classList.remove( 'is-shifted' );
    main.classList.remove( 'is-shifted' );
    footer.classList.remove( 'is-shifted' );
    dispatch( { type: OPEN_MOBILE_MENU, payload: false } );
    dispatch( { type: CHANGE_OVERLAY, payload: 'closeSearch' } );
  };

  const loginShiftedOverlay =
    overlayType === 'login' ||
    overlayType === 'register' ||
    overlayType === 'forgot' ||
    overlayType === 'forgotMsg' ||
    overlayType === 'registerMsg' ||
    overlayType === 'account' ||
    overlayType === 'accountChange' ||
    overlayType === 'accountChangePassword' ||
    overlayType === 'accountChangePasswordMsg' ||
    overlayType === 'logout' ||
    overlayType === 'deleted'
      ? ' is-shifted' : '';

  /**
   * Return
   */
  return (
    <MobileMenuElement className={ isOpen ? 'is-opened' : '' }>
      <div className="top">
        <div className="header">
          <span className="close"
                role="presentation"
                onClick={ handleClose }>
            <CrossIcon className="close__icon" color="#431E75" />
          </span>
          <Link className="logo__link" to="/" onClick={ handleClickLogo }>
            <BrandIconExtended className="logo" color="#140048" />
            <span className="logo__text">{ `${ poweredText } ${ poweredLink }` }</span>
          </Link>
        </div>

        <div className="switcher">
          <div className={
            `switcher__search${ searchIsOpened ? ' is-extended' : '' }${ loginShiftedOverlay ? ' is-shifted' : '' }`
          }
               role="presentation"
               onClick={ handleOpenSearch }>
            <SearchIcon className="switcher__search__icon"/>
            <MobileSearch isShown={ searchIsOpened } isCleared={ searchIsCleared } />
          </div>
          <div className={
            `switcher__user${
              overlayType === 'login' ||
              overlayType === 'register' ||
              overlayType === 'forgot' ||
              overlayType === 'forgotMsg' ||
              overlayType === 'registerMsg' ||
              overlayType === 'account' ||
              overlayType === 'accountChange' ||
              overlayType === 'accountChangePassword' ||
              overlayType === 'accountChangePasswordMsg' ||
              overlayType === 'logout' ||
              overlayType === 'deleted'
                ? ` is-extended${
                  overlayType === 'account' ||
                  overlayType === 'accountChange' ||
                  overlayType === 'accountChangePassword' ||
                  overlayType === 'accountChangePasswordMsg'
                    ? ' is-extended--logged'
                    : ''
                  }`
                : ''
            }${ searchIsOpened ? ' is-shifted' : '' }`
          }
               role="presentation"
               onClick={ handleOpenUserArea }>
            <UserIcon className="switcher__user__icon" color="#431e75" />
            { overlayType === 'account' ||
              overlayType === 'accountChange' ||
              overlayType === 'accountChangePassword' ||
              overlayType === 'accountChangePasswordMsg'
                ? <span className="switcher__user__hello">{ accountHello } { user.username }!</span>
                : ''
            }
          </div>
        </div>
      </div>

      <div className="bottom">
        <div className={
          subMenuIsOpened ||
          overlayType === 'login' ||
          overlayType === 'register' ||
          overlayType === 'forgot' ||
          overlayType === 'forgotMsg' ||
          overlayType === 'registerMsg' ||
          overlayType === 'account' ||
          overlayType === 'accountChange' ||
          overlayType === 'accountChangePassword' ||
          overlayType === 'accountChangePasswordMsg' ||
          overlayType === 'logout' ||
          overlayType === 'deleted'
            ? 'navigation is-shifted'
            : 'navigation'
        }>
          <a className="navigation__item"
             href="https://www.marketingmonkeys.ch/"
             target="_blank"
             rel="noreferrer">
            <span className="navigation__item__icon">
              <MMLogoIcon className="navigation__item__icon--mm" />
            </span>
            <span className="navigation__item__text">Marketing monkeys</span>
          </a>

          <div className="navigation__item"
               role="presentation"
               onClick={ handleOpenRss }>
            <span className="navigation__item__icon">
              <RSSIcon className="navigation__item__icon--rss"
                       border={ false } />
            </span>
            <span className="navigation__item__text">{ rssTitle }</span>
          </div>

          <div className="navigation__item"
               role="presentation"
               onClick={ handleOpenLanguages }>
            <span className="navigation__item__icon">
              <GlobeIcon className="navigation__item__icon--lang"
                         color="#fff" />
              <span className="navigation__item__icon--indicator">{ defaultLang }</span>
            </span>
            <span className="navigation__item__text">{ languageTitle }</span>
          </div>
        </div>

        <div className={
          overlayType === 'login' ||
          overlayType === 'register' ||
          overlayType === 'forgot' ||
          overlayType === 'forgotMsg' ||
          overlayType === 'registerMsg' ||
          overlayType === 'account' ||
          overlayType === 'accountChange' ||
          overlayType === 'accountChangePassword' ||
          overlayType === 'accountChangePasswordMsg' ||
          overlayType === 'logout' ||
          overlayType === 'deleted'
            ? 'socials is-shifted'
            : 'socials'
        }>
          <span className="socials__title">Follow us</span>
          <div className="socials__list">
            <SocialIcon className="socials__list__item"
                        type="linkedin"
                        link="https://www.linkedin.com/company/marketing-monkeys/" />
            <SocialIcon className="socials__list__item"
                        type="twitter"
                        link="https://twitter.com/MartechMonkeys" />
            <SocialIcon className="socials__list__item"
                        type="facebook"
                        link="https://www.facebook.com/MarketingMonkeysGmbH" />
            <SocialIcon className="socials__list__item"
                        type="instagram"
                        link="https://www.instagram.com/marketingmonkeys.ch/" />
          </div>
        </div>

        <span className={
          subMenuIsOpened ||
          overlayType === 'login' ||
          overlayType === 'register' ||
          overlayType === 'forgot' ||
          overlayType === 'forgotMsg' ||
          overlayType === 'registerMsg' ||
          overlayType === 'account' ||
          overlayType === 'accountChange' ||
          overlayType === 'accountChangePassword' ||
          overlayType === 'accountChangePasswordMsg' ||
          overlayType === 'logout' ||
          overlayType === 'deleted'
            ? 'close-sub-nav is-visible'
            : 'close-sub-nav'
        }
              role="presentation"
              onClick={ handleCloseSubMenu }>
          <ArrowIcon className="close-sub-nav__icon"
                     direction="left" />
        </span>

        <div className={ `${ languagesIsOpened ? 'languages is-opened' : 'languages' }${ loginShiftedOverlay }` }>
          <LanguageMenu useBorder={ true } onChoose={ handleCloseSubMenu } />
        </div>

        <div className={ `${ rssIsOpened && overlayType !== 'login' ? 'rss is-opened' : 'rss' }${ loginShiftedOverlay }` }>
          <RSSMenu useBorder={ true } />
        </div>

        <div className={ overlayType === 'account' ? 'login is-opened' : `login${ overlayType === 'accountChange' ? ` is-shifted` : `${ overlayType === 'accountChangePassword' ? ' is-shifted--double' : '' }` }` }>
          <Account />
        </div>

        <div className={ overlayType === 'accountChange' ? 'login is-opened' : `login is-account-change${ overlayType === 'accountChangePassword' ? ' is-shifted' : '' }` }>
          <AccountChange />
        </div>

        <div className={ overlayType === 'accountChangePassword' ? 'login is-opened' : 'login is-account-change' }>
          <AccountChangePassword />
        </div>

        <div className={ overlayType === 'accountChangePasswordMsg' ? 'login is-opened' : 'login' }>
          <AccountChangePasswordMsg />
        </div>

        <div className={ overlayType === 'logout' ? 'login is-opened' : 'login' }>
          <LogoutMsg />
        </div>

        <div className={ overlayType === 'login' ? 'login is-opened' : 'login' }>
          <Login />
        </div>

        <div className={ overlayType === 'register' ? 'login is-opened' : 'login' }>
          <Register />
        </div>

        <div className={ overlayType === 'registerMsg' ? 'login is-opened' : 'login' }>
          <RegisterThankMsg />
        </div>

        <div className={ overlayType === 'forgot' ? 'login is-opened' : 'login' }>
          <ForgotPassword />
        </div>

        <div className={ overlayType === 'forgotMsg' ? 'login is-opened' : 'login' }>
          <ForgotPasswordThankMsg />
        </div>

        <div className={ overlayType === 'deleted' ? 'login is-opened' : 'login' }>
          <ProfileDeletedMsg />
        </div>
      </div>
    </MobileMenuElement>
  )
});

MobileMenu.defaultProps = {
  isOpen: false,
  setIsClosed: () => {}
};

MobileMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsClosed: PropTypes.func.isRequired
};

export default MobileMenu;