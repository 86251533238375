import React, { createContext, useReducer } from 'react';

// import reducer
import reducer from './reducer';

// import initial state
import { initialState } from './state';

// define React context
export const GlobalStateContext    = createContext();
export const GlobalDispatchContext = createContext();

const GlobalContextProvider = ( { children } ) => {
  const [state, dispatch] = useReducer( reducer, initialState );

  return (
    <GlobalStateContext.Provider value={ state }>
      <GlobalDispatchContext.Provider value={ dispatch }>
        { children }
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

export default GlobalContextProvider;