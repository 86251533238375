import styled from 'styled-components';

export const HeaderLogoElement = styled.nav`
  position: relative;
  display: flex;
  align-items: flex-end;

  .header-logo {
    &__link {
      display: flex;
    }
    
    &__text {
      position: absolute;
      left: 40px;
      bottom: -7px;
      width: 220px;
      font-size: 12px;
      font-weight: 400;
      line-height: 1;
      letter-spacing: .6px;
      color: var(--white);
      
      &__link {
        position: relative;
        color: var(--white);
        text-decoration: none;
        
        &::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: -3px;
          display: block;
          width: 100%;
          border-bottom: 1px solid var(--white);
          transform: scaleX(0);
          transition: transform .3s ease-in-out;
        }
        
        &:hover {
          &::after {
            transform: scaleX(1);
          }
        }
      }
    }
  }
 
  /*-------------------------
  # Media Queries
  --------------------------*/
  @media (max-width: 375px) {
    .header-logo {
      &__icon {
        width: 22px;
        height: 22px;
      }
    }
  }
`;