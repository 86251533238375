import React, { memo, useContext, useState, useEffect } from 'react';
import { Link, useStaticQuery, graphql }                from 'gatsby';
import { useLocation }                                  from '@reach/router';

// import context
import { GlobalStateContext } from '../../../context/GlobalContextProvider';

// import helpers
import { useWindowSize, getCategoryName } from '../../../helpers';

// import styles
import { CategoriesElement } from './styles/Categories';

// import components
import ArrowIcon from '../icons/ArrowIcon';

const Categories = memo( () => {
  const data = useStaticQuery( graphql`
    {
      allApiPoints {
        nodes {
          categories_text {
            title
            all_text
          }
          categories {
            categories_list {
              id
              link
              text
            }
          }
        }
      }
    }
  `);

  // define the component context
  const state = useContext( GlobalStateContext );
  const { defaultLang } = state;

  // define the component state
  const [dropdownHeight, setDropdownHeight] = useState( 0 );
  const [scrollPosition, setScrollPosition] = useState( 0 );
  const [categories, setCategories]         = useState( [] );
  const [texts, setTexts] = useState({
    categoriesTitle: '',
    allCategoriesText: ''
  });
  const { categoriesTitle, allCategoriesText } = texts;

  // define the component custom hook
  const { width }    = useWindowSize();
  const { pathname } = useLocation();

  /**
   * Get texts from the backend and processing changing language
   */
  useEffect( () => {
    // get loaded data from graphql
    data.allApiPoints.nodes.forEach( node => {
      const { categories, categories_text } = node;
      let orderNumber;

      switch ( defaultLang ) {
        case 'en':
          orderNumber = 1;
          break;

        default:
          orderNumber = 0;
      }

      const current = categories[orderNumber];
      const text    = categories_text[orderNumber];
      const { categories_list } = current;
      const { title, all_text } = text;

      setCategories( categories_list );
      setTexts( t => ({
        ...t,
        categoriesTitle: title,
        allCategoriesText: all_text
      }) )
    });
  }, [data, defaultLang] );

  /**
   * Counting scroll position
   */
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition( position );
  };

  /**
   * Add and remove scroll event listener
   */
  useEffect(() => {
    window.addEventListener( 'scroll', handleScroll, { passive: true } );

    return () => {
      window.removeEventListener( 'scroll', handleScroll );
    };
  }, [] );

  /**
   * Processing when hover in on a menu item
   *
   * @param e | Event
   * @param hasChildren | bool
   */
  const handleMouseEnter = ( e, hasChildren ) => {
    if ( hasChildren ) {
      const childrenElement = e.currentTarget.children[1];
      const childrenElementDimensions = childrenElement.getBoundingClientRect().toJSON();
      const { height } = childrenElementDimensions;

      setDropdownHeight( height - 15 );
    }
  };

  /**
   * Processing when hover out on a menu item
   */
  const handleMouseLeave = () => setDropdownHeight( 0 );

  // define the additional style
  const categoriesHeight = width <= 1440 ? scrollPosition > 0 ? 60 : 75 : scrollPosition > 0 ? 75 : 98;
  const extendedHeight = dropdownHeight > 0 ? { height: `${ categoriesHeight + dropdownHeight }px` } : { height: `${ categoriesHeight }px` };

  return (
    <CategoriesElement className={ dropdownHeight > 0 ? 'is-opened' : '' }
                       style={ extendedHeight }>
      <div className="container">
        <div className="categories"
             style={ { height: categoriesHeight } }>
          <h5 className="title">{ categoriesTitle }</h5>

          <ul className="list">
            { pathname.match( /categories/ ) || pathname.match( /kategorien/ ) ?
             <li className="list__item">
               <Link className="list__item__link"
                     to={ defaultLang === 'de' ? '/' : `/${ defaultLang }/` }>
                 { allCategoriesText }
               </Link>
             </li>
            : false }

            { categories.map( category => {
              const { text, link, subcategories } = category;
              const hasChildren  = subcategories !== undefined && subcategories.length > 0;
              const categoryName = getCategoryName( text );
              const categoryLink = defaultLang === 'de' ? `/kategorien/${ link }` : `/${ defaultLang }/categories/${ link }`;

              return (
                <li className="list__item"
                    key={ link }>
                  <Link className={ hasChildren ? 'list__item__link list__item__link--additional-padding' : 'list__item__link' }
                        to={ categoryLink }
                        onMouseEnter={ ( e ) => handleMouseEnter( e, hasChildren ) }
                        onMouseLeave={ handleMouseLeave }>
                    <span dangerouslySetInnerHTML={ { __html: categoryName } } />

                    { hasChildren ?
                      <ArrowIcon className="list__item__link__arrow"
                                 direction="down"
                                 color="#0E1237" />
                    : false }
                  </Link>

                  { hasChildren ?
                    <ul className="list__item__children"
                        style={ { top:  width <= 1440 ? scrollPosition > 0 ? 35 : 50 : scrollPosition > 0 ? 50 : 70 } }>
                      { subcategories.map( child => {
                        const { text, link } = child;

                        return <li className="list__item__children__item" key={ link }>{ text }</li>
                      } ) }
                    </ul>
                  : false }
                </li>
              )
            } ) }
          </ul>
        </div>
      </div>
    </CategoriesElement>
  )
});

export default Categories;