import styled from 'styled-components';

export const HeaderNavElement = styled.nav`
  --header-icons-dimensions: 24px;

  display: flex;
  align-items: center;

  .header-nav {
    &__login {
      display: flex;
      align-items: flex-start;
      margin-right: 16px;
      
      &__icon {
        margin-right: 12px;
      }
      
      &__link {
        position: relative;
        opacity: .7;
        font-size: 14px;
        line-height: 19px;
        text-transform: uppercase;
        text-decoration: none;
        letter-spacing: 1.25px;
        color: var(--theme-light-gray);
        transition: .3s all ease-in-out;
        
        &:hover {
          opacity: .89;
          cursor: pointer;
          
          &::after {
            transform: scaleX(1);
          }
        }
        
        &::after {
          content: '';
          position: absolute;
          bottom: -3px;
          display: block;
          width: 100%;
          opacity: .89;
          border-bottom: 1px solid var(--theme-light-gray);
          transform: scaleX(0);
          transition: transform .3s ease-in-out;
        }
      }
      
      &__separator {
        width: 1px;
        height: 19px;
        margin: 0 9px;
        background-color: var(--theme-light-gray);
      }
    }
    
    &__mm-logo {
      display: flex;
      
      &__icon {
        margin-right: 16px;
        
        path {
          transition: .3s all ease-in-out;
        }
        
        &:hover {
          path {
            fill: var(--theme-blue);
          }
        }
      }
    }
    
    &__rss {
      width: var(--header-icons-dimensions);
      height: var(--header-icons-dimensions);
      margin-right: 16px;
      
      path {
        transition: .3s all ease-in-out;
      }
      
      &:hover {
        cursor: pointer;
        
        path {
          fill: var(--theme-blue);
        }
      }
    }
    
    &__lang {
      width: var(--header-icons-dimensions);
      height: var(--header-icons-dimensions);
      
      path {
        transition: .3s all ease-in-out;
      }
      
      &__wrapper {
        position: relative;
        width: var(--header-icons-dimensions);
        height: var(--header-icons-dimensions);
        
        &:hover {
          cursor: pointer;
          
          path {
            fill: var(--theme-blue);
          }
        }
      }
      
      &__indicator {
        --dimensions: 22px;
        
        position: absolute;
        left: -9px;
        top: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: var(--dimensions);
        height: var(--dimensions);
        border-radius: 50%;
        font-size: 10px;
        line-height: 1;
        font-weight: 600;
        text-transform: uppercase;
        color: var(--white);
        background-color: var(--theme-blue);
      }
    }
  }
`;