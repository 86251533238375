import React, { memo } from 'react';
import PropTypes       from 'prop-types';

const BrandIconExtended = memo( ( { className, color } ) => {
  return (
    <svg className={ className }
         xmlns="http://www.w3.org/2000/svg"
         width="194.035"
         height="34.482"
         viewBox="0 0 194.035 34.482">
      <g transform="translate(-376.442 -288.971)">
        <path d="M382.973,288.971a6.518,6.518,0,0,0-6.531,6.507V301.6h3.6v-6.123a2.921,2.921,0,0,1,2.93-2.906h24.348v24.346a2.921,2.921,0,0,1-2.905,2.932H380.044v-5.307h-3.6v8.909h27.974a6.535,6.535,0,0,0,6.507-6.534V288.971Z"
              transform="translate(0)"
              fill={ color } />
        <g transform="translate(386.558 297.229)">
          <g>
            <path d="M381.308,292.664h13.848l-7.559,13.1h7.295v3.647l-14.237-.022,7.513-12.971h-6.86Z"
                  transform="translate(-380.655 -292.054)"
                  fill={ color } />
            <path d="M392.9,292.664h4.452l3.367,11.094,3.535-11.094H407.4l3.58,11.05,3.364-11.05h4.411l-5.372,16.727h-4.5l-3.1-10-2.992,10h-4.476Z"
                  transform="translate(-363.497 -292.054)"
                  fill={ color } />
            <path d="M406.958,309.391V292.664h9.694v3.8h-5.326v2.709h5.023v3.712h-5.023v2.8h5.417v3.712Z"
                  transform="translate(-343.799 -292.054)"
                  fill={ color } />
            <path d="M419.453,309.391h-4.368V292.664h4.368Z"
                  transform="translate(-332.411 -292.054)"
                  fill={ color } />
            <path d="M420.969,297.736c0-3.34,2.776-5.326,5.744-5.326a10.21,10.21,0,0,1,5.35,1.789l-1.7,3.275A6.733,6.733,0,0,0,427,296.141c-1.068,0-1.681.591-1.681,1.465,0,.9,1.028,1.2,1.9,1.527.523.2,1.373.547,1.811.72,2.512,1.006,3.8,2.536,3.8,5.047,0,3.58-3.321,5.458-6.683,5.458a10.356,10.356,0,0,1-5.765-2.075s1.373-2.752,1.748-3.383a6.384,6.384,0,0,0,3.928,1.616c1.222,0,2.425-.415,2.425-1.311,0-.7-.11-1.028-1.746-1.638-.526-.2-1.772-.677-2.49-1.006A4.945,4.945,0,0,1,420.969,297.736Z"
                  transform="translate(-324.99 -292.41)"
                  fill={ color } />
            <path d="M431.967,309.4V296.647h-3.451v-3.974H439.96v3.974H436.51V309.4Z"
                  transform="translate(-313.591 -292.042)"
                  fill={ color } />
            <path d="M436.715,309.391V292.664h9.694v3.8h-5.326v2.709H446.1v3.712h-5.021v2.8H446.5v3.712Z"
                  transform="translate(-302.103 -292.054)"
                  fill={ color } />
            <path d="M449.025,309.391H444.66V292.664h4.365Z"
                  transform="translate(-290.97 -292.054)"
                  fill={ color } />
            <path d="M454.776,299.411v9.979h-4.365V292.664h4.365l7.818,9.915v-9.915h4.236v16.727h-4.368Z"
                  transform="translate(-282.911 -292.054)"
                  fill={ color } />
          </g>
        </g>
      </g>
    </svg>
  )
});

BrandIconExtended.defaultProps = {
  className: '',
  color: '#fff'
};

BrandIconExtended.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
};

export default BrandIconExtended;