import styled from 'styled-components';

export const NavColElement = styled.div`
  display: flex;
  flex-direction: column;
  width: 285px;
  
  .nav {
    &-title {
      margin: 0 0 0.25em 0;
      border-bottom: 1px solid var(--white);
      padding-bottom: 0.25em;
      text-transform: uppercase;
      font-size: 1em;
      font-weight: 400;
      line-height: 1em;
      color: var(--white);
      letter-spacing: .08em;
    }
    
    &-link {
      position: relative;
      width: fit-content;
      margin-top: 1em;
      text-decoration: none;
      font-size: 0.9em;
      font-weight: 400;
      line-height: 1em;
      letter-spacing: .08em;
      color: var(--white);
      
      &:hover {
        &::after {
          width: 100%;
        }
      }
      
      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -1px;
        width: 0;
        height: 1px;
        background-color: var(--white);
        transition: .5s all ease-in-out;
      }
    }
  }
  
  .list {
    margin: 15px 0 0;
    padding: 0;
    list-style: none;
    
    &__link {
      position: relative;
      font-size: 14px;
      line-height: 33px;
      letter-spacing: 1.01px;
      text-transform: capitalize;
      text-decoration: none;
      color: var(--white);
      transition: all 0.3s ease-in-out 0s;
      
      &:hover {
        &::after {
          width: 100%;
        }
      }
      
      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -3px;
        width: 0;
        height: 1px;
        background: var(--theme-light-gray);
        transition: width 0.3s ease-in-out 0s;
      }
    }
  }
  
  /*-------------------------
  # Media Queries
  --------------------------*/ 
  @media (max-width: 1440px) {
    .heading {
      font-size: 14px;
      line-height: 29px;
    }
    
    .list {
      margin-top: 5px;
      
      &__item,
      &__link {
        line-height: 28px;
      }
    }
  }
  
  @media (max-width: 1280px) {
    min-width: 170px;
    
    .list {
      display: none;
    }
  }
  
  @media (max-width: 1199px) {
    width: 265px;
  }
  
  @media (max-width: 1024px) {
    .nav {
      &-title {
        margin:  1.5em 0 0.25em 0;
      }
    }
  }
  
  @media (max-width: 991px) {
    width: 250px;
      
    .heading {
      border-bottom: none;
      text-align: center;
      font-size: 18px;
      line-height: 22px;
    }
    
    .nav {
      &-title {
        font-size: 0.75em;
        line-height: 1em;
        margin: 0.25em 0 0.2em 0;
        padding-bottom: 0.6em;
        padding-top: 0.6em;
        font-weight: 800;
        text-align: center;
      }
    
      &-link {
        display: none;
      }
    }
  }
  
  @media (max-width: 480px) {
    margin-bottom: 0;
  
    .heading {
      margin-bottom: 10px;
      font-size: 16px;
    }
  }
`;