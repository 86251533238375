import React, { memo } from 'react';
import PropTypes       from 'prop-types';

const FacebookIcon = memo( ( { className, color } ) => {
  return (
    <svg className={ className }
         xmlns="http://www.w3.org/2000/svg"
         width="10.708"
         height="23.05"
         viewBox="0 0 10.708 23.05">
      <g transform="translate(0 0)">
        <g>
          <path d="M1065.832,767.963v11.144a.29.29,0,0,0,.291.29h4.138a.29.29,0,0,0,.29-.29V767.782h3a.29.29,0,0,0,.289-.266l.289-3.412a.29.29,0,0,0-.29-.315h-3.288v-2.421a1.027,1.027,0,0,1,1.028-1.027h2.312a.29.29,0,0,0,.29-.29v-3.413a.291.291,0,0,0-.29-.29h-3.906a4.152,4.152,0,0,0-4.152,4.152v3.29h-2.069a.29.29,0,0,0-.29.29v3.412a.29.29,0,0,0,.29.29h2.069Z"
                transform="translate(-1063.472 -756.348)"
                fill={ color }
                fillRule="evenodd" />
        </g>
      </g>
    </svg>
  );
});

FacebookIcon.defaultProps = {
  className: '',
  color: '#fff'
};

FacebookIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

export default FacebookIcon;