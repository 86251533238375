import React, { memo, useContext, useState, useEffect } from 'react';
import { useStaticQuery, graphql }                      from 'gatsby';
import PropTypes                                        from 'prop-types';

// import context
import { GlobalStateContext } from '../../context/GlobalContextProvider';

// import helpers
import { useWindowSize } from '../../helpers';

// import styles
import { CloseBtnElement } from '../../styles/elements/CloseBtn';

// import components
import CrossIcon from './icons/CrossIcon';

const CloseBtn = memo( ( { className, onClick } ) => {
  const data = useStaticQuery( graphql`
    {
      allApiPoints {
        nodes {
          login {
            close_text
          }
        }
      }
    }
  `);

  // define the component context
  const state = useContext( GlobalStateContext );
  const { defaultLang } = state;

  // define the default component state
  const [closeText, setCloseText] = useState( '' );

  // define the component custom hooks
  const windowSize = useWindowSize();
  const { width } = windowSize;

  /**
   * Get texts from the backend and processing changing language
   */
  useEffect( () => {
    // get loaded data from graphql
    data.allApiPoints.nodes.forEach( node => {
      const { login } = node;
      let orderNumber;

      switch ( defaultLang ) {
        case 'en':
          orderNumber = 1;
          break;

        default:
          orderNumber = 0;
      }

      setCloseText( login[orderNumber].close_text );
    });
  }, [data, defaultLang] );

  /**
   * Processing click action.
   * Passing data to the received function
   */
  const handleClick = () => {
    if ( onClick !== undefined ) onClick();
  };

  return (
    <CloseBtnElement className={ className }
                     onClick={ handleClick } >
      { width > 991 ? closeText : false }
      <CrossIcon className={ `${ className }__icon` } />
    </CloseBtnElement>
  )
});

CloseBtn.defaultProps = {
  className: 'close',
  onClick: () => {}
};

CloseBtn.propTypes = {
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

export default CloseBtn;