import React, { memo } from 'react';
import PropTypes       from 'prop-types';

const SearchIcon = memo( ( { className, color } ) => {
  return (
    <svg className={ className }
         xmlns="http://www.w3.org/2000/svg"
         width="28.477"
         height="28.482"
         viewBox="0 0 28.477 28.482">
      <path d="M28.09,24.625l-5.546-5.546a1.334,1.334,0,0,0-.946-.389h-.907a11.564,11.564,0,1,0-2,2V21.6a1.334,1.334,0,0,0,.389.946l5.546,5.546a1.329,1.329,0,0,0,1.886,0l1.574-1.574a1.341,1.341,0,0,0,.006-1.891ZM11.57,18.69a7.12,7.12,0,1,1,7.12-7.12A7.116,7.116,0,0,1,11.57,18.69Z"
            fill={ color } />
    </svg>
  )
});


SearchIcon.defaultProps = {
  className: '',
  color: '#431e75'
};

SearchIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
};

export default SearchIcon;