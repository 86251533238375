import React, { memo, useContext, useState, useEffect } from 'react';
import { useStaticQuery, graphql }                      from 'gatsby';

// import constants
import { AUTH_URL, CHANGE_OVERLAY } from '../../../constants/index';

// import context
import { GlobalDispatchContext, GlobalStateContext } from '../../../context/GlobalContextProvider';

// import helpers
import { postData, usePasswordValidation, useWindowSize, useGetCookie } from '../../../helpers/index';

// import utils
import { getRefreshToken, setAccessToken } from '../../../utils/Auth';

// import styles
import { AccountElement } from './styles/index';

// import components
import EyeVisibleOffIcon from '../icons/EyeVisibleOffIcon';
import EyeVisibleOnIcon  from '../icons/EyeVisibleOnIcon';
import AccountUser       from './AccountUser';
import AccountBack       from './AccountBack';
import PasswordHint      from '../PasswordHint';
import Button            from '../Button';

const AccountChangePassword = memo( () => {
  const data = useStaticQuery( graphql`
    {
      allApiPoints {
        nodes {
          account {
            button_text
            edit_password_text
            edit_password_error
            edit_password_match
            back_text
            password_placeholder
            confirm_password_placeholder
            change_password_msg
          }
        }
      }
    }
  `);

  // define the component context
  const state    = useContext( GlobalStateContext );
  const dispatch = useContext( GlobalDispatchContext );
  const { defaultLang } = state;

  // define the default component state
  const [confirmPasswordIsVisible, setConfirmPasswordIsVisible] = useState( false );
  const [passwordIsVisible, setPasswordIsVisible] = useState( false );
  const [isBtnProcess, setIsBtnProcess] = useState( false );
  const [isError, setIsError]   = useState( false );
  const [showHint, setShowHint] = useState( false );
  const [notMatch, setNotMatch] = useState( false );
  const [success, setSuccess]   = useState( false );
  const [formData, setFormData] = useState({
    accountPassword: '',
    accountConfirmPassword: ''
  });
  const [texts, setTexts] = useState({
    accountButton: '',
    accountTitle: '',
    accountPasswordError: '',
    accountPasswordNotMatchError: '',
    accountBack: '',
    accountPasswordPlaceholder: '',
    accountConfirmPlaceholder: '',
    accountChangeMsg: ''
  });

  const { accountPassword, accountConfirmPassword } = formData;
  const {
    accountButton, accountTitle, accountPasswordError, accountPasswordNotMatchError,
    accountBack, accountPasswordPlaceholder, accountConfirmPlaceholder, accountChangeMsg
  } = texts;

  // define the component custom hooks
  const validation = usePasswordValidation( accountPassword );
  const session    = useGetCookie( 'MMBlogSession' );
  const windowSize = useWindowSize();
  const { width }  = windowSize;

  /**
   * Get texts from the backend and processing changing language
   */
  useEffect( () => {
    // get loaded data from graphql
    data.allApiPoints.nodes.forEach( node => {
      const { account } = node;
      let orderNumber;

      switch ( defaultLang ) {
        case 'en':
          orderNumber = 1;
          break;

        default:
          orderNumber = 0;
      }

      const current = account[orderNumber];
      const {
        button_text, edit_password_text, edit_password_error, edit_password_match,
        back_text, password_placeholder, confirm_password_placeholder, change_password_msg
      } = current;

      setTexts( t => ({
        ...t,
        accountButton: button_text,
        accountTitle: edit_password_text,
        accountPasswordError: edit_password_error,
        accountPasswordNotMatchError: edit_password_match,
        accountBack: back_text,
        accountPasswordPlaceholder: password_placeholder,
        accountConfirmPlaceholder: confirm_password_placeholder,
        accountChangeMsg: change_password_msg
      }));
    });
  }, [data, defaultLang] );

  /**
   * Processing a change password action.
   * Validating the data, passing the data to the backend or showing an error
   *
   * @param e | Event
   */
  const handleSubmit = ( e ) => {
    e.preventDefault();

    const { accountPassword, accountConfirmPassword } = formData;

    if ( accountPassword !== '' && validation ) {
      if ( accountPassword === accountConfirmPassword ) {
        const data = {
          refresh: getRefreshToken( session )
        };

        setIsBtnProcess( true );

        postData( AUTH_URL + 'token/refresh', 'POST', data, false )
          .then( data => {
            const response = data[0];
            const { status } = response;

            // if the user successfully refresh an Access token
            if ( status === 200 ) {
              const resJson = data[1];
              const { access } = resJson;

              setAccessToken( access, session );

              const authorizationData = {
                type: 'Bearer',
                token: access
              };

              const passwordData = {
                password: accountPassword,
                password2: accountConfirmPassword
              };

              postData( AUTH_URL + 'change-password/', 'PUT', passwordData, true, authorizationData )
                .then( data => {
                  const response = data[0];
                  const { status } = response;

                  setIsBtnProcess( false );

                  // if success
                  if ( status === 201 ) {
                    setFormData({
                      accountPassword: '',
                      accountConfirmPassword: ''
                    });

                    if ( width < 992 ) {
                      setSuccess( true );

                      setTimeout( () => {
                        dispatch( { type: CHANGE_OVERLAY, payload: 'account' } );
                        setSuccess( false );
                      }, 5000 );
                    } else {
                      dispatch( { type: CHANGE_OVERLAY, payload: 'accountChangePasswordMsg' } );
                    }
                  }
                });
            }
          });
      } else setNotMatch( true );
    } else setIsError( true );
  };

  /**
   * Hide an error
   */
  const handleHideError = () => {
    if ( isError ) setIsError( false );
    if ( notMatch ) setNotMatch( false );
  };

  return (
    <AccountElement className="account-change">
      <div className="account__container">
        <div className="account__top">
          <AccountUser />
        </div>

        <div className="account__fields">
          <div className="account__details">
            <h4 className="account__details__title">{ accountTitle }</h4>
          </div>

          { success ?
            <div className="account__edit">
              <span className="account__edit__msg">{ accountChangeMsg }</span>
            </div>
          :
            <form className="account__edit"
                  onSubmit={ ( e ) => handleSubmit( e ) }>
              { isError ? <span className="overlay__error account__error" dangerouslySetInnerHTML={ { __html: accountPasswordError } } /> : false }
              { notMatch ? <span className="overlay__error account__error">{ accountPasswordNotMatchError }</span> : false }
              <div className="field-group">
                <label className="field-label"
                       htmlFor="first_name">{ accountPasswordPlaceholder }</label>
                <input className="field field--is-editable field--is-password"
                       type={ passwordIsVisible ? 'text' : 'password' }
                       value={ accountPassword }
                       placeholder={ accountPasswordPlaceholder }
                       onChange={ ( e ) => { setFormData( { ...formData, accountPassword: e.currentTarget.value } ) } }
                       onFocus={ () => { handleHideError(); setShowHint( true ) } }
                       onBlur={ () => setShowHint( false ) } />
                <span className="field__icon"
                      role="presentation"
                      onClick={ () => { setPasswordIsVisible( v => !v ); setShowHint( true ); } }>
                  { accountPassword !== '' ?
                    passwordIsVisible ? <EyeVisibleOffIcon className="field__icon__item" /> : <EyeVisibleOnIcon className="field__icon__item" />
                  : false }
                </span>

                { showHint ? <PasswordHint password={ accountPassword } /> : false }
              </div>

              <div className="field-group">
                <label className="field-label"
                       htmlFor="first_name">{ accountConfirmPlaceholder }</label>
                <input className="field field--is-editable field--is-password"
                       type={ confirmPasswordIsVisible ? 'text' : 'password' }
                       value={ accountConfirmPassword }
                       placeholder={ accountConfirmPlaceholder }
                       onChange={ ( e ) => { setFormData( { ...formData, accountConfirmPassword: e.currentTarget.value } ) } }
                       onFocus={ handleHideError } />
                <span className="field__icon"
                      role="presentation"
                      onClick={ () => setConfirmPasswordIsVisible( v => !v ) }>
                  { accountConfirmPassword !== '' ?
                    confirmPasswordIsVisible ? <EyeVisibleOffIcon className="field__icon__item" /> : <EyeVisibleOnIcon className="field__icon__item" />
                  : false }
                </span>
              </div>

              <div className="account__bottom">
                <AccountBack text={ accountBack } overlay="accountChange" />
              </div>

              <Button className={ `overlay__login__btn${ isBtnProcess ? ' processing' : '' }` }
                      text={ accountButton }
                      disabled={ isBtnProcess }
                      method="submit" />
            </form>
          }
        </div>
      </div>
    </AccountElement>
  );
});

export default AccountChangePassword;