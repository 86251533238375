import styled from 'styled-components';

export const HeaderElement = styled.header`
  position: fixed;
  left: 0;
  top: 0;
  height: 75px;
  width: 100%;
  background-color: var(--theme-dark-purple);
  z-index: 6;
  
  .container {
    height: 100%;
  }
  
  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    transition: opacity .5s, visibility .5s, height .4s;
    background: var(--theme-deep-purple);
    z-index: 10;
    
    &.is-opened {
      height: 100%;
      opacity: 1;
      visibility: visible;
      
      .overlay {
        &__icon,
        &__title,
        &__list__item,
        &__helper-text,
        &__field-group,
        &__remember,
        &__login__btn,
        &__gender-switcher,
        &__subscribe,
        &__privacy,
        &__forgot,
        &__register__btn{
          animation: fadeInRight .5s ease forwards;
        }
        
        &__icon {
          animation-delay: .25s;
        }
        
        &__title {
          animation-delay: .3s;
        }
        
        &__list__item {
          animation-delay: .35s;
          
          &:nth-of-type(2) {
            animation-delay: .4s;
          }
          
          &:nth-of-type(3) {
            animation-delay: .45s;
          }
          
          &:nth-of-type(4) {
            animation-delay: .5s;
          }
          
          &:nth-of-type(5) {
            animation-delay: .55s;
          }
          
          &:nth-of-type(6) {
            animation-delay: .6s;
          }
        }
        
        &__helper-text {
          animation-delay: .35s;
        }
        
        &__field-group,
        &__gender-switcher {
          animation-delay: .4s;
        }
        
        &__remember,
        &__subscribe {
          animation-delay: .45s;
        }
        
        &__login__btn,
        &__privacy,
        &__forgot {
          animation-delay: .5s;
        }
        
        &__register__btn {
          animation-delay: .55s;
        }
        
        &__close {
          animation: fadeInTop .5s ease forwards;
          animation-delay: 1s;
        }
      }
      
      .account {
        &__top,
        &__fields,
        &__bottom {
          animation: fadeInRight .5s ease forwards;
        } 
        
        &__top {
          animation-delay: .4s;
        }
        
        &__fields {
          animation-delay: .45s;
        }
        
        &__bottom {
          animation-delay: .5s;
        } 
      }
    }
    
    &__container,
    &__form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &__container {
      height: 100%;
      width: 100%;
    }
    
    &__icon {
      position: relative;
      width: 40px;
      height: 40px;
      margin-bottom: 15px;
      opacity: 0;
      animation: fadeOutRight .5s ease forwards;
      animation-delay: .6s;
      
      &--decorated {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: var(--white);
      }
    }
    
    &__title {
      position: relative;
      min-width: 178px;
      margin: 0 0 30px 0;
      opacity: 0;
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 1.2px;
      color: var(--white);
      animation: fadeOutRight .5s ease forwards;
      animation-delay: .55s;
      
      &::after {
        --shift: 8px;
      
        content: '';
        position: absolute;
        left: calc(var(--shift) / -2);
        bottom: -4px;
        width: calc(100% + var(--shift));
        height: 1px;
        background-color: var(--white);
      }
    }
    
    &__list {
      margin: 0;
      padding: 0;
      list-style: none;
      text-align: center;
      
      &__item {
        position: relative;
        margin-bottom: 30px;
        opacity: 0;
        font-size: 18px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: 1.4px;
        text-transform: uppercase;
        color: var(--white);
        transition: .3s all ease-in-out;
        animation: fadeOutRight .5s ease forwards;
        animation-delay: .5s;
        
        &:nth-of-type(2) {
          animation-delay: .45s;
        }
          
        &:nth-of-type(3) {
          animation-delay: .4s;
        }
        
        &:nth-of-type(4) {
          animation-delay: .35s;
        }
        
        &:nth-of-type(5) {
          animation-delay: .3s;
        }
        
        &:nth-of-type(6) {
          animation-delay: .35s;
        }
        
        &:last-child {
          margin-bottom: 0;
        }
        
        &:hover {
          color: var(--theme-light-blue);
          cursor: pointer;
        }
        
        &__link {
          font-size: 18px;
          font-weight: 600;
          line-height: 26px;
          letter-spacing: 1.4px;
          text-transform: uppercase;
          text-decoration: none;
          color: var(--white);
          transition: .3s all ease-in-out;
        
          &--current,
          &:hover {
            color: var(--theme-light-blue);
          } 
        }
        
        &--current {
          color: var(--theme-light-blue);
        }
      }
    }
    
    &__close {
      --width: 130px;
    
      position: absolute;
      left: calc(50% - (var(--width) / 2));
      bottom: 50px;
      display: flex;
      width: var(--width);
      align-items: center;
      justify-content: center;
      opacity: 0;
      font-weight: 600;
      line-height: 19px;
      text-transform: uppercase;
      color: var(--theme-light-blue);
      transition: .3s all ease-in-out;
      animation: fadeOutTop .5s ease forwards;
      animation-delay: .2s;
      
      &:hover {
        cursor: pointer;
        
        .overlay__close__icon {
          transform: rotate(1turn);
        }
      }
      
      &__icon {
        position: relative;
        top: -1px;
        margin-left: 10px;
        transform: rotate(0deg);
        transition: .6s all ease-in-out;
      }
    }
    
    &__helper-text,
    &__privacy,
    &__forgot {
      position: relative;
      margin-bottom: 30px;
      opacity: 0;
      font-weight: 400;
      letter-spacing: 1.5px;
      color: var(--white);
    
      &__link {
        color: var(--theme-blue);
        text-decoration: none;
        
        &:hover {
          cursor: pointer;
        }
      }
    }
    
    &__helper-text {
      &--forgot {
        margin-bottom: 10px;
      }
      
      &--center {
        text-align: center;
      }
    }
    
    &__error {
      max-width: 560px;
      margin-bottom: 10px;
      text-align: center;
      letter-spacing: .75px;
      line-height: 19px;
      color: var(--theme-error);
      
      &--forgot {
        margin-bottom: 10px;
      }
    }
    
    &__field-group {
      position: relative;
      margin-bottom: 20px;
      opacity: 0;
      
      &--flex {
        display: flex;
        justify-content: space-between;
      }
    }
    
    &__gender-switcher,
    &__field {
      --width: 560px;
      
      width: var(--width);
    }
    
    &__field {
      height: 70px;
      border: 2px solid var(--white);
      border-radius: 38px;
      padding: 0 35px;
      color: var(--white);
      background-color: transparent;
      transition: .15s all ease-in-out;
      
      &:focus {
        outline: none;
      }
      
      &::placeholder {
        text-transform: uppercase;
        color: var(--theme-dark-gray);
      }
      
      &--half {
        width: calc(var(--width) / 2 - 15px);
      }
      
      &--left {
        margin-right: 15px;
      }
      
      &--right {
        margin-left: 15px;
      }
      
      &--password {
        text-transform: initial;
        
        &::placeholder {
          text-transform: uppercase;
        }
      }
      
      &--textarea {
        display: block;
        width: var(--width);
        max-width: var(--width);
        min-width: var(--width);
        min-height: 130px;
        padding-top: 15px;
      }
      
      &--is-invalid {
        border-color: var(--theme-error);
      }
      
      &__icon {
        --dimensions: 28px;
    
        position: absolute;
        top: calc(50% - (var(--dimensions) / 2));
        right: 25px;
        width: var(--dimensions);
        height: var(--dimensions);
        
        &:hover {
          cursor: pointer;
        }
        
        &__item {
          width: var(--dimensions);
          height: var(--dimensions);
        }
      }
    }
    
    &__remember {
      position: relative;
      margin-bottom: 15px;
      opacity: 0;
      
      &__mark {
        display: none;
        
        &:checked {
          & + label {
            &::after {
             background-color: var(--theme-blue);
            }
          }
        }
      }
    
      &__label {
        position: relative;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        color: var(--theme-dark-gray);
        
        &::after {
          content: '';
          display: flex;
          width: 15px;
          height: 15px;
          border: 2px solid var(--theme-light-gray);
          margin-left: 30px;
          background-color: var(--theme-light-gray);
        }
        
        &:hover {
          cursor: pointer;
        }
      }
    }
    
    &__login,
    &__register {
      &__btn {
        position: relative;
        opacity: 0;
        will-change: transform;
        transition: all 0.2s ease;
        background-image: none;
        
        &:hover {
          color: var(--white);
        }
        
        &.processing {
          --btn-height: 70px;
        
          width: var(--btn-height);
          height: var(--btn-height);
          border: 2px solid var(--theme-blue);
          border-radius: calc(var(--btn-height) / 2);
          font-size: 0;
          background: transparent;
          border-right-color: var(--white);
          animation: loadingBtn 0.4s linear infinite !important;
        }
      }
    }
    
    &__gender-switcher {
      position: relative;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      padding: 0 37px;
      opacity: 0;
    
      &__label {
        text-transform: uppercase;
        color: var(--theme-dark-gray);
        transition: .15s all ease-in-out;
        
        &--is-invalid {
          color: var(--theme-error);
        }
      }
      
      &__radios {
        display: flex;
      }
      
      &__radio {
        display: none;
        
        &:checked {
          & + label {
            &::before {
             background-color: var(--theme-blue);
            }
          }
        }
      
        &__label {
          display: flex;
          align-items: center;
          margin-left: 30px;
          text-transform: uppercase;
          color: var(--theme-dark-gray);
          
          &::before {
            content: '';
            display: flex;
            width: 15px;
            height: 15px;
            border: 2px solid var(--theme-light-gray);
            margin-right: 15px;
            background-color: var(--theme-light-gray);
          }
          
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    
    &__subscribe {
      position: relative;
      margin-bottom: 10px;
      opacity: 0;
      
      &--uppercase {
        label {
          text-transform: uppercase;
          color: var(--theme-dark-gray);
        }
      }
      
      &__mark {
        display: none;
        
        &:checked {
          & + label {
            &::after {
             background-color: var(--theme-blue);
            }
          }
        }
      }
      
      &__label {
        display: flex;
        align-items: center;
        color: var(--white);
        letter-spacing: .75px;
        
        &::after {
          content: '';
          display: flex;
          width: 15px;
          height: 15px;
          border: 2px solid var(--theme-light-gray);
          margin-left: 30px;
          background-color: var(--theme-light-gray);
        }
        
        &:hover {
          cursor: pointer;
        }
      }
    }
    
    &__additional-actions {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  
  /*-------------------------
  # fadeInRight
  --------------------------*/
  @keyframes fadeInRight {
    0% {
      opacity: 0;
      left: 50px;
    }
    
    100% {
      opacity: 1;
      left: 0;
    }
  }
  
  /*-------------------------
  # fadeOutRight
  --------------------------*/
  @keyframes fadeOutRight {
    0% {
      opacity: 1;
      left: 0;
    }
    
    100% {
      opacity: 0;
      left: 50px;
    }
  }
  
  /*-------------------------
  # fadeInTop
  --------------------------*/
  @keyframes fadeInTop {
    0% {
      opacity: 0;
      bottom: 10px;
    }
    
    100% {
      opacity: 1;
      bottom: 50px;
    }
  }
  
  /*-------------------------
  # fadeOutTop
  --------------------------*/
  @keyframes fadeOutTop {
    0% {
      opacity: 1;
      bottom: 50px;
    }
    
    100% {
      opacity: 0;
      bottom: 10px;
    }
  }
  
  /*-------------------------
  # loadingBtn
  --------------------------*/
  @keyframes loadingBtn {
    0% {
      opacity: 1;
      transform: rotateZ(0);
    }
    
    100% {
      opacity: 1;
      transform: rotateZ(360deg);
    }
  }
  
  /*-------------------------
  # Media Queries
  --------------------------*/
  @media (max-width: 1920px) {
    .overlay {
      &__icon,
      &__title,
      &__helper-text,
      &__privacy {
        &.register-overlay {
          margin-bottom: 10px;
        }
      }
      
      &__subscribe {
        &.register-overlay {
          margin-bottom: 0;
        }
      }
    }
  }
  
  @media (max-width: 1440px) {
    .overlay {
      &__container {
        &.register-overlay {
          padding-bottom: 45px;
        }
      }
    
      &__title {
        margin-bottom: 20px;
        line-height: 21px;
        
        &::after {
          bottom: -8px;
        }
      }
      
      &__helper-text {
        margin-bottom: 7px;
      }
      
      &__field-group,
      &__gender-switcher {
        margin-bottom: 15px;
      }
      
      &__list__item {
        margin-bottom: 20px;
      }
      
      &__subscribe {
        margin-bottom: 5px;
        
        &__label {
          line-height: 21px;
        }
      }
      
      &__privacy {
        line-height: 21px;
      }
      
      &__close {
        bottom: 25px;
      }
      
      &__login,
      &__register {
        &__btn {
          &.processing {
            --btn-height: 57px;
          }
        }
      }
    }
    
    @keyframes fadeInTop {
      0% {
        opacity: 0;
        bottom: 0;
      }
      
      100% {
        opacity: 1;
        bottom: 25px;
      }
    }
    
    @keyframes fadeOutTop {
      0% {
        opacity: 1;
        bottom: 25px;
      }
      
      100% {
        opacity: 0;
        bottom: 0;
      }
    }
  }
  
  @media only screen 
  and (min-device-width: 1366px) 
  and (max-device-height: 768px) 
  and (orientation: landscape) {
    .overlay {
      &__field {
        height: 50px;
        
        &-group {
          margin-bottom: 10px;
        }
      }
      
      &__field,
      &__gender-switcher {
        --width: 450px;
     
        font-size: 14px;
      }
    
      &__list {
        &__item {
          margin-bottom: 15px;
        }
      }
      
      &__helper-text,
      &__subscribe__label,
      &__privacy,
      &__remember__label {
        font-size: 14px;
        line-height: 19px;
      }
      
      &__close {
        bottom: 90px;
      }
    }
    
    @keyframes fadeInTop {
      0% {
        opacity: 0;
        bottom: 0;
      }
      
      100% {
        opacity: 1;
        bottom: 25px;
      }
    }
    
    @keyframes fadeOutTop {
      0% {
        opacity: 1;
        bottom: 25px;
      }
      
      100% {
        opacity: 0;
        bottom: 0;
      }
    }
  }
  
  @media (max-width: 1280px) {
    .overlay {
      &__container {
        &.register-overlay {
          padding-bottom: 35px;
        }
      }
    
      &__icon {
        width: 36px;
        height: 36px;
      }
      
      &__field-group,
      &__gender-switcher {
        margin-bottom: 10px;
      }
      
      &__field {
        height: 65px;
      }
      
      &__subscribe {
        margin-right: 30px;
        
        &__label {
          &::after {
            margin-left: 10px;
          }
        }
      }
    }
    
    @keyframes fadeInTop {
      0% {
        opacity: 0;
        bottom: 0;
      }
      
      100% {
        opacity: 1;
        bottom: 15px;
      }
    }
    
    @keyframes fadeOutTop {
      0% {
        opacity: 1;
        bottom: 15px;
      }
      
      100% {
        opacity: 0;
        bottom: 0;
      }
    }
  }
  
  @media (max-width: 1024px) {
    .overlay {
      &__field {
        height: 60px;
      }
      
      &__gender-switcher, 
      &__field {
        --width: 520px;
      }
    
      &__list {
        &__item {
          margin-bottom: 15px;
        }
      }
      
      &__additional-actions {
        flex-direction: row;
      }
      
      &__close {
        bottom: 90px;
      }
      
      &__subscribe {
        &.register-overlay {
          margin-bottom: 10px;
        }
      }
    }

    @keyframes fadeInTop {
      0% {
        opacity: 0;
        bottom: 0;
      }
      
      100% {
        opacity: 1;
        bottom: 20px;
      }
    }
    
    @keyframes fadeOutTop {
      0% {
        opacity: 1;
        bottom: 20px;
      }
      
      100% {
        opacity: 0;
        bottom: 0;
      }
    }
  }
  
  @media (max-width: 991px) {
    height: 60px;
    
    .overlay {
      &__icon {
        width: 44px;
        height: 44px;
        
        &__item {
          width: 24px;
          height: 24px;
        }
      }
    
      &__gender-switcher, 
      &__field {
        --width: 560px;
      }
      
      &__field {
        height: 70px;
      }
      
      &__field-group, 
      &__gender-switcher {
        margin-bottom: 15px;
      }
    
      &__additional-actions {
        flex-direction: column;
      }
      
      &__subscribe {
        &__label {
          margin-bottom: 10px;
        }
      }
      
      &__close {
        position: absolute;
        top: 20px;
        right: 30px;
        animation: none !important;
        left: inherit;
        bottom: inherit;
        opacity: 1;
        width: 24px;
        height: 24px;
        display: block;
          
        &__icon {
          width: 24px;
          height: 24px;
        }
      }
      
      &__error {
        display: block;
        text-align: center;
      }
      
      &__privacy {
        text-align: center;
      }
    }
  }
  
  @media (max-width: 767px) {
    .overlay {
      &__login,
      &__register {
        &__btn {
          &.processing {
            --btn-height: 40px;
          }
        }
      } 
    }
  }
  
  @media (max-width: 640px) {
    .overlay {
      &__gender-switcher,
      &__field {
        --width: 408px;
      }
      
      &__field {
        height: 56px;
      
        &-group {
          &--flex {
            flex-wrap: wrap;
            justify-content: center;
          }
        }
      
        &--half {
          width: 408px;
        }
        
        &--left {
          margin-right: 0;
          margin-bottom: 15px;
        }
        
        &--right {
          margin-left: 0;
        }
        
        &__icon {
          right: 20px;
        }
      }
      
      &__gender-switcher {
        height: 31px;
      }
    }
  }
  
  @media (max-width: 375px) {
    height: 48px;
  }
`;
