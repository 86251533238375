// import constants
import { DEFAULT_LANG } from '../constants';

export const initialState = {
  defaultLang: DEFAULT_LANG,
  websiteLanguages: ['de', 'en'],
  overlayType: '',
  overlayIsShown: false,
  sponsoredAd: true,
  filterIsShown: false,
  mobileMenuIsOpened: false,
  isLoggedIn: false,
  filterRequestData: {
    author: '',
    year: '',
    time: '',
    level: '',
    sort: ''
  },
  filteredPosts: [],
  sortDropdownTitle: ''
};