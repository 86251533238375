import styled from 'styled-components';

export const ButtonElement = styled.button`
  border: none;
  border-radius: 38px;
  padding: 0;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  background-position: 100%;
  background-size: 400%;
  transition: all .5s ease-in-out;
  
  &:hover {
    background-position: 0;
    cursor: pointer;
  }

  &.blue {
    border: 2px solid var(--theme-blue);
    color: var(--white);
    background-color: var(--theme-blue);
    background-image: linear-gradient(45deg, var(--white) 50%, transparent 50%);
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
    
    
    &:hover {
      border-color: var(--theme-blue);
      color: var(--theme-blue);
      cursor: pointer;
    }
  }
  
  &.normal {
    width: 250px;
    height: 70px;
  }
  
  @media (max-width: 1440px) {
    &.normal {
      width: 200px;
      height: 57px;
    }
  }
  
  @media (max-width: 767px) {
    &.normal {
      width: 180px;
      height: 40px;
    }
  }
`;