import React, { memo, useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes                 from 'prop-types';

// import constants
import { API_URL } from '../../../constants';

// import helpers
import { useEmailValidation } from '../../../helpers';

// import components
import ArrowRegularIcon from '../icons/ArrowRegularIcon';
import InputLoader      from '../InputLoader';

const FooterInfoboxRight = memo( ( { data } ) => {
  const queryData = useStaticQuery( graphql`
    {
      allApiPoints {
        nodes {
          newsletters {
            placeholder_successful_registration
            placeholder_conflict_registration
          }
        }
      }
    }
`);

  const { secondInfoboxPurpleTitle, secondInfoboxBlueTitle, text, placeholder, errorMsg, defaultLang } = data;

  // define the default component state
  const [emailValue, setEmailValue] = useState( '' );
  const [error, setError]           = useState( false );
  const [isLoading, setIsLoading]   = useState( false );
  const [success, setSuccess]       = useState( false );
  const [conflict, setConflict]     = useState( false );
  const [texts, setTexts] = useState({
    successText: '',
    conflictText: ''
  });

  const { successText, conflictText } = texts;

  // define the component custom hook
  const validation = useEmailValidation( emailValue );

  /**
   * Get texts from the backend and processing changing language
   */
  useEffect( () => {
    // get loaded data from graphql
    queryData.allApiPoints.nodes.forEach( node => {
      const { newsletters } = node;
      let orderNumber;

      switch ( defaultLang ) {
        case 'en':
          orderNumber = 1;
          break;

        default:
          orderNumber = 0;
      }

      const current = newsletters[orderNumber];
      const { placeholder_successful_registration, placeholder_conflict_registration } = current;

      setTexts({
        successText: placeholder_successful_registration,
        conflictText: placeholder_conflict_registration,
      });
    });
  }, [queryData, defaultLang] );

  /**
   * Processing when the form was submitted
   *
   * @param e | Event
   */
  async function handleSubmit( e ) {
    e.preventDefault();

    // validate an email
    if ( emailValue !== '' && validation ) {
      const data = { email: emailValue };
      const url = API_URL + 'home/global-newsletter-subscription-' + defaultLang;

      // show loading process
      setIsLoading( true );

      // make a request
      const response = await fetch( url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify( data )
      });

      const res = await response;
      const { status } = res;

      // if entered email has already in the list, show a conflict message
      if ( status === 409 ) {
        setIsLoading( false );
        setSuccess( false );
        setError( false );
        setConflict( true );
        setEmailValue( '' );
      }

      // show a success message
      if ( status === 201 ) {
        setIsLoading( false );
        setSuccess( true );
        setError( false );
        setConflict( false );
        setEmailValue( '' );
      }
    } else {
      // show an error message
      setError( true );
      setEmailValue( '' );
    }
  }

  const InputPlaceholder = success ? successText : placeholder && conflict ? conflictText : placeholder;

  return (
    <div className="infobox">
      <h2 className="infobox__title"><span className="colored">{ secondInfoboxBlueTitle }</span> { secondInfoboxPurpleTitle }</h2>
      <p className="infobox__description" dangerouslySetInnerHTML={ { __html: text } } />

      <div className="infobox__form__wrapper">
        <form className={ isLoading ? 'infobox__form infobox__form--is-loading' : 'infobox__form' }
              onSubmit={ ( e ) => handleSubmit( e ) }>
          <input className="infobox__form__field"
                 type="text"
                 value={ emailValue }
                 placeholder={ InputPlaceholder }
                 disabled={ success }
                 onChange={ ( e ) => { setEmailValue( e.currentTarget.value ); setError( false ) } }
                 onFocus={ () => setError( false ) } />
          <button className="infobox__form__btn"
                  type="submit"
                  aria-label="Subscribe"
                  disabled={ success }>
            <ArrowRegularIcon className="infobox__form__btn__icon" />
          </button>
          <InputLoader isLoading={ isLoading } />
        </form>
        { error ? <span className="infobox__form__error">{ errorMsg }</span> : false }
      </div>
    </div>
  );
});

FooterInfoboxRight.defaultProps = {
  data: {}
};

FooterInfoboxRight.propTypes = {
  data: PropTypes.object.isRequired
};

export default FooterInfoboxRight;