import React, { memo, useContext, useState, useEffect } from 'react';
import { useStaticQuery, graphql }                      from 'gatsby';

// import context
import { GlobalStateContext } from '../../context/GlobalContextProvider';

// import components
import UserIconCircled from '../elements/icons/UserIconCircled';

const ProfileDeletedMsg = memo( () => {
  const data = useStaticQuery( graphql`
    {
      allApiPoints {
        nodes {
          account {
            delete_account_msg_title
            delete_account_msg_text
          }
        }
      }
    }
  `);

  // define the component context
  const state = useContext( GlobalStateContext );
  const { defaultLang } = state;

  // define the component state
  const [texts, setTexts] = useState({
    accountTitle: '',
    accountMessage: ''
  });

  const { accountTitle, accountMessage } = texts;

  /**
   * Get texts from the backend and processing changing language
   */
  useEffect( () => {
    // get loaded data from graphql
    data.allApiPoints.nodes.forEach( node => {
      const { account } = node;
      let orderNumber;

      switch ( defaultLang ) {
        case 'en':
          orderNumber = 1;
          break;

        default:
          orderNumber = 0;
      }

      const current = account[orderNumber];
      const { delete_account_msg_title, delete_account_msg_text } = current;

      setTexts( t => ({
        ...t,
        accountTitle: delete_account_msg_title,
        accountMessage: delete_account_msg_text
      }));
    });
  }, [data, defaultLang] );

  return (
    <>
      <UserIconCircled className="overlay__icon" />
      <h4 className="overlay__title">{ accountTitle }</h4>
      <span className="overlay__helper-text overlay__helper-text--center">{ accountMessage }</span>
    </>
  );
});

export default ProfileDeletedMsg;