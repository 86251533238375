import React, { memo } from 'react';
import PropTypes       from 'prop-types';

const ArrowRegularIcon = memo( ( { className, color } ) => {
  return (
    <svg className={ className }
         xmlns="http://www.w3.org/2000/svg"
         width="15.754"
         height="15.584"
         viewBox="0 0 15.754 15.584">
      <path d="M18,24.755l6.378-6.378L18,12"
            transform="translate(-9.624 -10.586)"
            fill="none"
            stroke={ color }
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2" />
      <path d="M12,18H24.755"
            transform="translate(-11 -10.208)"
            fill="none"
            stroke={ color }
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2" />
    </svg>
  )
});

ArrowRegularIcon.defaultProps = {
  className: '',
  color: '#3e2271'
};

ArrowRegularIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
};

export default ArrowRegularIcon;