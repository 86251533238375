import React, { memo } from 'react';
import PropTypes       from 'prop-types';

// import styles
import { InputLoaderElement } from './styles';

const InputLoader = memo( ( { isLoading } ) => {
  if ( isLoading ) {
    return (
      <InputLoaderElement>
        <div className="item item--1" />
        <div className="item item--2" />
        <div className="item item--3" />
      </InputLoaderElement>
    );
  } else return false;
});

InputLoader.defaultProps = {
  isLoading: false
};

InputLoader.propTypes = {
  isLoading: PropTypes.bool
};

export default InputLoader;