import React, { memo, useContext, useState, useEffect } from 'react';
import { useStaticQuery, graphql }                      from 'gatsby';

// import constants
import { AUTH_URL, CHANGE_OVERLAY, CHANGE_LOGIN_STATUS } from '../../../constants/index';

// import context
import { GlobalDispatchContext, GlobalStateContext } from '../../../context/GlobalContextProvider';

// import helpers
import { postData, useWindowSize, useGetCookie } from '../../../helpers/index';

// import utils
import { getUser, getRefreshToken, setAccessToken, logout } from '../../../utils/Auth';

// import styles
import { AccountElement } from './styles/index';

// import components
import LogoutIcon  from '../icons/LogoutIcon';
import EditIcon    from '../icons/EditIcon';
import AccountUser from './AccountUser';

const Account = memo( () => {
  const data = useStaticQuery( graphql`
    {
      allApiPoints {
        nodes {
          account {
            account_detail_text
            delete_link
            delete_text
            edit_text
            fields {
              field
              placeholder
              type
            }
            logout_text
            delete_account_text
            delete_account_yes
            delete_account_no
          }
        }
      }
    }
  `);

  // define the component context
  const state    = useContext( GlobalStateContext );
  const dispatch = useContext( GlobalDispatchContext );
  const { defaultLang } = state;

  // define the component state
  const [deleteAccount, setDeleteAccount] = useState( false );
  const [texts, setTexts] = useState({
    accountLogout: '',
    accountDetails: '',
    accountEditText: '',
    accountDeleteText: '',
    accountDeleteLink: '',
    accountDeleteQuestion: '',
    accountDeleteYes: '',
    accountDeleteNo: '',
    accountFields: []
  });

  const {
    accountLogout, accountDetails, accountEditText, accountDeleteQuestion, accountDeleteText,
    accountDeleteLink, accountFields, accountDeleteYes, accountDeleteNo
  } = texts;

  // define the component custom hooks
  const windowSize = useWindowSize();
  const { width }  = windowSize;

  // get session from cookie
  const session = useGetCookie( 'MMBlogSession' );

  // get user data
  const user = getUser( session );
  const { email, username, firstName, lastName, companyName } = user;

  /**
   * Get texts from the backend and processing changing language
   */
  useEffect( () => {
    // get loaded data from graphql
    data.allApiPoints.nodes.forEach( node => {
      const { account } = node;
      let orderNumber;

      switch ( defaultLang ) {
        case 'en':
          orderNumber = 1;
          break;

        default:
          orderNumber = 0;
      }

      const current = account[orderNumber];
      const {
        hello_text, logout_text, account_detail_text, edit_text, delete_text,
        delete_link, fields, delete_account_text, delete_account_yes, delete_account_no
      } = current;

      setTexts( t => ({
        ...t,
        accountHello: hello_text,
        accountLogout: logout_text,
        accountDetails: account_detail_text,
        accountEditText: edit_text,
        accountDeleteText: delete_text,
        accountDeleteLink: delete_link,
        accountDeleteQuestion: delete_account_text,
        accountDeleteYes: delete_account_yes,
        accountDeleteNo: delete_account_no,
        accountFields: fields
      }));
    });
  }, [data, defaultLang] );

  /**
   * Process login action
   */
  const handleLogout = () => {
    const data = {
      refresh: getRefreshToken( session )
    };

    postData( AUTH_URL + 'token/refresh', 'POST', data, false )
      .then( data => {
        const response = data[0];
        const { status } = response;

        // if the user successfully refresh an Access token
        if ( status === 200 ) {
          const resJson = data[1];
          const { access } = resJson;

          setAccessToken( access, session );

          const logoutData = {
            refresh: getRefreshToken( session )
          };

          const authorizationData = {
            type: 'Bearer',
            token: access
          };

          postData( AUTH_URL + 'logout/', 'POST', logoutData, true, authorizationData )
            .then( data => {
              const response = data[0];
              const { status } = response;

              if ( status === 204 ) {
                logout();
                dispatch( { type: CHANGE_LOGIN_STATUS, payload: false } );
                dispatch( { type: CHANGE_OVERLAY, payload: 'logout' } );
              }
            });
        }
      });
  };

  /**
   * Request to the server after the user confirmation
   */
  const handleDelete = () => {
    const data = {
      refresh: getRefreshToken( session )
    };

    postData( AUTH_URL + 'token/refresh', 'POST', data, false )
      .then( data => {
        const response = data[0];
        const { status } = response;

        // if the user successfully refresh an Access token
        if ( status === 200 ) {
          const resJson = data[1];
          const { access } = resJson;

          setAccessToken( access, session );

          const authorizationData = {
            type: 'Bearer',
            token: access
          };

          postData( AUTH_URL + 'delete/', 'DELETE', {}, true, authorizationData )
            .then( data => {
              const response = data[0];
              const { status } = response;

              // if the profile successfully deleted
              if ( status === 200 ) {
                logout();
                dispatch( { type: CHANGE_LOGIN_STATUS, payload: false } );
                dispatch( { type: CHANGE_OVERLAY, payload: 'deleted' } );
              }
            });
        }
      });
  };

  return (
    <AccountElement>
      <div className="account__container">
        <div className="account__top">
          <AccountUser />
          <span className="logout"
                role="presentation"
                onClick={ handleLogout }>
          { accountLogout }
          <LogoutIcon className="logout__icon" />
        </span>
        </div>

        <div className="account__fields">
          <div className="account__details">
            <h4 className="account__details__title">{ accountDetails }</h4>
            <span className="account__details__edit"
                  role="presentation"
                  onClick={ () => { dispatch( { type: CHANGE_OVERLAY, payload: 'accountChange' } ); } }>{ accountEditText }</span>
            { width < 992 ? <EditIcon className="account__details__edit__icon" /> : false }
          </div>

          { accountFields.map( fieldItem => {
            const { field, placeholder } = fieldItem;
            let value;

            switch ( field ) {
              case 'first_name':
                value = firstName;
                break;

              case 'last_name':
                value = lastName;
                break;

              case 'username':
                value = username;
                break;

              case 'company_name':
                value = companyName;
                break;

              default:
                value = email;
            }

            return (
              <div className="field-group" key={ field }>
                <span className="field-label">{ placeholder }</span>
                <span className="field">{ value }</span>
              </div>
            );
          }) }
        </div>

        <div className="account__bottom">
          <p className="delete">{ accountDeleteText } <span className="delete__link"
                                                            role="presentation"
                                                            onClick={ () => setDeleteAccount( true ) }>{ accountDeleteLink }</span></p>
        </div>
      </div>

      <div className={ deleteAccount ? 'account__delete is-shown' : 'account__delete' }>
        <div className="account__delete__container">
          <p className="account__delete__text">{ accountDeleteQuestion }</p>
          <div className="account__delete__options">
            <span className="account__delete__option"
                  role="presentation"
                  onClick={ handleDelete }>{ accountDeleteYes }</span>
            <span className="account__delete__option"
                  role="presentation"
                  onClick={ () => setDeleteAccount( false ) }>{ accountDeleteNo }</span>
          </div>
        </div>
      </div>
    </AccountElement>
  )
});

export default Account;