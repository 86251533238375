import React, { memo } from 'react';
import PropTypes       from 'prop-types';

const CheckIcon = memo( ( { className, color } ) => {
  return (
    <svg className={ className }
         xmlns="http://www.w3.org/2000/svg"
         width="24px"
         height="24px"
         viewBox="0 0 24 24"
         fill={ color }>
      <path d="M0 0h24v24H0z" fill="none"/>
      <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/>
    </svg>
  );
});

CheckIcon.defaultProps = {
  className: '',
  color: '#1fe21f'
};

CheckIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
};

export default CheckIcon;