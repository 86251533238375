import React, { memo, useContext, useState, useEffect } from 'react';
import { useStaticQuery, graphql }                      from 'gatsby';

// import context
import { GlobalStateContext } from '../../context/GlobalContextProvider';

// import components
import UserIconCircled from '../elements/icons/UserIconCircled';

const ForgotPasswordThankMsg = memo( () => {
  const data = useStaticQuery( graphql`
    {
      allApiPoints {
        nodes {
          forgot {
            title
            thank_msg
          }
        }
      }
    }
  `);

  // define the component context
  const state = useContext( GlobalStateContext );
  const { defaultLang } = state;

  // define the default component state
  const [texts, setTexts] = useState({
    forgotTitle: '',
    forgotMsg: ''
  });

  const { forgotTitle, forgotMsg } = texts;

  /**
   * Get texts from the backend and processing changing language
   */
  useEffect( () => {
    // get loaded data from graphql
    data.allApiPoints.nodes.forEach( node => {
      const { forgot } = node;
      let orderNumber;

      switch ( defaultLang ) {
        case 'en':
          orderNumber = 1;
          break;

        default:
          orderNumber = 0;
      }

      const current = forgot[orderNumber];
      const { title, thank_msg } = current;

      setTexts( t => ({
        ...t,
        forgotTitle: title,
        forgotMsg: thank_msg
      }));
    });
  }, [data, defaultLang] );

  return (
    <>
      <UserIconCircled className="overlay__icon" />
      <h4 className="overlay__title">{ forgotTitle }</h4>
      <span className="overlay__helper-text overlay__helper-text--center" dangerouslySetInnerHTML={ { __html: forgotMsg } } />
    </>
  )
});

export default ForgotPasswordThankMsg;