import styled from 'styled-components';

export const AccountElement = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .account {
    &__container {
      width: 748px;
    }
    
    &__top,
    &__fields,
    &__bottom {
      position: relative;
      opacity: 0;
    }
  
    &__top {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    
    &__fields {
      margin-top: 25px;
    }
    
    &__details {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 35px;
      border-bottom: 1px solid var(--white);
      padding-bottom: 10px;
      
      &__title {
        margin: 0;
        font-size: 16px;
        font-weight: 700;
        line-height: 29px;
        letter-spacing: 1.25px;
        text-transform: uppercase;
        color: var(--white);
      }
      
      &__edit {
        font-size: 16px;
        font-weight: 700;
        line-height: 29px;
        letter-spacing: 1.25px;
        color: var(--theme-blue);
        
        &:hover {
          cursor: pointer;
        }
      }
    }
    
    &__bottom {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 10px;
    }
    
    &__error {
      display: block;
      margin: 0 0 20px 0;
      text-align: center;
    }
    
    &__edit {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      
      &__msg {
        color: var(--white);
      }
    }
    
    &__delete {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      opacity: 0;
      visibility: hidden;
      background-color: rgba(var(--theme-deep-purple--rbg), .9);
      transition: .3s all ease-in-out;
    
      &.is-shown {
        opacity: 1;
        visibility: visible;
        
      }
      
      &__text {
        letter-spacing: 1.2px;
        color: var(--white);
      }
      
      &__options {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      
      &__option {
        margin: 0 25px;
        letter-spacing: 1.2px;
        color: var(--theme-blue);
        
        &:hover {
          cursor: pointer;
        }
      }
    }
    
    &__back {
      display: flex;
      align-items: center;
      
      &:hover {
        cursor: pointer;
        
        .account__back__icon {
          transform: rotate(1.5turn);
        }
      }
      
      &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        margin-right: 10px;
        border: 2px solid var(--theme-blue);
        border-radius: 50%;
        transform: rotate(.5turn);
        transition: .3s all ease-in-out;
      }
      
      &__text {
        font-weight: 500;
        letter-spacing: 1.2px;
        color: var(--white);
      }
    }
  }
  
  .user-data {
    display: flex;
    align-items: center;
  }
  
  .avatar {
    margin-right: 33px;
    border-radius: 50%;
  }
  
  .user-name {
    font-size: 18px;
    line-height: 21px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1.25px;
    color: var(--white);
  }
  
  .logout {
    display: flex;
    align-items: center;
    color: var(--white);
    transition: .3s all ease-in-out;
    
    &:hover {
      color: var(--theme-blue);
      cursor: pointer;
      
      svg {
        path {
          &:nth-child(2) {
            fill: var(--theme-blue);
          }
        }
      }
    }
  
    &__icon {
      margin-left: 5px;
      
      path {
        transition: .3s all ease-in-out;
      }
    }
  }
  
  .field {
    display: flex;
    align-items: center;
    width: 560px;
    height: 70px;
    border: none;
    border-radius: 38px;
    padding-left: 50px;
    color: var(--theme-dark-gray);
    background-color: var(--white);
    
    &--is-editable {
      border: 2px solid var(--white);
      padding: 0 35px;
      background: transparent;
      color: var(--white);
      
      &::placeholder {
        text-transform: uppercase;
        color: var(--theme-dark-gray);
      }
    }
    
    &--is-password {
      text-transform: initial;
      
      &::placeholder {
        text-transform: uppercase;
        color: var(--theme-dark-gray);
      }
    }
    
    &:focus {
      outline: none;
    }
    
    &::placeholder {
      color: var(--theme-dark-gray);
    }
  
    &-group {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 20px;
    }
    
    &-label {
      display: block;
      max-width: 180px;
      font-size: 16px;
      text-transform: uppercase;
      color: var(--white);
    }
    
    &__icon {
      --dimensions: 28px;
    
      position: absolute;
      top: calc(50% - (var(--dimensions) / 2));
      right: 25px;
      width: var(--dimensions);
      height: var(--dimensions);
      
      &:hover {
        cursor: pointer;
      }
    }
  }
  
  .delete {
    margin: 0 0 0 auto;
    font-size: 16px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 1.25px;
    color: var(--white);
  
    &__link {
      color: var(--theme-blue);
      
      &:hover {
        cursor: pointer;
      }
    }
  }
  
  /*-------------------------
  # Media Queries
  --------------------------*/
  @media (max-width: 1440px) {
    .account {
      &__fields {
        margin-top: 10px;
      }
      
      &__details {
        margin-bottom: 15px;
        padding-bottom: 10px;
      }
      
      &__error {
        margin-bottom: 5px;
      }
      
      &__bottom {
        margin-bottom: 5px;
      }
    }
    
    .avatar {
      --dimensions: 80px;
    
      width: var(--dimensions);
      height: var(--dimensions);;
    }
    
    .field-group {
      margin-bottom: 15px;
    }
  }
  
  @media (max-width: 1280px) {
    .field {
      height: 65px;
      
      &-group {
        margin-bottom: 10px;
      }
    }
    
    .avatar {
      --dimensions: 70px;
    }
    
    .account {
      &__fields {
        margin-top: 5px;
      }
    }
  }
  
  @media (max-width: 1199px) {
    .field {
      height: 60px;
    }
    
    .avatar {
      --dimensions: 50px;
    }
    
    .account {
      &__bottom {
        margin-bottom: 0;
      }
    }
  }
  
  @media (max-width: 1024px) {
    justify-content: flex-start;
    padding-top: 10px;
  }
  
  @media (max-width: 991px) {
    padding: 30px;
    
    &.account-change {
      .account {
        &__top {
          justify-content: center;
        }
        
        &__fields {
          margin-top: 0;
        }
      }
    }
  
    .account {
      &__container {
        width: 100%;
      }
      
      &__top,
      &__fields,
      &__bottom {
        opacity: 1;
      }
      
      &__top {
        align-items: flex-start; 
      }
      
      &__details {
        margin-bottom: 25px;
        padding-bottom: 20px;
      
        &__title {
          display: none;
        }
        
        &__edit {
          margin-left: auto;
          line-height: 19px;
          text-transform: uppercase;
          letter-spacing: 1.2px;
          
          &__icon {
            width: 24px;
            height: 24px;
            margin-left: 6px;
          }
        }
      }
      
      &__fields {
        margin-top: -25px;
      }
      
      &__bottom {
        margin-bottom: 20px;
      }
    }
    
    .avatar {
      --dimensions: 66px;
      
      margin-right: 0;
    }
    
    .user-name {
      display: none;
    }
    
    .logout {
      line-height: 19px;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 1.2px;
    }
    
    .field-label {
      max-width: 130px;
    }
  }
  
  @media (max-width: 767px) {
    .account {
      &__container {
        padding-bottom: 30px;
      }
      
      &__bottom {
        margin-top: 15px;
      }
      
      &__delete {
        position: fixed;
        padding: 30px;
        text-align: center;
      }
    }
  
    .field {
      width: 100%;
      height: 56px;
    
      &-group {
        flex-direction: column;
        align-items: flex-start;
      }
      
      &-label {
        max-width: 100%;
        margin-bottom: 5px;
        text-transform: initial;
      }
      
      &__icon {
        top: 50px;
        right: 15px;
      }
    }
    
    .delete {
      margin: 0 auto;
      line-height: 33px;
      
      &.password {
        margin: 0 0 0 auto;
      }
    }
  }
  
  @media (max-width: 480px) {
    padding: 0;
  }
`;