import React, { memo, useContext, useState, useEffect } from 'react';
import { useStaticQuery, graphql }                      from 'gatsby';

// import constants
import { AUTH_URL, CHANGE_OVERLAY } from '../../../constants/index';

// import context
import { GlobalDispatchContext, GlobalStateContext } from '../../../context/GlobalContextProvider';

// import utils
import { getRefreshToken, getUser, setAccessToken, setUser } from '../../../utils/Auth';

// import helpers
import { postData, useGetCookie } from '../../../helpers/index';

// import styles
import { AccountElement } from './styles/index';

// import components
import AccountBack from './AccountBack';
import Button      from '../Button';
import AccountUser from "./AccountUser";

const AccountChange = memo( () => {
  const data = useStaticQuery( graphql`
    {
      allApiPoints {
        nodes {
          account {
            edit_account_detail_text
            fields {
              field
              placeholder
              type
            }
            change_password_text
            change_password_link
            button_text
            back_text
            edit_error_text
          }
        }
      }
    }
  `);

  // define the component context
  const state    = useContext( GlobalStateContext );
  const dispatch = useContext( GlobalDispatchContext );
  const { defaultLang } = state;

  // get session from cookie
  const session = useGetCookie( 'MMBlogSession' );

  // get user data
  const user = getUser( session );
  const { email, username, firstName, lastName, companyName } = user;

  // define the default component state
  const [isBtnProcess, setIsBtnProcess] = useState( false );
  const [isError, setIsError] = useState( false );
  const [isErrorResponse, setIsErrorResponse] = useState({
    status: false,
    text: ''
  });
  const [formData, setFormData] = useState({
    accountFirstName: firstName,
    accountLastName: lastName,
    accountNickname: username,
    accountCompanyName: companyName
  });
  const [texts, setTexts] = useState({
    accountEditDetails: '',
    accountChangePasswordText: '',
    accountChangePasswordLink: '',
    accountButton: '',
    accountBack: '',
    accountError: '',
    accountFields: []
  });

  const { status: errorStatus, text: errorText } = isErrorResponse;
  const { accountFirstName, accountLastName, accountNickname, accountCompanyName } = formData;
  const {
    accountEditDetails, accountChangePasswordText, accountChangePasswordLink,
    accountButton, accountFields, accountBack, accountError
  } = texts;

  /**
   * Get texts from the backend and processing changing language
   */
  useEffect( () => {
    // get loaded data from graphql
    data.allApiPoints.nodes.forEach( node => {
      const { account } = node;
      let orderNumber;

      switch ( defaultLang ) {
        case 'en':
          orderNumber = 1;
          break;

        default:
          orderNumber = 0;
      }

      const current = account[orderNumber];
      const {
        edit_account_detail_text, change_password_text, change_password_link, button_text, fields, back_text,
        edit_error_text
      } = current;

      setTexts( t => ({
        ...t,
        accountEditDetails: edit_account_detail_text,
        accountChangePasswordText: change_password_text,
        accountChangePasswordLink: change_password_link,
        accountButton: button_text,
        accountBack: back_text,
        accountError: edit_error_text,
        accountFields: fields
      }));
    });
  }, [data, defaultLang] );

  /**
   * Processing change of the field
   *
   * @param e | Event
   * @param type | string
   */
  const handleChange = ( e, type ) => {
    switch ( type ) {
      case 'first_name':
        setFormData( { ...formData, accountFirstName: e.currentTarget.value } );
        break;

      case 'last_name':
        setFormData( { ...formData, accountLastName: e.currentTarget.value } );
        break;

      case 'username':
        setFormData( { ...formData, accountNickname: e.currentTarget.value } );
        break;

      case 'company_name':
        setFormData( { ...formData, accountCompanyName: e.currentTarget.value } );
        break;

      default:
        return false;
    }
  };

  /**
   * Processing an edit action.
   * Validating the data, passing the data to the backend or showing an error
   *
   * @param e | Event
   */
  const handleSubmit = ( e ) => {
    e.preventDefault();

    const { accountFirstName, accountLastName, accountNickname, accountCompanyName } = formData;

    if (
      accountFirstName !== '' &&
      accountLastName !== '' &&
      accountNickname !== '' &&
      accountCompanyName !== ''
    ) {
      const data = {
        refresh: getRefreshToken( session )
      };

      setIsBtnProcess( true );

      postData( AUTH_URL + 'token/refresh', 'POST', data, false )
        .then( data => {
          const response = data[0];
          const { status } = response;

          // if the user successfully refresh an Access token
          if ( status === 200 ) {
            const resJson = data[1];
            const { access } = resJson;

            setAccessToken( access, session );

            const userData = {
              username: accountNickname,
              first_name: accountFirstName,
              last_name: accountLastName,
              company_name: accountCompanyName
            };

            const authorizationData = {
              type: 'Bearer',
              token: access
            };

            postData( AUTH_URL + 'change-profile/', 'PUT', userData, true, authorizationData )
              .then( data => {
                const response = data[0];
                const { status } = response;

                setIsBtnProcess( false );

                // if an error
                if ( status === 400 ) {
                  const resJson = data[1];

                  if ( resJson.first_name !== undefined ) {
                    setIsErrorResponse( {
                      status: true,
                      text: resJson.first_name[0]
                    });
                  }

                  if ( resJson.last_name !== undefined ) {
                    setIsErrorResponse( {
                      status: true,
                      text: resJson.last_name[0]
                    });
                  }

                  if ( resJson.username !== undefined ) {
                    setIsErrorResponse( {
                      status: true,
                      text: resJson.username[0]
                    });
                  }

                  if ( resJson.company_name !== undefined ) {
                    setIsErrorResponse( {
                      status: true,
                      text: resJson.company_name[0]
                    });
                  }
                }

                // if success
                if ( status === 201 ) {
                  setUser(
                    {
                      email,
                      username: accountNickname,
                      firstName: accountFirstName,
                      lastName: accountLastName,
                      companyName: accountCompanyName
                    },
                    session
                  );

                  dispatch( { type: CHANGE_OVERLAY, payload: 'account' } );
                }
              });
          }
        });
    } else {
      setIsError( true );
    }
  };

  /**
   * Hide an error
   */
  const handleHideError = () => {
    if ( isError ) setIsError( false );
    if ( errorStatus ) setIsErrorResponse( { status: false, text: '' } );
  };

  return (
    <AccountElement className="account-change">
      <div className="account__container">
        <div className="account__top">
          <AccountUser />
        </div>

        <div className="account__fields">
          <div className="account__details">
            <h4 className="account__details__title">{ accountEditDetails }</h4>
          </div>

          <form className="account__edit"
                onSubmit={ ( e ) => handleSubmit( e ) }>
            { isError ? <span className="overlay__error account__error">{ accountError }</span> : false }
            { errorStatus ? <span className="overlay__error account__error">{ errorText }</span> : false }

            { accountFields.map( fieldItem => {
              const { type, field, placeholder } = fieldItem;
              let value;

              switch ( field ) {
                case 'first_name':
                  value = accountFirstName;
                  break;

                case 'last_name':
                  value = accountLastName;
                  break;

                case 'username':
                  value = accountNickname;
                  break;

                case 'company_name':
                  value = accountCompanyName;
                  break;

                default:
                  value = email;
              }

              return (
                <div className="field-group" key={ field }>
                  <label className="field-label"
                         htmlFor={ field }>{ placeholder }</label>
                  <input className="field field--is-editable"
                         type={ type }
                         value={ value }
                         placeholder={ placeholder }
                         disabled={ type === 'email' }
                         onChange={ ( e ) => handleChange( e, field )  }
                         onFocus={ handleHideError }/>
                </div>
              );
            }) }

            <div className="account__bottom">
              <AccountBack text={ accountBack } overlay="account" />
              <p className="delete password">{ accountChangePasswordText } <span className="delete__link"
                                                                        role="presentation"
                                                                        onClick={ () => { dispatch( { type: CHANGE_OVERLAY, payload: 'accountChangePassword' } ); } }>{ accountChangePasswordLink }</span></p>
            </div>

            <Button className={ `overlay__login__btn${ isBtnProcess ? ' processing' : '' }` }
                    text={ accountButton }
                    disabled={ isBtnProcess }
                    method="submit" />
          </form>
        </div>
      </div>
    </AccountElement>
  );
});

export default AccountChange;