import React, { memo, useContext } from 'react';
import PropTypes                   from 'prop-types';

// import constants
import { CHANGE_OVERLAY, SHOW_OVERLAY } from '../../../constants';

// import context
import { GlobalDispatchContext } from '../../../context/GlobalContextProvider';

// import helpers
import { useWindowSize } from '../../../helpers';

// import components
import PhoneIcon    from '../icons/PhoneIcon';
import EnvelopeIcon from '../icons/EnvelopeIcon';
import PinIcon      from '../icons/PinIcon';
import Button       from '../Button';

const FooterInfoboxLeft = memo( ( { data } ) => {
  const { firstInfoboxPurpleTitle, firstInfoboxBlueTitle, phone, email, address, contactText } = data;
  const color = '#431E75';
  const { width } = useWindowSize();

  // define the component context
  const dispatch = useContext( GlobalDispatchContext );

  /**
   * Set the contact overlay type
   */
  const handleOverlayType = () => {
    dispatch( { type: CHANGE_OVERLAY, payload: 'contact' } );
    dispatch( { type: SHOW_OVERLAY, payload: true } )
  };

  return (
    <div className="infobox">
      <h2 className="infobox__title">{ firstInfoboxPurpleTitle } <span className="colored">{ firstInfoboxBlueTitle }</span></h2>

      <div className="contacts">
        <div className="contacts__item">
          <span className="contacts__item__icon">
            <PhoneIcon className="contacts__item__icon__item" />
          </span>
          <a className="contacts__item__text"
             title={ `Phone us ${ phone }` }
             href={ `tel:${ phone }` }>{ phone }</a>
        </div>

        <div className="contacts__item">
          <span className="contacts__item__icon">
            <EnvelopeIcon className="contacts__item__icon__item" />
          </span>
          <a className="contacts__item__text"
             href={ `mailto:${ email }` }
             target="_blank"
             title={ `Email us ${ email }` }
             rel="noreferrer nofollow">{ email }</a>
        </div>

        <div className="contacts__item">
          <span className="contacts__item__icon">
            <PinIcon className="contacts__item__icon__item" />
          </span>
          <a className="contacts__item__text contacts__item__text--long"
             href="https://www.google.com/maps/place/Wilstrasse+17,+4557+Horriwil,+Switzerland/@47.183042,7.617857,17z/data=!4m5!3m4!1s0x478e29f6dcb51823:0x71559ca7792a0e2f!8m2!3d47.1830417!4d7.617857?hl=de"
             target="_blank"
             title={ `Visit us` }
             rel="noreferrer nofollow">{ address }</a>
        </div>

        <div className="contacts__item">
          <span className="contacts__item__icon">
              <svg className="contacts__item__icon__item"
                   xmlns="http://www.w3.org/2000/svg"
                   width="20.276"
                   height="19.365"
                   viewBox="0 0 20.276 19.365">
                  <g transform="translate(0 0)">
                      <g transform="translate(0.283 6.002)">
                          <g>
                              <path d="M939.118,772.363v7.167a.321.321,0,0,1-.321.321h-3.7a.321.321,0,0,1-.322-.321v-6.665c0-1.754-.626-2.952-2.2-2.952a2.374,2.374,0,0,0-2.226,1.587,2.962,2.962,0,0,0-.144,1.058v6.972a.321.321,0,0,1-.321.321h-3.7a.32.32,0,0,1-.321-.321c.009-1.775.046-10.387.008-12.412a.319.319,0,0,1,.321-.324h3.691a.321.321,0,0,1,.321.321v1.529c-.008.015-.021.029-.029.043h.029v-.043a4.311,4.311,0,0,1,3.913-2.157c2.856,0,5,1.866,5,5.875Zm-19.672,7.488h3.7a.321.321,0,0,0,.32-.321V767.115a.321.321,0,0,0-.32-.321h-3.7a.321.321,0,0,0-.321.321v12.415A.321.321,0,0,0,919.446,779.851Z"
                                    transform="translate(-919.126 -766.488)"
                                    fill="#431E75" />
                          </g>
                      </g>
                      <g>
                          <ellipse cx="2.324" cy="2.324" rx="2.324" ry="2.324" fill={ color } />
                      </g>
                  </g>
              </svg>
          </span>
          <a className="contacts__item__text contacts__item__text--linkedin"
             href="https://www.linkedin.com/company/marketing-monkeys/"
             target="_blank"
             rel="noreferrer nofollow">{ width > 480 ? 'linkedin.com/company/marketing-monkeys/' : 'linkedin.com/marketing-monkeys/' }</a>
        </div>
      </div>

      <Button className="infobox__btn"
              text={ contactText }
              onClick={ handleOverlayType } />
    </div>
  );
});

FooterInfoboxLeft.defaultProps = {
  data: {}
};

FooterInfoboxLeft.propTypes = {
  data: PropTypes.object.isRequired
};

export default FooterInfoboxLeft;