import React, { memo } from 'react';
import PropTypes       from 'prop-types';

const BrandIconCollapsed = memo( ( { className, color } ) => {
  return (
    <svg className={ className }
         xmlns="http://www.w3.org/2000/svg"
         width="27.284"
         height="27.284"
         viewBox="0 0 27.284 27.284">
      <path d="M381.61,288.971a5.157,5.157,0,0,0-5.168,5.149v4.845h2.85V294.12a2.311,2.311,0,0,1,2.318-2.3h19.266v19.264a2.311,2.311,0,0,1-2.3,2.32H379.292v-4.2h-2.85v7.049h22.135a5.171,5.171,0,0,0,5.149-5.17V288.971Z"
            transform="translate(-376.442 -288.971)"
            fill={ color } />
      <path d="M381.172,292.664h10.957l-5.981,10.366h5.772v2.886l-11.265-.017,5.945-10.264h-5.428Z"
            transform="translate(-372.65 -285.647)"
            fill={ color } />
    </svg>
  )
});

BrandIconCollapsed.defaultProps = {
  className: '',
  color: '#fff'
};

BrandIconCollapsed.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
};

export default BrandIconCollapsed;