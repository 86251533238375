import React, { memo, useContext, useState, useEffect } from 'react';
import { useStaticQuery, graphql, Link, navigate }      from 'gatsby';
import PropTypes                                        from 'prop-types';
import Highlighter                                      from 'react-highlight-words';

// import constants
import { CHANGE_OVERLAY, OPEN_MOBILE_MENU } from '../../../constants';

// import context
import { GlobalDispatchContext, GlobalStateContext } from '../../../context/GlobalContextProvider';

// import styles
import { SearchResultsElement } from '../../../styles/elements/Search/SearchResults';

// import components
import ArrowIcon from '../icons/ArrowIcon';

const SearchResults = memo( ( { isOpened, posts, value } ) => {
  const data = useStaticQuery( graphql`
    {
      allApiPoints {
        nodes {
          search_texts {
            you_see_text
            from_text
            articles_text
            show_all
            no_results
          }
        }
      }
    }
  `);

  // define the app context
  const dispatch = useContext( GlobalDispatchContext );
  const state    = useContext( GlobalStateContext );
  const { defaultLang } = state;

  // define the default component state
  const [texts, setTexts] = useState({
    youSeeText: '',
    fromText: '',
    articlesText: '',
    showAllText: '',
    noResultsText: ''
  });

  const { youSeeText, fromText, articlesText, showAllText, noResultsText } = texts;
  const postLen = posts.length > 0 ? posts[1].posts.length : 0;

  /**
   * Get texts from the backend and processing changing language
   */
  useEffect( () => {
    // get loaded data from graphql
    data.allApiPoints.nodes.forEach( node => {
      const { search_texts } = node;
      let orderNumber;

      switch ( defaultLang ) {
        case 'en':
          orderNumber = 1;
          break;

        default:
          orderNumber = 0;
      }

      const current = search_texts[orderNumber];
      const { you_see_text, from_text, articles_text, show_all, no_results } = current;

      setTexts({
        youSeeText: you_see_text,
        fromText: from_text,
        articlesText: articles_text,
        showAllText: show_all,
        noResultsText: no_results
      });
    });
  }, [data, defaultLang] );

  /**
   * Processing a click on an article from the search list
   */
  const handleClick = () => {
    const header = document.getElementsByTagName( 'header' )[0];
    const main   = document.getElementsByTagName( 'main' )[0];
    const footer = document.getElementsByTagName( 'footer' )[0];

    header.classList.remove( 'is-shifted' );
    main.classList.remove( 'is-shifted' );
    footer.classList.remove( 'is-shifted' );
    dispatch( { type: OPEN_MOBILE_MENU, payload: false } );
    dispatch( { type: CHANGE_OVERLAY, payload: 'closeSearch' } );
  };

  /**
   * Processing a click on the Show all button
   */
  const handleClickShowAll = () => {
    handleClick();
    navigate( defaultLang === 'de' ? '/search' : '/en/search', { state: { searchResults: posts, searchValue: value } } );
  };

  if ( postLen > 0 ) {
    return (
      <SearchResultsElement className={ isOpened ? 'is-opened' : 'results' }>
        { posts[1].posts.map( ( post, index ) => {
          if ( index < 3 ) {
            const { title, short_description, slug } = post;
            const postUrl = defaultLang === 'de' ? `/${ slug }` : `/en/${ slug }`;

            return (
              <Link className="item" to={ postUrl }
                    key={ slug }
                    onClick={ handleClick }>
                <h4 className="item__title">
                  <Highlighter highlightClassName="item--highlight"
                               searchWords={ [value] }
                               autoEscape={ true }
                               textToHighlight={ title } />
                </h4>
                <p className="item__description">
                  <Highlighter highlightClassName="item--highlight"
                               searchWords={ [value] }
                               autoEscape={ true }
                               textToHighlight={ short_description } />
                </p>
              </Link>
            )
          } else return false;
        })}

        { postLen > 3 ?
          <>
            <p className="amount">
              { youSeeText } <span className="amount__limit">3</span> { fromText } <span className="amount__total">{ postLen }</span> { articlesText }
            </p>
            <span className="amount__all"
                  role="presentation"
                  onClick={ handleClickShowAll }>
              { showAllText } <ArrowIcon className="amount__all__arrow" direction="down" color="#724B9E" />
            </span>
          </>
        : false }
      </SearchResultsElement>
    )
  } else {
    return (
      <SearchResultsElement className={ isOpened ? 'is-opened' : 'results' }>
        <p className="no-results">{ noResultsText } "<span className="no-results__highlight">{ value }</span>"</p>
      </SearchResultsElement>
    )
  }
});

SearchResults.defaultProps = {
  isOpened: false,
  posts: [],
  value: ''
};

SearchResults.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  posts: PropTypes.array,
  value: PropTypes.string
};

export default SearchResults;