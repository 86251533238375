import styled from 'styled-components';

export const HeaderHamburgerElement = styled.svg`
  &:hover {
    cursor: pointer;
  }
  
  /*-------------------------
  # Media Queries
  --------------------------*/
  @media (max-width: 375px) {
    width: 23px;
    height: 16px;
  }
`;