import React, { memo } from 'react';
import PropTypes       from 'prop-types';

// import styles
import { ArrowElement } from '../../../styles/elements/Arrow';

const ArrowIcon = memo( ( { className, color, direction } ) => {
  return (
    <ArrowElement className={ `${ className } ${ direction }` }
         xmlns="http://www.w3.org/2000/svg"
         width="11.248"
         height="20.496"
         viewBox="0 0 11.248 20.496">
      <path d="M0,17.668,8.834,8.834,0,0"
            transform="translate(9.834 19.082) rotate(180)"
            fill="none"
            stroke={ color }
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2" />
    </ArrowElement>
  )
});

ArrowIcon.defaultProps = {
  className: '',
  color: '#fff',
  direction: 'left'
};

ArrowIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  direction: PropTypes.string.isRequired
};

export default ArrowIcon;