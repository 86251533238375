import React, { memo } from 'react';
import PropTypes       from 'prop-types';

const GlobeIcon = memo( ( { className, color, onClick } ) => {
  /**
   * Processing click action.
   * Passing data to the received function
   */
  const handleClick = () => {
    if ( onClick !== undefined ) onClick();
  };

  return (
    <svg className={ className }
         xmlns="http://www.w3.org/2000/svg"
         width="23.951"
         height="23.951"
         viewBox="0 0 23.951 23.951"
         onClick={ handleClick }>
      <path d="M564.976,81a11.976,11.976,0,1,0,11.976,11.976A11.989,11.989,0,0,0,564.976,81ZM575.8,92.48h-4.529a20.472,20.472,0,0,0-.954-5.938,11.941,11.941,0,0,0,2.157-1.381A10.81,10.81,0,0,1,575.8,92.48ZM565.472,82.187c1.341.282,2.55,1.692,3.4,3.756a10.839,10.839,0,0,1-3.4.715Zm2.713.432A10.82,10.82,0,0,1,571.6,84.4a10.815,10.815,0,0,1-1.68,1.068A9.954,9.954,0,0,0,568.185,82.619Zm-3.847-.4v4.425a10.844,10.844,0,0,1-3.261-.705C561.9,83.952,563.054,82.572,564.338,82.223Zm-4.31,3.249a10.813,10.813,0,0,1-1.68-1.067,10.815,10.815,0,0,1,3.418-1.786A9.951,9.951,0,0,0,560.028,85.472Zm4.31,2.312v4.7h-4.529a19.512,19.512,0,0,1,.877-5.472A11.971,11.971,0,0,0,564.338,87.784Zm0,5.829v4.7a11.973,11.973,0,0,0-3.61.76,19.341,19.341,0,0,1-.915-5.455Zm0,5.832v4.283c-1.258-.341-2.392-1.672-3.211-3.6A10.841,10.841,0,0,1,564.338,99.445Zm-2.572,3.887a10.815,10.815,0,0,1-3.327-1.714,10.818,10.818,0,0,1,1.636-1.022A9.835,9.835,0,0,0,561.766,103.332Zm3.705.432V99.435a10.838,10.838,0,0,1,3.353.694C567.974,102.128,566.786,103.488,565.472,103.764Zm4.4-3.168a10.819,10.819,0,0,1,1.636,1.022,10.816,10.816,0,0,1-3.327,1.714A9.835,9.835,0,0,0,569.875,100.6Zm-4.4-2.295V93.613h4.667a19.34,19.34,0,0,1-.915,5.455A11.97,11.97,0,0,0,565.472,98.3Zm0-5.822V87.792a11.974,11.974,0,0,0,3.794-.784,19.516,19.516,0,0,1,.877,5.472Zm-8-7.319a11.951,11.951,0,0,0,2.157,1.381,20.471,20.471,0,0,0-.954,5.938h-4.529A10.81,10.81,0,0,1,557.472,85.161Zm-3.318,8.453h4.523a20.269,20.269,0,0,0,.993,5.917,11.963,11.963,0,0,0-2.116,1.338A10.813,10.813,0,0,1,554.154,93.613Zm18.243,7.255a11.958,11.958,0,0,0-2.116-1.338,20.266,20.266,0,0,0,.993-5.917H575.8A10.813,10.813,0,0,1,572.4,100.869Z"
            transform="translate(-553 -81)"
            fill={ color } />
    </svg>
  )
});

GlobeIcon.defaultProps = {
  className: '',
  color: '#eaeaea',
  onClick: () => {}
};

GlobeIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func
};

export default GlobeIcon;