import React, { memo } from 'react';
import PropTypes       from 'prop-types';

// import components
import CheckIcon from './icons/CheckIcon';
import CrossIcon from './icons/CrossIcon';

const PasswordHintRule = memo( ( { type, text } ) => {
  return (
    <div className="requirement">
        <span className="requirement__icon">
          { type ?
            <CheckIcon className="requirement__icon__item requirement__icon__item--check" />
          :
            <CrossIcon className="requirement__icon__item requirement__icon__item--cross" color="#de0105" />
          }
        </span>
      <span className="requirement__text" dangerouslySetInnerHTML={ { __html: text } } />
    </div>
  )
});
PasswordHintRule.defaultProps = {
  type: false,
  text: ''
};

PasswordHintRule.propTypes = {
  type: PropTypes.bool,
  text: PropTypes.string
};

export default PasswordHintRule;