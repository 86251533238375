import { useEffect, useState } from 'react';

/**
 * Get window size hook
 *
 * @return object
 */
export const useWindowSize = () => {
  // init state with undefined width/height so server and client render match
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined
  });

  useEffect( () => {
    // Handler to call on window resize
    function handleResize() {
      const { innerWidth, innerHeight } = window;

      // set window width and height
      setWindowSize({
        width: innerWidth,
        height: innerHeight
      });
    }

    // add resize event listener
    window.addEventListener( 'resize', handleResize );

    // call handler right away so state gets updated with initial window size
    handleResize();

    // remove resize event listener on cleanup
    return () => window.removeEventListener( 'resize', handleResize );
  }, [] ); // empty array ensures that effect is only run on mount

  return windowSize;
};

/**
 * Validate email
 *
 * @param email | string
 *
 * @return boolean
 */
export const useEmailValidation = ( email ) => {
  const regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regExp.test( email );
};

/**
 * Validate a german phone number
 *
 * @param phone | string
 *
 * @return boolean
 */
export const useGermanPhoneValidation = ( phone ) => {
  const regExp = /\(?\+\(?49\)?[ ()]?([- ()]?\d[- ()]?){9}$/g;
  return regExp.test( phone );
};

/**
 * Validate a swiss phone number
 *
 * @param phone | string
 *
 * @return boolean
 */
export const useSwissPhoneValidation = ( phone ) => {
  const regExp = /(\b(0041|0)|\B\+41)(\s?\(0\))?(\s)?[1-9]{2}(\s)?[0-9]{3}(\s)?[0-9]{2}(\s)?[0-9]{2}\b/;
  return regExp.test( phone );
};

/**
 * Validate password
 *
 * @param password | string
 *
 * @return boolean
 */
export const usePasswordValidation = ( password ) => {
  const regExp = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/;

  return regExp.test( password );
};

/**
 * Set cookie helper function
 *
 * @param name | string
 * @param value | string
 * @param options | object
 */
export const setCookie = ( name, value, options ) => {
  options = {
    path: '/',
    ...options
  };

  if ( options.expires instanceof Date ) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = encodeURIComponent( name ) + "=" + encodeURIComponent( value );

  for ( let optionKey in options ) {
    updatedCookie += "; " + optionKey;

    let optionValue = options[optionKey];

    if ( optionValue !== true ) {
      updatedCookie += "=" + optionValue;
    }
  }

  document.cookie = updatedCookie;
};

/**
 * Get cookie custom hook
 *
 * @param name | string
 * @return string
 */
export const useGetCookie = name => {
  if ( typeof document !== 'undefined' ) {
    let matches = document.cookie.match( new RegExp( "(?:^|; )" + name.replace( /([.$?*|{}()[\]\\/+^])/g, '\\$1' ) + "=([^;]*)" ) );

    return matches ? decodeURIComponent( matches[1] ) : undefined;
  } else return undefined;
};

/**
 * Remove cookie helper function
 *
 * @param name | string
 */
export const deleteCookie = name => setCookie( name, '', { 'max-age': -1 } );

/**
 * Detect device custom hook
 *
 * @return object
 */
export const useDeviceDetect = () => {
  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    const userAgent =
      typeof window.navigator === "undefined" ? "" : navigator.userAgent;
    const mobile = Boolean(
      userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      )
    );
    setMobile(mobile);
  }, []);

  return { isMobile };
};

/**
 * Send request with the data to the server
 *
 * @param url | string
 * @param method | string
 * @param data | object
 * @param authorization | bool
 * @param authorizationData | object
 * @return object
 */
export async function postData( url = '', method = 'POST', data = {}, authorization = false, authorizationData = {} ) {
  const headers = authorization ?
      {
        'Content-Type': 'application/json',
        'Authorization': authorizationData.type + ' ' + authorizationData.token
      }
    :
      {
        'Content-Type': 'application/json'
      };

  const response = await fetch( url, {
    method: method,
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers,
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify( data )
  });

  //return await { res: response, resJson: response.json() };
  //return await response;
  return response.status === 204 ? [await response] : [await response, await response.json()];
}

/**
 * Get posts by ID
 *
 * @param allPosts | array
 * @param findingPosts | array
 *
 * @return array
 */
export const usePostsById = ( allPosts, findingPosts ) => {
  return allPosts.filter( post => {
    return findingPosts.find( findingPost => {
      return findingPost === post.postId;
    });
  });
};

/**
 * Add a line break after the first word in the category name
 *
 * @param categoryName | string
 *
 * @return string
 */
export const getCategoryName = categoryName => {
  const words = categoryName.split( ' ' );
  let name = '';

  words.forEach( ( word, index ) => {
    if ( categoryName.indexOf( '&' ) > -1 ) {
      if ( index === 1 ) {
        name += word + '<br />';
      } else {
        name += `${ word } `;
      }
    } else {
      if ( index === 0 ) {
        name += word + '<br />';
      } else {
        name += `${ word } `;
      }
    }
  });

  return name.trimEnd();
};