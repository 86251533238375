import styled from 'styled-components';

export const InputLoaderElement = styled.div`
  --loading-width: 70px;
  --loading-height: 18px;

  position: absolute;
  left: calc(50% - (var(--loading-width) / 2));
  top: calc(50% - (var(--loading-height) / 2));
  width: var(--loading-width);
  text-align: center;
  z-index: 2;
  
  .item {
    display: inline-block;
    width: var(--loading-height);
    height: var(--loading-height);
    border-radius: 100%;
    background-color: var(--theme-purple);
    animation: newsletterLoading 1.4s infinite ease-in-out both;
    
    &--1 {
      animation-delay: -0.32s;
    }
    
    &--2 {
      animation-delay: -0.16s
    }
  }
  
  @keyframes newsletterLoading {
    0%, 80%, 100% { 
      transform: scale(0);
    }
    
    40% {
      transform: scale(1.0);
    }
  }
`;