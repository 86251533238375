import React, { memo } from 'react';
import PropTypes       from 'prop-types';

const LinkedinIcon = memo( ( { className, color } ) => {
  return (
    <svg className={ className }
         xmlns="http://www.w3.org/2000/svg"
         width="20.276"
         height="19.365"
         viewBox="0 0 20.276 19.365">
      <g transform="translate(0 0)">
        <g transform="translate(0.283 6.002)">
          <g>
            <path d="M939.118,772.363v7.167a.321.321,0,0,1-.321.321h-3.7a.321.321,0,0,1-.322-.321v-6.665c0-1.754-.626-2.952-2.2-2.952a2.374,2.374,0,0,0-2.226,1.587,2.962,2.962,0,0,0-.144,1.058v6.972a.321.321,0,0,1-.321.321h-3.7a.32.32,0,0,1-.321-.321c.009-1.775.046-10.387.008-12.412a.319.319,0,0,1,.321-.324h3.691a.321.321,0,0,1,.321.321v1.529c-.008.015-.021.029-.029.043h.029v-.043a4.311,4.311,0,0,1,3.913-2.157c2.856,0,5,1.866,5,5.875Zm-19.672,7.488h3.7a.321.321,0,0,0,.32-.321V767.115a.321.321,0,0,0-.32-.321h-3.7a.321.321,0,0,0-.321.321v12.415A.321.321,0,0,0,919.446,779.851Z"
                  transform="translate(-919.126 -766.488)"
                  fill={ color } />
          </g>
        </g>
        <g>
          <ellipse cx="2.324"
                   cy="2.324"
                   rx="2.324"
                   ry="2.324"
                   fill={ color } />
        </g>
      </g>
    </svg>
  );
});

LinkedinIcon.defaultProps = {
  className: '',
  color: '#fff'
};

LinkedinIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

export default LinkedinIcon;