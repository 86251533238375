import styled from 'styled-components';

export const SearchResultsElement = styled.div`
  position: absolute;
  left: 0;
  top: calc(100% - -8px);
  width: 100%;
  max-height: 0;
  overflow: hidden;
  visibility: hidden;
  border-radius: 8px 8px;
  border-top: none;
  background: var(--white);
  box-shadow: 0 0 30px rgba(var(--black--rgb), .2);
  z-index: 1;
  animation: searchClosing .3s ease forwards;
  
  &.is-opened {
    animation: searchOpening .5s ease forwards;
    animation-delay: .2s;
  }
  
  .item {
    display: block;
    height: auto;
    overflow: hidden;
    padding: 20px 25px 20px 25px;
    text-decoration: none;
    transition: .3s all ease-in-out;
    
    &:hover {
      background: var(--theme-white);
      cursor: pointer;
    }
  
    &__title {
      margin: 0 0 7px 0;
      font-size: 16px;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 1.05px;
      text-decoration: none;
      color: var(--theme-deep-purple);
    }
    
    &__description {
      margin: 0;
      line-height: 22px;
      color: var(--theme-deep-purple);
    }
    
    &--highlight {
      font-weight: bold;
      color: var(--theme-blue);
      background-color: transparent;
    }
  }
  
  .amount {
    margin: 10px 0;
    text-align: center;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 1.2px;
    color: var(--theme-dark-gray);
    
    &__limit,
    &__total {
      color: var(--theme-blue);
    }
    
    &__all {
      display: block;
      width: 150px;
      margin: 0 auto 20px auto;
      text-align: center;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 1.2px;
      text-decoration: underline;
      color: var(--theme-blue);
      
      &:hover {
        cursor: pointer;
      }
      
      &__arrow {
        display: none;
      }
    }
  }
  
  .no-results {
    padding: 20px 25px 20px 25px;
    font-size: 16px;
    line-height: 18px;
    
    &__highlight {
      font-weight: 700;
      color: var(--theme-dark-purple);
    }
  }
  
  @keyframes searchOpening {
    0% {
      max-height: 0;
      visibility: hidden;
    }
    
    100% {
      max-height: 550px;
      visibility: visible;
    }
  }
  
  @keyframes searchClosing {
    0% {
      max-height: 550px;
      visibility: visible;
    }
    
    100% {
      max-height: 0;
      visibility: hidden;
    }
  }
  
  /*-------------------------
  # Media Queries
  --------------------------*/
  @media (max-width: 1280px) {
    --height: 110px;
  
    .item {
      padding: 15px;
      
    }
  }
  
  @media (max-width: 991px) {
    top: 100%;
    border-radius: 0;
    background: var(--white);
    box-shadow: none;
    
    .item {
      padding: 25px 35px;
      border-top: 1px solid var(--theme-dark-gray);
      
      &__title {
        margin-bottom: 15px;
        font-size: 16px;
        line-height: 18px;
        font-weight: 700;
      }
      
      &__description {
        font-size: 16px;
        line-height: 24px;
      }
    }
    
    .amount {
      display: none;
      
      &__all {
        display: flex;
        font-size: 16px;
        font-weight: 600;
        line-height: 19px;
        text-decoration: none;
        color: var(--theme-purple);
        
        &__arrow {
          display: inline-block;
          margin-left: 10px;
          width: 16px;
          height: 16px;
        }
      }
    }
    
    .no-results {
      margin: 0;
      border-top: 1px solid var(--theme-dark-gray);
      padding-top: 30px;
      padding-bottom: 30px;
    }
    
    @keyframes searchOpening {
      0% {
        max-height: 0;
        height: 0;
        visibility: hidden;
      }
      
      100% {
        max-height: 100vh;
        height: 100vh;
        visibility: visible;
      }
    }
    
    @keyframes searchClosing {
      0% {
        max-height: 100vh;
        height: 100vh;
        visibility: visible;
      }
      
      100% {
        max-height: 0;
        height: 0;
        visibility: hidden;
      }
    }
  }
  
  @media (max-width: 480px) {
    .item {
      padding-top: 15px;
      padding-bottom: 15px;
      
      &__title {
        margin-bottom: 5px;
      }
      
      &__description {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
`;