import React, { memo } from 'react';
import PropTypes       from 'prop-types';

const UserIconCircled = memo( ( { className, circleColor, userColor } ) => {
  return (
    <svg className={ className }
         xmlns="http://www.w3.org/2000/svg"
         width="44.391"
         height="44.391"
         viewBox="0 0 44.391 44.391">
      <g transform="translate(6495.842 20405.443)">
      <ellipse cx="22.195"
               cy="22.195"
               rx="22.195"
               ry="22.195"
               transform="translate(-6495.842 -20405.443)"
               fill={ circleColor } />
      <g transform="translate(-6481.906 -20394.301)">
      <path d="M854.286,564.274a4.665,4.665,0,1,0-4.665-4.665,4.665,4.665,0,0,0,4.665,4.665Z"
            transform="translate(-845.876 -554.945)"
            fill={ userColor } />
      <path d="M861.59,568.594a4.259,4.259,0,0,0-3.61-2.01v0h-.032v0a4.521,4.521,0,0,0-1.658.314,6.458,6.458,0,0,1-2.375.466,5.876,5.876,0,0,1-2.216-.438,4.55,4.55,0,0,0-1.648-.317,4.217,4.217,0,0,0-3.707,2.133,5.768,5.768,0,0,0-.745,2.878v6.23h16.819v-6.23A5.785,5.785,0,0,0,861.59,568.594Z"
            transform="translate(-845.6 -555.745)"
            fill={ userColor } />
      </g>
    </g>
  </svg>
)
});

UserIconCircled.defaultProps = {
  className: '',
  circleColor: '#fff',
  userColor: '#140048'
};

UserIconCircled.propTypes = {
  className: PropTypes.string,
  circleColor: PropTypes.string,
  userColor: PropTypes.string
};

export default UserIconCircled;