import React, { memo, useState, useContext, useEffect } from 'react';
import { graphql, useStaticQuery }                      from 'gatsby';

// import constants
import { API_URL, CHANGE_OVERLAY } from '../../../../constants';

// import context
import { GlobalDispatchContext, GlobalStateContext } from '../../../../context/GlobalContextProvider';

// import helpers
import { postData, useEmailValidation, useWindowSize, useGermanPhoneValidation, useSwissPhoneValidation } from '../../../../helpers';

// import components
import PhoneIcon from '../../icons/PhoneIcon';
import Button    from '../../Button';

const Contact = memo( () => {
  const data = useStaticQuery( graphql`
    {
      allApiPoints {
        nodes {
          footer {
            inquiry_texts {
              company_name
              email
              error_message
              error_message_email
              error_message_phone
              first_name
              gender_options {
                female_text
                female_value
                male_text
                male_value
              }
              last_name
              salutation_text
              phone
              subscribe_to_newsletter
              title
              button_text
              message
            }
          }
        }
      }
    }
  `);

  // define the component context
  const state    = useContext( GlobalStateContext );
  const dispatch = useContext( GlobalDispatchContext );
  const { defaultLang } = state;

  // define the default component state
  const [genderIsInvalid, setGenderIsInvalid]       = useState( false );
  const [firstNameIsInvalid, setFirstNameIsInvalid] = useState( false );
  const [lastNameIsInvalid, setLastNameIsInvalid]   = useState( false );
  const [emailIsInvalid, setEmailIsInvalid]         = useState( false );
  const [messageIsInvalid, setMessageIsInvalid]     = useState( false );

  const [isEmailError, setIsEmailError] = useState( false );
  const [isPhoneError, setIsPhoneError] = useState( false );
  const [isBtnProcess, setIsBtnProcess] = useState( false );
  const [isError, setIsError]   = useState( false );
  const [formData, setFormData] = useState({
    company: '',
    gender: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
    subscribe: false
  });
  const [texts, setTexts] = useState({
    title: '',
    companyNamePlaceholder: '',
    genderPlaceholder: '',
    genderMaleText: '',
    genderMaleValue: '',
    genderFemaleText: '',
    genderFemaleValue: '',
    firstNamePlaceholder: '',
    lastNamePlaceholder: '',
    emailPlaceholder: '',
    phonePlaceholder: '',
    subscribeText: '',
    buttonText: '',
    errorText: '',
    errorEmailText: '',
    errorPhoneText: '',
    messagePlaceholder: ''
  });

  const { company, gender, firstName, lastName, email, phone, message, subscribe } = formData;
  const {
    title, companyNamePlaceholder, genderPlaceholder, genderMaleText, genderMaleValue, genderFemaleText, genderFemaleValue,
    firstNamePlaceholder, lastNamePlaceholder, emailPlaceholder, phonePlaceholder, subscribeText, buttonText,
    errorText, errorEmailText, errorPhoneText, messagePlaceholder
  } = texts;

  // get the component custom hooks
  const validation = useEmailValidation( email );
  const germanPhoneValidation = useGermanPhoneValidation( phone );
  const swissPhoneValidation  = useSwissPhoneValidation( phone );
  const { width }  = useWindowSize();
  /**
   * Get texts from the backend and processing changing language
   */
  useEffect( () => {
    // get loaded data from graphql
    data.allApiPoints.nodes.forEach( node => {
      const { footer } = node;
      let orderNumber;

      switch ( defaultLang ) {
        case 'de':
          orderNumber = 1;
          break;

        default:
          orderNumber = 0;
      }

      const current = footer[orderNumber];
      const { inquiry_texts: {
        title, company_name, salutation_text, first_name, last_name, email, phone, subscribe_to_newsletter,
        button_text, error_message, error_message_email, error_message_phone, gender_options, message
      }} = current;

      const { male_text, male_value, female_text, female_value } = gender_options[0];

      setTexts({
        title,
        companyNamePlaceholder: company_name,
        genderPlaceholder: salutation_text,
        genderMaleText: male_text,
        genderMaleValue: male_value,
        genderFemaleText: female_text,
        genderFemaleValue: female_value,
        firstNamePlaceholder: first_name,
        lastNamePlaceholder: last_name,
        emailPlaceholder: email,
        phonePlaceholder: phone,
        subscribeText: subscribe_to_newsletter,
        buttonText: button_text,
        errorText: error_message,
        errorEmailText: error_message_email,
        errorPhoneText: error_message_phone,
        messagePlaceholder: message
      })
    });
  }, [data, defaultLang] );

  /**
   * Processing change the gender field
   *
   * @param value | string
   */
  const handleChangeGender = ( value ) => {
    setFormData( { ...formData, gender: value } );
    setIsError( false );
    setGenderIsInvalid( false );
  };

  /**
   * Hide an error
   *
   * @param field | string
   */
  const handleHideError = ( field ) => {
    if ( isError ) setIsError( false );
    if ( isEmailError ) setIsEmailError( false );
    if ( isPhoneError ) setIsPhoneError( false );
    if ( firstNameIsInvalid && field === 'firstName' ) setFirstNameIsInvalid( false );
    if ( lastNameIsInvalid && field === 'lastName' ) setLastNameIsInvalid( false );
    if ( emailIsInvalid && field === 'email' ) setEmailIsInvalid( false );
    if ( messageIsInvalid && field === 'message' ) setMessageIsInvalid( false );
  };

  const submittingFunction = () => {
    const { company, gender, firstName, lastName, email, phone, message, subscribe } = formData;

    const prepFirstName = firstName.trim();
    const prepLastName  = lastName.trim();
    const data = {
      company_name: company.trim(),
      first_name: prepFirstName,
      last_name: prepLastName,
      gender,
      message,
      phone,
      email,
      newsletter: subscribe ? "True" : "False"
    };

    setIsBtnProcess( true );

    postData( API_URL + 'home/create-inquiry/' + defaultLang, 'POST', data, false )
      .then( data => {
        // show a thank message
        if ( data[0].status === 201 ) {
          setIsBtnProcess( false );
          dispatch( { type: CHANGE_OVERLAY, payload: 'contactMsg' } );
        }
    });
  };

  /**
   * Processing a send action.
   * Validating the data, passing the data to the backend or showing an error
   */
  const handleSend = ( e ) => {
    e.preventDefault();

    const { gender, firstName, lastName, email, phone, message } = formData;

    if (
      gender !== '' &&
      firstName !== '' &&
      lastName !== '' &&
      message !== ''
    ) {
      if ( email !== '' && validation ) {
        if ( phone === '' ) {
          submittingFunction();
        } else if ( phone !== '' ) {
          if ( !germanPhoneValidation && !swissPhoneValidation ) {
            setIsPhoneError( true );
          } else {
            submittingFunction();
          }
        }

      } else {
        if ( email === '' || !validation ) {
          setIsEmailError( true );
          setEmailIsInvalid( true );
        }
      }
    } else {
      setIsError( true );
      if ( isEmailError ) setIsEmailError( true );
      if ( gender === '' ) setGenderIsInvalid( true );
      if ( firstName === '' ) setFirstNameIsInvalid( true );
      if ( lastName === '' ) setLastNameIsInvalid( true );
      if ( email === '' ) setEmailIsInvalid( true );
      if ( message === '' ) setMessageIsInvalid( true );
    }
  };

  return (
    <>
      <span className="overlay__icon overlay__icon--decorated">
        <PhoneIcon className="overlay__icon__item" color="#0E1237"/>
      </span>
      <h4 className="overlay__title">{ title }</h4>
      { isError ? <span className="overlay__error">{ errorText }</span> : false }
      { isEmailError ? <span className="overlay__error">{ errorEmailText }</span> : false }
      { isPhoneError ? <span className="overlay__error">{ errorPhoneText }</span> : false }
      <form className="overlay__form"
            onSubmit={ ( e ) => handleSend( e ) }>
        <div className="overlay__field-group">
          <input className="overlay__field"
                 type="text"
                 value={ company }
                 placeholder={ companyNamePlaceholder }
                 onChange={ ( e ) => { setFormData( { ...formData, company: e.currentTarget.value.trimStart() } ) } } />
        </div>

        <div className="overlay__gender-switcher">
        <span className={
          genderIsInvalid
            ? 'overlay__gender-switcher__label overlay__gender-switcher__label--is-invalid'
            : 'overlay__gender-switcher__label'
        }>
          { genderPlaceholder }
          </span>
          <div className="overlay__gender-switcher__radios">
            { width > 991 ?
              <>
                <input className="overlay__gender-switcher__radio"
                       id="male"
                       type="radio"
                       name="gender"
                       checked={ gender === genderMaleValue }
                       hidden
                       onChange={ () => handleChangeGender( genderMaleValue ) } />
                <label className="overlay__gender-switcher__radio__label"
                       htmlFor="male">{ genderMaleText }</label>

                <input className="overlay__gender-switcher__radio"
                       id="female"
                       type="radio"
                       name="gender"
                       checked={ gender === genderFemaleValue }
                       hidden
                       onChange={ () => handleChangeGender( genderFemaleValue ) } />
                <label className="overlay__gender-switcher__radio__label"
                       htmlFor="female">{ genderFemaleText }</label>
              </>
              :
              <>
                <input className="overlay__gender-switcher__radio"
                       id="male"
                       type="radio"
                       name="gender"
                       checked={ gender === genderMaleValue }
                       hidden
                       onChange={ () => handleChangeGender( genderMaleValue ) } />
                <label className={ gender === genderMaleValue ? 'overlay__gender-switcher__radio__label is-checked' : 'overlay__gender-switcher__radio__label' }
                       role="presentation"
                       htmlFor="male"
                       onClick={ () => handleChangeGender( genderMaleValue ) }>{ genderMaleText }</label>

                <input className="overlay__gender-switcher__radio"
                       id="female"
                       type="radio"
                       name="gender"
                       checked={ gender === genderFemaleValue }
                       hidden
                       onChange={ () => handleChangeGender( genderFemaleValue ) } />
                <label className={ gender === genderFemaleValue ? 'overlay__gender-switcher__radio__label is-checked' : 'overlay__gender-switcher__radio__label' }
                       role="presentation"
                       htmlFor="female"
                       onClick={ () => handleChangeGender( genderFemaleValue ) }>{ genderFemaleText }</label>
              </>
            }
          </div>
        </div>

        <div className="overlay__field-group overlay__field-group--flex">
          <input className={
            firstNameIsInvalid
              ? 'overlay__field overlay__field--is-invalid overlay__field--half overlay__field--left'
              : 'overlay__field overlay__field--half overlay__field--left'
          }
                 type="text"
                 value={ firstName }
                 placeholder={ firstNamePlaceholder }
                 onChange={ ( e ) => { setFormData( { ...formData, firstName: e.currentTarget.value.trimStart() } ) } }
                 onFocus={ () => handleHideError( 'firstName' ) } />

          <input className={
            lastNameIsInvalid
              ? 'overlay__field overlay__field--is-invalid overlay__field--half overlay__field--right'
              : 'overlay__field overlay__field--half overlay__field--right'
          }
                 type="text"
                 value={ lastName }
                 placeholder={ lastNamePlaceholder }
                 onChange={ ( e ) => { setFormData( { ...formData, lastName: e.currentTarget.value.trimStart() } ) } }
                 onFocus={ () => handleHideError( 'lastName' ) } />
        </div>

        <div className="overlay__field-group overlay__field-group--flex">
          <input className={
            emailIsInvalid
              ? 'overlay__field overlay__field--is-invalid overlay__field--half overlay__field--left'
              : 'overlay__field overlay__field--half overlay__field--left'
          }
                 type="text"
                 value={ email }
                 placeholder={ emailPlaceholder }
                 onChange={ ( e ) => { setFormData( { ...formData, email: e.currentTarget.value.trim().toLowerCase() } ) } }
                 onFocus={ () => handleHideError( 'email' ) } />

          <input className="overlay__field overlay__field--half overlay__field--right"
                 type="text"
                 value={ phone }
                 placeholder={ phonePlaceholder }
                 onChange={ ( e ) => { setFormData( { ...formData, phone: e.currentTarget.value.trim() } ) } }
                 onFocus={ () => handleHideError( 'phone' ) } />
        </div>

        <div className="overlay__field-group">
        <textarea className={
          messageIsInvalid
            ? 'overlay__field overlay__field--is-invalid overlay__field--textarea'
            : 'overlay__field overlay__field--textarea'
        }
                  value={ message }
                  placeholder={ messagePlaceholder }
                  onChange={ ( e ) => { setFormData( { ...formData, message: e.currentTarget.value } ) } }
                  onFocus={ () => handleHideError( 'message' ) } />
        </div>

        <div className="overlay__subscribe overlay__subscribe--uppercase">
          { width > 991 ?
            <>
              <input className="overlay__subscribe__mark"
                     id="subscribe"
                     type="checkbox"
                     checked={ subscribe }
                     hidden
                     onChange={ ( e ) => { setFormData( { ...formData, subscribe: e.currentTarget.checked } ) } } />
              <label className="overlay__subscribe__label" htmlFor="subscribe">{ subscribeText }</label>
            </>
            :
            <>
              <input className="overlay__subscribe__mark"
                     id="subscribe"
                     type="checkbox"
                     checked={ subscribe }
                     hidden
                     onChange={ () => { setFormData( ( state ) => ( { ...formData, subscribe: !state.subscribe } ) ) } } />
              <label className={ subscribe ? 'overlay__subscribe__label is-checked' : 'overlay__subscribe__label' }
                     htmlFor="subscribe"
                     role="presentation"
                     onClick={ () => { setFormData( ( state ) => ( { ...formData, subscribe: !state.subscribe } ) ) } }>
                { subscribeText }
              </label>
            </>
          }
        </div>

        <Button className={ `overlay__register__btn${ isBtnProcess ? ' processing' : '' }` }
                text={ buttonText }
                disabled={ isBtnProcess }
                method="submit" />
      </form>
    </>
  )
});

export default Contact;