import React, { memo, useContext, useState, useEffect } from 'react';
import { useStaticQuery, graphql }                      from 'gatsby';
import PropTypes                                        from 'prop-types';

// import context
import { GlobalStateContext } from '../../context/GlobalContextProvider';

// import components
import UserIconAdd from '../elements/icons/UserIconAdd';

const RegisterThankMsg = memo( ( { className } ) => {
  const data = useStaticQuery( graphql`
    {
      allApiPoints {
        nodes {
          register {
            title
            thank_msg
          }
        }
      }
    }
  `);

  // define the component context
  const state = useContext( GlobalStateContext );
  const { defaultLang } = state;

  // define the default component state
  const [texts, setTexts] = useState({
    registerTitle: '',
    registerMsg: ''
  });

  const { registerTitle, registerMsg } = texts;

  /**
   * Get texts from the backend and processing changing language
   */
  useEffect( () => {
    // get loaded data from graphql
    data.allApiPoints.nodes.forEach( node => {
      const { register } = node;
      let orderNumber;

      switch ( defaultLang ) {
        case 'en':
          orderNumber = 1;
          break;

        default:
          orderNumber = 0;
      }

      const current = register[orderNumber];
      const { title, thank_msg } = current;

      setTexts( t => ({
        ...t,
        registerTitle: title,
        registerMsg: thank_msg
      }));
    });
  }, [data, defaultLang] );

  return(
    <>
      <UserIconAdd className={ `overlay__icon ${ className }` } />
      <h4 className={ `overlay__title ${ className }` }>{ registerTitle }</h4>
      <span className="overlay__helper-text overlay__helper-text--center" dangerouslySetInnerHTML={ { __html: registerMsg } } />
    </>
  )
});

RegisterThankMsg.defaultProps = {
  className: ''
};

RegisterThankMsg.propTypes = {
  className: PropTypes.string
};

export default RegisterThankMsg;