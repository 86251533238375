import React, { memo, useState, useEffect, useContext } from 'react';
import { useStaticQuery, graphql }                      from 'gatsby';
import PropTypes                                        from 'prop-types';

// import context
import { GlobalStateContext } from '../../context/GlobalContextProvider';

// import styles
import { PasswordHintElement } from '../../styles/elements/PasswordHint';

// import components
import PasswordHintRule from './PasswordHintRule';

const PasswordHint = memo( ( { password } ) => {
  const data = useStaticQuery( graphql`
    {
      allApiPoints {
        nodes {
          register {
            regex_8_char_text
            regex_numeric_char_text
            regex_capital_char_text
            regex_special_char_text
          }
        }
      }
    }
  `);

  // define the component context
  const globalState = useContext( GlobalStateContext );
  const { defaultLang } = globalState;

  // define the default component state
  const [state, setState] = useState({
    min8chars: false,
    min8charsText: '',
    numeric: false,
    numericText: '',
    capital: false,
    capitalText: '',
    specialChar: false,
    specialCharText: ''
  });

  const { min8chars, min8charsText, numeric, numericText, capital, capitalText, specialChar, specialCharText } = state;

  /**
   * Get texts from the backend and processing changing language
   */
  useEffect( () => {
    // get loaded data from graphql
    data.allApiPoints.nodes.forEach( node => {
      const { register } = node;
      let orderNumber;

      switch ( defaultLang ) {
        case 'en':
          orderNumber = 1;
          break;

        default:
          orderNumber = 0;
      }

      const current = register[orderNumber];
      const {
        regex_8_char_text, regex_numeric_char_text, regex_capital_char_text, regex_special_char_text
      } = current;

      setState( t => ({
        ...t,
        min8charsText: regex_8_char_text,
        numericText: regex_numeric_char_text,
        capitalText: regex_capital_char_text,
        specialCharText: regex_special_char_text
      }));
    });
  }, [data, defaultLang] );

  /**
   * Check the password rules
   */
  useEffect( () => {
    const min8charsRegExp = /(?=.{8,})/;
    const numericRegExp   = /(?=.*[0-9])/;
    const capitalRegExp   = /(?=.*[A-Z])/;
    const specialRegExp   = /(?=.*[^A-Za-z0-9])/;

    setState( t => ({
      ...t,
      min8chars: min8charsRegExp.test( password ),
      numeric: numericRegExp.test( password ),
      capital: capitalRegExp.test( password ),
      specialChar: specialRegExp.test( password )
    }));
  }, [password] );

  return (
    <PasswordHintElement>
      <PasswordHintRule type={ min8chars } text={ min8charsText } />
      <PasswordHintRule type={ numeric } text={ numericText } />
      <PasswordHintRule type={ capital } text={ capitalText } />
      <PasswordHintRule type={ specialChar } text={ specialCharText } />
    </PasswordHintElement>
  );
});

PasswordHint.defaultProps = {
  password: ''
};

PasswordHint.propTypes = {
  password: PropTypes.string
};

export default PasswordHint;