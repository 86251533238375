import React, { memo, useContext, useCallback, useEffect } from 'react';

// import constants
import { SHOW_OVERLAY } from '../../../constants';

// import context
import { GlobalDispatchContext, GlobalStateContext } from '../../../context/GlobalContextProvider';

// import components
import RSSMenu                  from '../RSSMenu';
import LanguageMenu             from '../LanguageMenu';
import Login                    from '../Login';
import Register                 from '../Register';
import RegisterThankMsg         from '../RegisterThankMsg';
import ForgotPassword           from '../ForgotPassword';
import ForgotPasswordThankMsg   from '../ForgotPasswordThankMsg';
import Contact                  from './Contact/Contact';
import ContactMsg               from './Contact/ContactMsg';
import Account                  from '../Account/index';
import AccountChange            from '../Account/AccountChange';
import AccountChangePassword    from '../Account/AccountChangePassword';
import AccountChangePasswordMsg from '../Account/AccountChangePasswordMsg';
import LogoutMsg                from '../LogoutMsg';
import ProfileDeletedMsg        from '../ProfileDeletedMsg';
import CloseBtn                 from '../CloseBtn';

const Overlay = memo( () => {
  // define the component context
  const state    = useContext( GlobalStateContext );
  const dispatch = useContext( GlobalDispatchContext );
  const { overlayType, overlayIsShown } = state;

  /**
   * Close a popup when ESC key was pressed
   *
   * @param e | Event
   */
  const handleEsc = useCallback( e => {
    if ( e.keyCode === 27 ) {
      dispatch( { type: SHOW_OVERLAY, payload: false } );
    }
  }, [dispatch] );

  /**
   * Processing to press ESC key
   */
  useEffect( () => {
    document.addEventListener( 'keydown', handleEsc, false );

    return () => {
      document.removeEventListener( 'keydown', handleEsc, false );
    };
  }, [handleEsc] );

  return (
    <div className={ `overlay${ overlayIsShown ? ' is-opened' : '' }` }>
      <div className={ `overlay__container${ overlayType === 'register' ? ' register-overlay' : '' }` }>
        { overlayType === 'rss' ? <RSSMenu /> : false }
        { overlayType === 'lang' ? <LanguageMenu /> : false }
        { overlayType === 'login' ? <Login /> : false }
        { overlayType === 'register' ? <Register className="register-overlay" /> : false }
        { overlayType === 'registerMsg' ? <RegisterThankMsg className="register-overlay" /> : false }
        { overlayType === 'forgot' ? <ForgotPassword /> : false }
        { overlayType === 'forgotMsg' ? <ForgotPasswordThankMsg /> : false }
        { overlayType === 'contact' ? <Contact /> : false }
        { overlayType === 'contactMsg' ? <ContactMsg /> : false }
        { overlayType === 'account' ? <Account /> : false }
        { overlayType === 'accountChange' ? <AccountChange /> : false }
        { overlayType === 'accountChangePassword' ? <AccountChangePassword /> : false }
        { overlayType === 'accountChangePasswordMsg' ? <AccountChangePasswordMsg /> : false }
        { overlayType === 'logout' ? <LogoutMsg /> : false }
        { overlayType === 'deleted' ? <ProfileDeletedMsg /> : false }

        <CloseBtn className="overlay__close"
                  onClick={ () => { dispatch( { type: SHOW_OVERLAY, payload: false } ); } } />
      </div>
    </div>
  );
});

export default Overlay;