import styled from 'styled-components';

export const ArrowElement = styled.svg`
  transition: .3s all ease-in-out;
  
  &.left {
    transform: rotate(0deg);
  }
  
  &.up {
    transform: rotate(90deg);
  }
  
  &.down {
    transform: rotate(-90deg);
  }
  
  &.right {
    transform: rotate(-180deg);
  }
`;