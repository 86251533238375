// Basic constants
export const DEFAULT_LANG = 'de';
export const BACKEND_URL  = 'https://monkeybackend.ch';
export const API_URL      = BACKEND_URL + '/api/';
export const AUTH_URL     = BACKEND_URL + '/auth/';

// Context constants
export const SHOW_OVERLAY        = 'SHOW_OVERLAY';
export const CHANGE_OVERLAY      = 'CHANGE_OVERLAY';
export const CHANGE_LANGUAGE     = 'CHANGE_LANGUAGE';
export const CHANGE_FILTER       = 'CHANGE_FILTER';
export const GET_FILTERED_POSTS  = 'GET_FILTERED_POSTS';
export const OPEN_MOBILE_MENU    = 'OPEN_MOBILE_MENU';
export const CHANGE_LOGIN_STATUS = 'CHANGE_LOGIN_STATUS';
export const CHANGE_SORT_TITLE   = 'CHANGE_SORT_TITLE';