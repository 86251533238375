import React, { memo, useState, useEffect, useContext } from 'react';
import { graphql, navigate, useStaticQuery }            from 'gatsby';
import PropTypes                                        from 'prop-types';

// import constants
import { CHANGE_OVERLAY, OPEN_MOBILE_MENU } from '../../../constants';

// import context
import { GlobalDispatchContext, GlobalStateContext } from '../../../context/GlobalContextProvider';

// import styles
import { MobileSearchElement } from '../../../styles/elements/Search/MobileSearch';

// import components
import ArrowRegularIcon from '../icons/ArrowRegularIcon';
import SearchResults    from './SearchResults';

const MobileSearch = memo( ( { isShown, isCleared } ) => {
  const data = useStaticQuery( graphql`
    {
      allApiPoints {
        nodes {
          search_texts {
            mobile_placeholder
          }
        }
      }
    }
  `);

  // get the app global state
  const state    = useContext( GlobalStateContext );
  const dispatch = useContext( GlobalDispatchContext );
  const { defaultLang, overlayType } = state;

  // define the default component state
  const [resultsIsOpened, setResultsIsOpened] = useState( false );
  const [searchValue, setSearchValue]         = useState( '' );
  const [searchResults, setSearchResults]     = useState( [] );
  const [placeholder, setPlaceholder]         = useState( '' );

  /**
   * Get texts from the backend and processing changing language
   */
  useEffect( () => {
    // get loaded data from graphql
    data.allApiPoints.nodes.forEach( node => {
      const { search_texts } = node;
      let orderNumber;

      switch ( defaultLang ) {
        case 'en':
          orderNumber = 1;
          break;

        default:
          orderNumber = 0;
      }

      setPlaceholder( search_texts[orderNumber].mobile_placeholder );
    });
  }, [data, defaultLang] );

  // processing actions when the components was mounted
  useEffect( () => {
    if ( isCleared ) {
      setResultsIsOpened( false );
      setSearchValue( '' );
    }
  }, [isShown, isCleared] );

  useEffect( () => {
    if ( overlayType === 'closeSearch' ) {
      setResultsIsOpened( false );
      setSearchValue( '' );
    }
  }, [overlayType] );

  /**
   * Processing a form submitting
   *
   * @param e | Event
   */
  const handleSubmit = ( e ) => {
    e.preventDefault();

    const header = document.getElementsByTagName( 'header' )[0];
    const main   = document.getElementsByTagName( 'main' )[0];
    const footer = document.getElementsByTagName( 'footer' )[0];

    navigate( '/search', { state: { searchResults, searchValue } } );
    header.classList.remove( 'is-shifted' );
    main.classList.remove( 'is-shifted' );
    footer.classList.remove( 'is-shifted' );
    dispatch( { type: OPEN_MOBILE_MENU, payload: false } );
    dispatch( { type: CHANGE_OVERLAY, payload: 'closeSearch' } );
  };

  /**
   * Processing a field value change
   *
   * @param e | Event
   */
  const handleChange = ( e ) => {
    const value = e.currentTarget.value;

    const requestUrl = defaultLang === 'de'
      ? 'https://monkeybackend.ch/api/blog_new/posts-search-german/?search='
      : 'https://monkeybackend.ch/api/blog_new/posts-search-english/?search=';

    setSearchValue( value );

    fetch( requestUrl + value )
      .then( response => response.json() )
      .then(
        data => {
          const { search_results } = data;

          setSearchResults( search_results );
        },
        error => { console.log( error );
        });

    if ( value !== '' ) {
      setTimeout( () => {
        setResultsIsOpened( true );
      }, 300 );
    } else {
      setResultsIsOpened( false );

      setTimeout( () => {
        setSearchResults( [] );
      }, 300 );
    }
  };

  /**
   * Processing a field value change
   */
  const handleBlur = () => {
    setTimeout( () => {
      setResultsIsOpened( false );
    }, 150 );
  };

  return (
    <MobileSearchElement className={ isShown ? ' is-shown' : '' }
                         onSubmit={ ( e ) => handleSubmit( e ) }>
        <input className="field"
               type="text"
               value={ searchValue }
               placeholder={ placeholder }
               onChange={ ( e ) => handleChange( e ) }
               onBlur={ handleBlur } />
        <button className={ resultsIsOpened ? 'button is-clicked' : 'button' }
                aria-label="Search"
                type="submit">
          <ArrowRegularIcon className="button__icon" />
        </button>

      <SearchResults isOpened={ resultsIsOpened }
                     posts={ searchResults }
                     value={ searchValue } />
    </MobileSearchElement>
  )
});

MobileSearch.defaultProps = {
  isShown: false,
  isCleared: false
};

MobileSearch.propTypes = {
  isShown: PropTypes.bool,
  isCleared: PropTypes.bool
};

export default MobileSearch;