import styled from 'styled-components';

export const MobileMenuElement = styled.div`
  position: fixed;
  left: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: var(--theme-dark-purple);
  transition: .3s all ease-in;
  
  &.is-opened {
    left: 0;
    transition: .3s all ease-out;
  }
  
  .bottom {
    position: relative;
    display: flex;
    flex-direction: column;
    align-content: space-between;
    justify-content: space-between;
    height: 100%;
    padding-bottom: 60px;
  }
  
  .header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 165px;
    background-color: var(--theme-white);
    box-shadow: 0 5px 5px rgba(0, 0, 0, .16);
    z-index: 2;
  }
  
  .close {
    position: absolute;
    top: 18px;
    right: 30px;
    
    &__icon {
      width: 24px;
      height: 24px;
      transform: rotate(0deg);
      transition: .3s all ease-in-out;
      
      
      &:hover {
        cursor: pointer;
        transform: rotate(1turn);
      }
    }
  }
  
  .logo {
    width: 278px;
    height: 50px;
    
    &__link {
      position: relative;
    }
  }
  
  .switcher {
    position: relative;
    display: flex;
    height: 108px;
    width: 100%;
    background-color: var(--theme-light-gray);
    z-index: 1;
    
    &__search,
    &__user {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      padding: 0 30px;
      transition: .3s all ease-in-out;
      
      &:hover {
        cursor: pointer;
      }
    }
    
    &__search {
      background-color: var(--white);
      
      &.is-extended {
        justify-content: flex-start;
        width: 100%;
        
        .switcher {
          &__search {
            &__icon {
              width: 40px;
              height: 40px;
            }
          }
        }
      }
      
      &.is-shifted {
        width: 0;
        padding: 0;
      }
      
      &__icon {
        transition: .3s all ease-in-out;
        z-index: 2;
      }
    }
    
    &__user {
      &.is-extended {
        width: 100%;
        
        &--logged {
          .switcher {
            &__user {
              &__icon {
                margin-right: 15px;
              
                path {
                  fill: var(--theme-blue);
                }
              }
              
              &__hello {
                font-size: 18px;
                letter-spacing: 1.25px;
                opacity: 0;
                transition: .3s all ease-in-out;
                animation: fadeInProfileName .3s ease forwards;
                animation-delay: .2s;
              }
            }
          }
        }
      }
      
      &.is-shifted {
        width: 0;
        padding: 0;
      }
      
      &__icon {
        width: 22px;
        height: 30px;
      }
    }
  }
  
  .navigation {
    position: relative;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 90px 0;
    transition: .3s all ease-in;
    
    &.is-shifted {
      left: -100%;
      transition: .3s all ease-out;
    }
  
    &__item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      
      &__icon {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        margin-bottom: 13px;
        border: 1px solid var(--white);
        border-radius: 6px;
      
        &--mm {
          width: 23px;
          height: 13px;
        }
        
        &--rss {
          width: 27px;
          height: 27px;
        }
        
        &--lang {
          width: 22px;
          height: 22px;
        }
        
        &--indicator {
          --dimensions: 22px;
      
          position: absolute;
          top: -12px;
          right: -12px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: var(--dimensions);
          height: var(--dimensions);
          border-radius: 50%;
          font-size: 10px;
          line-height: 1;
          font-weight: 600;
          text-transform: uppercase;
          color: var(--white);
          background-color: var(--theme-blue);
        }
      }
      
      &__text {
        font-size: 16px;
        font-weight: 600;
        line-height: 19px;
        text-transform: uppercase;
        letter-spacing: 1.2px;
        color: var(--white);
      }
    }
  }
  
  .socials {
    position: relative;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: .4s all ease-in;
    
    &.is-shifted {
      left: -100%;
      transition: .6s all ease-out;
    }
    
    &__title {
      margin-bottom: 18px;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: .8px;
      color: var(--white);
    }
    
    &__list {
      display: flex;
      
      &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        margin: 0 15px;
        border: 1px solid var(--white);
        border-radius: 6px;
      }
    }
  }
  
  .close-sub-nav {
    position: absolute;
    left: -70px;
    bottom: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    opacity: 0;
    visibility: hidden;
    border: 1px solid var(--white);
    border-radius: 6px;
    animation: menuFadeInRight .5s ease forwards;
    z-index: 1;
    
    &.is-visible {
      animation: menuFadeInLeft .5s ease forwards;
      animation-delay: .7s;
    }
  }
  
  .languages,
  .rss {
    position: absolute;
    left: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100% - 140px);
    transition: .3s all ease-in;
    
    &.is-opened {
      left: 0;
      transition: .3s all ease-out;
      
      .overlay {
        &__icon__wrapper,
        &__title,
        &__list__item {
          animation: mobileOverlayFadeInRight .5s ease forwards;
        }
        
        &__icon {
          animation: none;
          
          &__wrapper {
            animation-delay: .25s;
          }
        }
        
        &__title {
          animation-delay: .3s;
        }
        
        &__list__item {
          animation-delay: .35s;
          
          &:nth-of-type(2) {
            animation-delay: .4s;
          }
          
          &:nth-of-type(3) {
            animation-delay: .45s;
          }
          
          &:nth-of-type(4) {
            animation-delay: .5s;
          }
          
          &:nth-of-type(5) {
            animation-delay: .55s;
          }
          
          &:nth-of-type(6) {
            animation-delay: .6s;
          }
        }
      }
    }
    
    &.is-shifted {
      left: -100%;
      transition: .3s all ease-out;
    }
    
    .overlay {
      &__icon {
        width: 24px;
        height: 24px;
        margin: 0;
        opacity: 1;
      
        &__wrapper {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          margin-bottom: 15px;
          opacity: 0;
          border: 1px solid var(--white);
          border-radius: 6px;
          animation: mobileOverlayFadeInLeft .1s ease forwards;
        }
        
        &__title,
        &__list__item {
          animation: mobileOverlayFadeInLeft .1s ease forwards;
        }
      }
    }
  }
  
  .rss {
    .overlay {
      &__icon {
        width: 26px;
        height: 26px;
      }
    }
  }
  
  .login {
    position: absolute;
    left: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transition: .3s all ease-in;
    
    &.is-opened {
      left: 0;
      transition: .3s all ease-out;
      
      .overlay {
        &__helper-text,
        &__field-group,
        &__remember,
        &__login__btn,
        &__gender-switcher,
        &__subscribe,
        &__privacy,
        &__forgot,
        &__register__btn {
          animation: mobileOverlayFadeInRight .5s ease forwards;
        }
        
        &__helper-text {
          animation-delay: .25s;
        }
        
        &__field-group,
        &__gender-switcher {
          animation-delay: .3s;
        }
        
        &__remember,
        &__subscribe {
          animation-delay: .35s;
        }
        
        &__login__btn,
        &__privacy,
        &__forgot {
          animation-delay: .4s;
        }
        
        &__register__btn {
          animation-delay: .45s;
        }
      }
    }
    
    &.is-shifted {
      left: -100%;
      transition: .15s all ease-out;
      
      &--double {
        left: -200%;
      }
    }
    
    &.is-account-change {
      transition: .2s all ease-out;
    }
    
    .overlay {
      &__icon,
      &__title {
        display: none;
      }
      
      &__field-group,
      &__gender-switcher {
        margin-bottom: 13px;
      }
      
      &__remember {
        margin-bottom: 10px;
      }
      
      &__helper-text,
      &__privacy, 
      &__forgot {
        margin-bottom: 20px;
      }
      
      &__remember,
      &__subscribe {
        &__label {
          &.is-checked {
            &::after {
             background-color: var(--theme-blue);
            }
          }
        }
      }
      
      &__gender-switcher {
        &__radio {
          &__label {
            &.is-checked {
              &::before {
               background-color: var(--theme-blue);
              }
            }
          }
        }
      }
      
      &__subscribe {
        margin-bottom: 0;
      }
      
      &__error {
        margin-bottom: 10px;
      }
      
      &__helper-text,
      &__field-group,
      &__remember,
      &__login__btn,
      &__gender-switcher,
      &__subscribe,
      &__privacy,
      &__forgot,
      &__register__btn {
        animation: mobileOverlayFadeInLeft .1s ease forwards;
      }
    }
  }
  
  /*-------------------------
  # menuFadeInLeft
  --------------------------*/
  @keyframes menuFadeInLeft {
    0% {
      opacity: 0;
      visibility: hidden;
      left: -70px;
    }
    
    100% {
      opacity: 1;
      visibility: visible;
      left: 16px;
    }
  }
  
  /*-------------------------
  # menuFadeInRight
  --------------------------*/
  @keyframes menuFadeInRight {
    0% {
      opacity: 1;
      visibility: visible;
      left: 16px;
    }
    
    100% {
      opacity: 0;
      visibility: hidden;
      left: -70px;
    }
  }
  
  /*-------------------------
  # mobileOverlayFadeInRight
  --------------------------*/
  @keyframes mobileOverlayFadeInRight {
    0% {
      opacity: 0;
      left: 20px;
    }
    
    100% {
      opacity: 1;
      left: 0;
    }
  }
  
  /*-------------------------
  # mobileOverlayFadeInLeft
  --------------------------*/
  @keyframes mobileOverlayFadeInLeft {
    0% {
      opacity: 1;
      left: 0;
    }
    
    100% {
      opacity: 0;
      left: 50px;
    }
  }
  
  /*-------------------------
  # fadeInProfileName
  --------------------------*/
  @keyframes fadeInProfileName {
    0% {
      opacity: 0;
    }
    
    100% {
      opacity: 1;
    }
  }
  
  /*-------------------------
  # Media Queries
  --------------------------*/
  @media (max-width: 991px) {
    .logo {
      &__text {
        position: absolute;
        left: 58px;
        top: inherit;
        bottom: -5px;
        width: 100%;
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: 1px;
        color: var(--theme-deep-purple);
      }
      
      &__link {
        display: flex;
      }
    }
  }
  
  @media (max-width: 767px) {
    .header {
      height: 125px;
    }
    
    .switcher {
      height: 72px;
    }
    
    .bottom {
      padding-bottom: 30px;
    }
    
    .navigation {
      padding: 60px 0;
      
      &__item {
        &__icon {
          width: 30px;
          height: 30px;
          margin-bottom: 10px;
          
          svg {
            width: 18px;
            height: 18px;
          }
        }
      }
    }
    
    .socials {
      &__title {
        margin-bottom: 15px;
      }
    
      &__list {
        &__item {
          width: 32px;
          height: 32px;
          margin: 0 10px;
          
          svg {
            width: 18px;
            height: 18px;
          }
        }
      }
    }
    
    .languages,
    .rss {
      height: calc(100% - 100px);
    
      .overlay {
        &__icon {
          &__wrapper {
            width: 30px;
            height: 30px;
            
            svg {
              width: 18px;
              height: 18px;
            }
          }
        }
      }
    }
    
    .overlay {
      &__gender-switcher,
      &__field {
        width: 408px;
      }
      
      &__field {
        height: 56px;
      }
    }
    
    .close-sub-nav {
      top: -50px;
      width: 32px;
      height: 32px;
      border-color: var(--theme-deep-purple);
      
      &__icon {
        path {
          stroke: var(--theme-deep-purple);
        }
      }
    }
    
    .login {
      overflow-y: auto;
    }
  }
  
  @media (max-width: 480px) {
    .bottom {
      justify-content: center;
    }
  
    .close {
      top: 15px;
      right: 15px;
    }
  
    .navigation {
      height: 400px;
      padding: 40px 0;
      margin-top: -40px;
    }
    
    .socials {
      position: absolute;
      bottom: 40px;
      display: block;
      width: 100%;
      text-align: center;
      
      &__list {
        align-items: center;
        justify-content: center;
      }
    }
    
    .login {      
      display: block;
      padding: 30px;
    }
    
    .overlay {
      &__title,
      &__list__item {
        font-size: 16px;
        line-height: 19px;
      }
    
      &__helper-text,
      &__forgot {
        display: block;
        text-align: center;
      }
    
      &__gender-switcher, 
      &__field,
      &__field-group {
        width: 100%;
      }
      
      &__gender-switcher {
        padding: 0 35px;
        
        &__radio {
          &__label {
            margin-left: 15px;
            
            &::before {
              margin-right: 8px;
            }
          }
        }
      }
      
      &__remember__label {
        justify-content: center;
      }
      &__subscribe {
        margin-right: 0;
      }
      
      &__login,
      &__register {
        &__btn {
          display: block;
          margin: 0 auto;
        }
      }
      
      &__error {
        text-align: center;
      }
    }
    
    .switcher {
      &__user {
        &.is-extended {
          &--logged {
            .switcher {
              &__user {
                &__hello {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
  
  @media (max-width: 390px) {
    .header {
      height: 110px;
    }
  
    .logo {
      width: 254px;
      height: 45px;
      
      &__text {
        bottom: -20px;
        width: auto;
      }
    }
    
    .switcher {
      height: 68px;
    }
    
    .overlay {
      &__field,
      &__gender-switcher {
        padding: 0 25px;
      }
    }
  }
  
  @media (max-width: 375px) {
    .switcher {
      height: 56px;
      
      &__search {
        &.is-extended {
          .switcher {
            &__search {
              &__icon {
                width: 32px;
                height: 32px;
              }
            }
          }
        }
      }
    }
  
    .navigation {
      justify-content: space-evenly;
    
      &__item {
        flex-direction: row;
        justify-content: flex-start;
        width: 250px;
        
        &__icon {
          margin: 0 13px 0 0;
        }
      }
    }
    
    .close-sub-nav {
      top: -43px;
    }
  }
`;