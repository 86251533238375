import React, { memo, useMemo, useContext, useEffect, useState } from 'react';
import { useStaticQuery, graphql }                               from 'gatsby';
import { toUrl }                                                 from 'gatsby-source-gravatar';

// import context
import { GlobalStateContext } from '../../../context/GlobalContextProvider';

// import helpers
import { useGetCookie } from '../../../helpers/index';

// import utils
import { getUser } from '../../../utils/Auth';

const AccountUser = memo( () => {
  const data = useStaticQuery( graphql`
    {
      allApiPoints {
        nodes {
          account {
            hello_text
          }
        }
      }
    }
  `);

  // define the component context
  const state = useContext( GlobalStateContext );
  const { defaultLang } = state;

  // define the component state
  const [texts, setTexts] = useState( { accountHello: '' } );
  const { accountHello } = texts;

  // get session from cookie
  const session = useGetCookie( 'MMBlogSession' );

  // get user data
  const user = getUser( session );
  const { email, username } = user;
  const currentEmail = email === undefined ? 'example@mail.com' : email;

  // set up gravatar image
  const gravatarUrl = useMemo( () => toUrl( currentEmail, 'size=86&d=mp' ), [currentEmail] );

  /**
   * Get texts from the backend and processing changing language
   */
  useEffect( () => {
    // get loaded data from graphql
    data.allApiPoints.nodes.forEach( node => {
      const { account } = node;
      let orderNumber;

      switch ( defaultLang ) {
        case 'en':
          orderNumber = 1;
          break;

        default:
          orderNumber = 0;
      }

      const current = account[orderNumber];
      const { hello_text } = current;

      setTexts( t => ({
        ...t,
        accountHello: hello_text
      }));
    });
  }, [data, defaultLang] );

  return (
    <>
      <div className="user-data">
        <img className="avatar"
             src={ gravatarUrl }
             alt={ username !== undefined ? username : 'User' } />
        <span className="user-name">{ `${ accountHello } ${ username }!` }</span>
      </div>
    </>
  )
});

export default AccountUser;