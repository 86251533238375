import React, { memo, useState, useEffect } from 'react';
import { graphql, useStaticQuery, Link }    from 'gatsby';
import PropTypes                            from 'prop-types';

// import helpers
import { useWindowSize } from '../../../helpers';

// import styles
import { HeaderLogoElement } from './styles';

// import components
import BrandIconExtended  from '../icons/BrandIconExtended';
import BrandIconCollapsed from '../icons/BrandIconCollapsed';

/**
 * The HeaderLogo Component
 *
 * @param currentLang | string
 */
const HeaderLogo = memo( ( { currentLang } ) => {
  const data = useStaticQuery( graphql`
    {
      allApiPoints {
        nodes {
          powereds {
            link
            link_text
            title_powered
          }
        }
      }
    }
  `);

  // define the default component state
  const [texts, setTexts] = useState({
    poweredTitle: '',
    poweredLinkText: '',
    poweredLink: ''
  });

  const { poweredTitle, poweredLinkText, poweredLink } = texts;

  // define the component custom hooks
  const windowSize = useWindowSize();
  const { width }  = windowSize;

  /**
   * Get texts from the backend and processing changing language
   */
  useEffect( () => {
    // get loaded data from graphql
    data.allApiPoints.nodes.forEach( node => {
      const { powereds } = node;
      let orderNumber;

      switch ( currentLang ) {
        case 'en':
          orderNumber = 1;
          break;

        default:
          orderNumber = 0;
      }

      // set up texts
      const current = powereds[orderNumber];
      const { title_powered, link_text, link } = current;

      setTexts( t => ({
        ...t,
        poweredTitle: title_powered,
        poweredLinkText: link_text,
        poweredLink: link
      }));
    });
  }, [data, currentLang] );

  return (
    <HeaderLogoElement className="header-logo" role="navigation">
      <Link className="header-logo__link"
            title="Zweinstein"
            to={ currentLang === 'de' ? '/' : '/en/' }>
        { width > 991 ?
          <BrandIconExtended className="header-logo__icon" />
        :
          <BrandIconCollapsed className="header-logo__icon" />
        }
      </Link>

      { width > 991 ?
        <span className="header-logo__text">
          { poweredTitle } <a className="header-logo__text__link"
                              href={ poweredLink }
                              target="_blank"
                              rel="noreferrer">{ poweredLinkText }</a>
        </span>
      : false }
    </HeaderLogoElement>
  );
});

HeaderLogo.defaultProps = {
  currentLang: 'de',
};

HeaderLogo.propTypes = {
  currentLang: PropTypes.string.isRequired
};

export default HeaderLogo;