import React, { memo } from 'react';
import PropTypes       from 'prop-types';

const RSSIcon = memo( ( { className, color, onClick, border } ) => {
  /**
   * Processing click action.
   * Passing data to the received function
   */
  const handleClick = () => {
    if ( onClick !== undefined ) onClick();
  };

  /**
   * Return
   */
  return (
    <svg className={ className }
         xmlns="http://www.w3.org/2000/svg"
         width="23.951"
         height="23.951"
         viewBox="0 0 23.951 23.951"
         onClick={ handleClick }>
      <g transform="translate(-946.161 -564.42)">
        <g transform="translate(950.551 568.484)">
          <path d="M982.779,681.814a2.119,2.119,0,1,0,1.514.6,2.055,2.055,0,0,0-1.514-.6Zm0,0"
                transform="translate(-980.536 -670.479)"
                fill={ color } />
          <path d="M987.144,641.5a9.792,9.792,0,0,0-2.893-1.981,9.949,9.949,0,0,0-3.4-.856h-.055a.718.718,0,0,0-.7.717l.016,1.5a.681.681,0,0,0,.188.474.674.674,0,0,0,.463.217,7.092,7.092,0,0,1,6.453,6.316.673.673,0,0,0,.226.457.683.683,0,0,0,.479.178l1.5-.016a.654.654,0,0,0,.519-.239.671.671,0,0,0,.183-.534,9.937,9.937,0,0,0-.929-3.382,9.805,9.805,0,0,0-2.042-2.849Zm0,0"
                transform="translate(-980.032 -632.986)"
                fill={ color } />
          <path d="M994,604.559a15.7,15.7,0,0,0-13.634-9.16h-.033a.664.664,0,0,0-.485.2.657.657,0,0,0-.216.513l.017,1.585a.678.678,0,0,0,.2.48.661.661,0,0,0,.473.211,12.556,12.556,0,0,1,4.54,1.082,12.483,12.483,0,0,1,6.358,6.223,12.383,12.383,0,0,1,1.168,4.516.665.665,0,0,0,.222.469.693.693,0,0,0,.495.189l1.585-.017a.656.656,0,0,0,.508-.227.642.642,0,0,0,.194-.523,15.377,15.377,0,0,0-1.39-5.545Zm0,0"
                transform="translate(-979.628 -595.399)"
                fill={ color } />
        </g>

        { border ?
          <path d="M965.018,588.371H951.255a5.1,5.1,0,0,1-5.094-5.094V569.514a5.1,5.1,0,0,1,5.094-5.094h13.763a5.1,5.1,0,0,1,5.094,5.094v13.763A5.1,5.1,0,0,1,965.018,588.371Zm-13.763-22.508a3.655,3.655,0,0,0-3.651,3.651v13.763a3.655,3.655,0,0,0,3.651,3.651h13.763a3.656,3.656,0,0,0,3.651-3.651V569.514a3.655,3.655,0,0,0-3.651-3.651Z"
                fill={ color } />
        : false }

      </g>
    </svg>
  )
});

RSSIcon.defaultProps = {
  className: '',
  color: '#fff',
  onClick: () => {},
  border: true
};

RSSIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  border: PropTypes.bool
};

export default RSSIcon;