import styled from 'styled-components';

export const PasswordHintElement = styled.div`
  position: absolute;
  top: -29px;
  right: -260px;
  width: 240px;
  opacity: 0;
  border: 2px solid var(--white);
  border-radius: 15px;
  padding: 7px;
  animation: fadeInRightPasswordHint .5s ease forwards;
  animation-delay: .25s;
  
  &::before {
    content: '';
    position: absolute;
    top: 52px;
    left: -12px;
    width: 20px;
    height: 20px;
    border-top: 0;
    border-right: 0;
    border-bottom: 2px solid var(--white);
    border-left: 2px solid var(--white);
    border-radius: 0 0 0 4px;
    background-color: var(--theme-deep-purple);
    transition: .3s all ease-in-out;
    transform: rotate(45deg);
  }
  
  .requirement {
    display: flex;
    align-items: center;
    line-height: 1;
  
    &__icon {
      --dimensions: 24px;
    
      display: flex;
      align-items: center;
      justify-content: center;
      width: var(--dimensions);
      height: var(--dimensions);
      margin-right: 5px;
      
      &__item {
        &--cross {
          position: relative;
          top: 1px;
        }
      
        &--check {
          --dimensions: 20px;
          
          width: var(--dimensions);
          height: var(--dimensions);
        }
      }
    }
    
    &__text {
      width: 180px;
      font-size: 14px;
      line-height: 20px;
      color: var(--white);
    }
  }

  @keyframes fadeInRightPasswordHint {
    0% {
      opacity: 0;
      right: -300px;
    }
    
    100% {
      opacity: 1;
      right: -260px;
    }
  }
  
  /*-------------------------
  # Media Queries
  --------------------------*/
  @media (max-width: 1024px) {
    width: 220px;
    right: -240px;
    top: -26px;
    
    .requirement {
      &__text {
        font-size: 12px;
        line-height: 18px;
      }
    }
    
    @keyframes fadeInRightPasswordHint {
      0% {
        opacity: 0;
        right: -280px;
      }
      
      100% {
        opacity: 1;
        right: -240px;
      }
    }
  }
  
  @media (max-width: 991px) {
    top: -125px;
    left: 0;
    background-color: var(--theme-deep-purple);
    
    &::before {
      top: 114px;
      left: 30px;
      width: 15px;
      height: 15px;
      background-color: var(--theme-deep-purple);
      transform: rotate(-45deg);
    }
    
    @keyframes fadeInRightPasswordHint {
      0% {
        opacity: 0;
        top: -148px;
      }
      
      100% {
        opacity: 1;
        top: -138px;
      }
    }
  }
`;