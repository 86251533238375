import styled from 'styled-components';

// import assets
import swiperArrow from '../../../../assets/images/swiper__arrow.svg';

export const MobileCategoriesElement = styled.section`
  --categories-height: 69px;
  
  position: fixed;
  top: 60px;
  width: 100%;
  height: var(--categories-height);
  background-color: var(--white);
  box-shadow: -4px 4px 6px rgba(var(--black--rgb), .16);
  z-index: 4;
  
  .list {
    width: 100%;
    height: 100%;
    padding: 5px 14px;
    
    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 185px;
      max-width: 100%;
      border-left: 1px solid var(--theme-gray);
      padding: 0 25px;
    
      &:first-child {
        border-left: none;
      }
      
      &__category {
        &--show {
          display: flex;
        }
        
        &--hide {
          display: none;
        }
      }
      
      &__link {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        text-align: center;
        line-height: 19px;
        text-decoration: none;
        color: var(--theme-deep-purple);
        
        &[aria-current="page"] {
          color: var(--theme-blue);
        }
      }
    }
    
    .swiper {
      &-wrapper {
        margin: 0;
        padding: 0;
        list-style: none;
      }
      
      &-button {
        &-prev,
        &-next {
          position: absolute;
          top: 0;
          width: 15px;
          height: var(--categories-height);
          opacity: 1;
          visibility: visible;
          transition: .3s all ease-in-out;
          z-index: 2;
        }
      
        &-prev {
          left: 0;
          background: url(${ swiperArrow }) no-repeat center var(--theme-light-gray);
          transform: rotate(0.5turn);
        }
        
        &-next {
          right: 0;
          background: url(${ swiperArrow }) no-repeat center var(--theme-light-gray);
        }
        
        &-disabled {
          opacity: 0;
          visibility: hidden;
        }
      }
    }
  }
  
  @media (max-width: 480px) {
    --categories-height: 54px;
  
    .list {
      &__item {
        width: 145px;
        padding: 0 10px;
        
        &__link {
          line-height: 18px;
        }
      }
    }
  }
  
  @media (max-width: 390px) {
    .list {
      &__item {
        width: 175px;
        padding: 0 20px;
      }
    }
  }
  
  @media (max-width: 375px) {
    top: 48px;
  }
`;
