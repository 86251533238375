import React, { memo } from 'react';
import PropTypes       from 'prop-types';

const LogoutIcon = memo( ( { className, color } ) => {
  return (
    <svg className={ className }
         width="24"
         height="24"
         viewBox="0 0 24 24"
         xmlns="http://www.w3.org/2000/svg">
      <path d="m0 0h24v24h-24z" fill="none" />
      <path d="m17 7-1.41 1.41 2.58 2.59h-10.17v2h10.17l-2.58 2.58 1.41 1.42 5-5zm-13-2h8v-2h-8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2h-8z"
            fill={ color } />
    </svg>
  );
});

LogoutIcon.defaultProps = {
  className: '',
  color: '#fff'
};

LogoutIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
};

export default LogoutIcon;