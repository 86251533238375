import React from 'react';

// import context
import GlobalContextProvider from './src/context/GlobalContextProvider';

// import components
import Layout from './src/components/common/Layout';

/**
 * Wrapper for Root
 */
export const wrapRootElement = ( { element } ) => {
  return <GlobalContextProvider>{ element }</GlobalContextProvider>
};

/**
 * Wrapper for Page
 */
export const wrapPageElement = ( { element, props } ) => {
  return <Layout { ...props }>{ element }</Layout>
};

/**
 * Fix Intersection observer error on iOS
 */
export const onClientEntry = async () => {
  if ( typeof IntersectionObserver === 'undefined' ) {
    await import( 'intersection-observer' );
    console.log( 'intersection-observer imported' );
  }
};

/**
 * Save the current position before go to the link
 */
export const shouldUpdateScroll = ( { routerProps: { location }, getSavedScrollPosition } ) => {
  const transitionDelay = 400;

  if ( location.action === 'PUSH' ) {
    window.setTimeout( () => window.scrollTo( 0, 0 ), transitionDelay );
  } else {
    const savedPosition = getSavedScrollPosition( location );

    window.setTimeout( () => window.scrollTo( ...( savedPosition || [0, 0] ) ), transitionDelay );
  }

  return false;
};