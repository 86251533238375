import styled from 'styled-components';

export const CloseBtnElement = styled.span`
  --width: 130px;
    
  position: absolute;
  left: calc(50% - (var(--width) / 2));
  bottom: 50px;
  display: flex;
  width: var(--width);
  align-items: center;
  justify-content: center;
  font-weight: 600;
  line-height: 19px;
  text-transform: uppercase;
  color: var(--theme-light-blue);
  transition: .3s all ease-in-out;
  
  &:hover {
    cursor: pointer;
    
    .close__icon {
      transform: rotate(1turn);
    }
  }
  
  .close {
    &__icon {
      position: relative;
      top: -1px;
      margin-left: 10px;
      transform: rotate(0deg);
      transition: .6s all ease-in-out;  
    }
  }
  
  /*-------------------------
  # Media Queries
  --------------------------*/
  @media (max-width: 991px) {
    --width: 24px;
  
    position: absolute;
    top: 18px;
    right: 30px;
    left: initial;
    bottom: initial;
    
    .close {
      &__icon {
        position: static;
        width: var(--width);
        height: var(--width);
        margin: 0;
        
        path {
          fill: var(--theme-dark-purple);
        }
      }
    }
  }
  
  @media (max-width: 480px) {
    top: 15px;
    right: 15px;
  }
`;