import React, { memo } from 'react';
import PropTypes       from 'prop-types';

const InstagramIcon = memo( ( { className, color } ) => {
  return (
    <svg className={ className }
         xmlns="http://www.w3.org/2000/svg"
         width="24.043"
         height="24.043"
         viewBox="0 0 24.043 24.043">
      <g transform="translate(0 0)">
        <path d="M1136.255,755.989a1.413,1.413,0,1,0,1.414,1.413A1.413,1.413,0,0,0,1136.255,755.989Zm-6.284,1.813a5.937,5.937,0,1,0,5.938,5.937A5.944,5.944,0,0,0,1129.971,757.8Zm0,9.741a3.8,3.8,0,1,1,3.8-3.8A3.808,3.808,0,0,1,1129.971,767.543Zm11.981-8.738a7.242,7.242,0,0,0-7.243-7.242h-9.559a7.242,7.242,0,0,0-7.242,7.242v9.559a7.242,7.242,0,0,0,7.242,7.242h9.559a7.242,7.242,0,0,0,7.243-7.242Zm-2.269,9.559a4.974,4.974,0,0,1-4.974,4.974h-9.559a4.974,4.974,0,0,1-4.974-4.974V758.8a4.974,4.974,0,0,1,4.974-4.974h9.559a4.974,4.974,0,0,1,4.974,4.974Z"
              transform="translate(-1117.909 -751.562)"
              fill={ color } />
      </g>
    </svg>
  );
});

InstagramIcon.defaultProps = {
  className: '',
  color: '#fff'
};

InstagramIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

export default InstagramIcon;