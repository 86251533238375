import styled from 'styled-components';

export const MobileSearchElement = styled.form`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  
  &.is-shown {
    animation: searchAppearing .5s ease forwards;
    animation-delay: .4s;
  }
  
  .field {
    width: 100%;
    height: 100%;
    border: none;
    padding: 0 90px;
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: .75px;
    color: var(--theme-deep-purple);
    z-index: 1;
    
    &:focus {
      outline: none;
    }
    
    &::placeholder {
      color: var(--theme-dark-gray);
    }
  }
  
  .button {
    --dimensions: 40px;
  
    position: absolute;
    top: calc(50% - (var(--dimensions) / 2));
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--dimensions);
    height: var(--dimensions);
    border: 2px solid #3E2271;
    border-radius: 50%;
    background-color: var(--white);
    transition: .3s all ease-in-out;
    z-index: 1;
    
    &:hover {
      cursor: pointer;
    }
    
    &.is-clicked {
      transform: rotate(90deg);
    }
  }
  
  /*-------------------------
  # searchAppearing
  --------------------------*/
  @keyframes searchAppearing {
    0% {
      opacity: 0;
      visibility: hidden;
    }
    
    100% {
      opacity: 1;
      visibility: visible;
    }
  }
 
  /*-------------------------
  # Media Queries
  --------------------------*/ 
  @media (max-width: 375px) {
    .button {
      --dimensions: 34px;
    }
    
    .results {
      --height: 125px;
      
      &__item {
        padding-top: 30px;
        padding-bottom: 30px;
      }
    }
  }
`;